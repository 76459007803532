<template>
  <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
    <div class="pad-8 br-10 bgcolor-lightblue">
      <div style="clear: both;">
        <div style="float: right;">
          <router-link :to="'/benutzerverwaltung/edit/'+ userItem.id" class="button tiny primary">
            Bearbeiten
          </router-link>
        </div>
      </div>
      <img :src="config.projectURL + '/assets/images/users/' + userItem.userImg" class="width-48 circular bgcolor-lightblue">
      <div style="display: inline-block;" class="pad-8">
        <p style="display: block;" class="f-bold mar-bottom-4">
          <span class="tiny label blue" style="margin-left: -4px;vertical-align: middle;">{{ userItem.workgroupTitle }}</span>
          {{ userItem.title }} {{ userItem.firstname }} {{ userItem.lastname }}
        </p>
        <p class="color-grey" style="font-size: 0.9em;">
          <svg style="vertical-align: bottom;" width="16px"  viewBox="0 0 64 64" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64"><path d="m-22.5-213.2-1.9-1.9-17.6 17.6c-2.2 2.2-5.9 2.2-8.1 0L-67.7-215l-1.9 1.9 13.1 13.1-13.1 13.1 1.9 1.9 13.1-13.1 2.6 2.6c1.6 1.6 3.7 2.5 5.9 2.5s4.3-.9 5.9-2.5l2.6-2.6 13.1 13.1 1.9-1.9-13.1-13.1 13.2-13.2" transform="translate(78 232)" fill="#aaaaaa" class="fill-134563"></path><path d="M-26.2-181.6h-39.5c-2.3 0-4.2-1.9-4.2-4.2V-214c0-2.3 1.9-4.2 4.2-4.2h39.5c2.3 0 4.2 1.9 4.2 4.2v28.2c0 2.3-1.9 4.2-4.2 4.2zm-39.6-33.9c-.8 0-1.4.6-1.4 1.4v28.2c0 .8.6 1.4 1.4 1.4h39.5c.8 0 1.4-.6 1.4-1.4v-28.2c0-.8-.6-1.4-1.4-1.4h-39.5z" transform="translate(78 232)" fill="#aaaaaa" class="fill-134563"></path></svg>
          {{ userItem.email }}
        </p>
        <p class="color-grey" style="font-size: 0.9em;">
          <svg style="vertical-align: bottom;" width="16px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none"></path><path d="M13.25 21.59a30.12 30.12 0 0 0 13.18 13.17l4.4-4.41c.55-.55 1.34-.71 2.03-.49C35.1 30.6 37.51 31 40 31c1.11 0 2 .89 2 2v7c0 1.11-.89 2-2 2C21.22 42 6 26.78 6 8a2 2 0 0 1 2-2h7c1.11 0 2 .89 2 2 0 2.49.4 4.9 1.14 7.14.22.69.06 1.48-.49 2.03l-4.4 4.42z" fill="#aaaaaa" class="fill-000000"></path></svg>
          {{ userItem.phone }}
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "UserItem",
  props: ["userItem"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
  .grid-12{
    background-color: rgba(0,0,0,0.03);
    border-bottom: 4px solid #ffffff;
    border-radius: 10px;
    margin-bottom: 8px;
  }
  img.circular{
    vertical-align: 4px;
    margin-right: 8px;
  }
</style>