<template>
  <div class="vueModal" id="selectNewSlotModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="handleClosing" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="handleClosing">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <div style="display: flex;flex-direction: column;gap: 20px;align-items: center">
        <h3>Bist du dir sicher?</h3>
        <div style="display: flex;gap: 20px">
          <button class="button" @click="handleClosing">
            Abbrechen
          </button>
          <button class="button primary" @click="handleApprove">
            Bestätigen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SafetyModal",
  methods:{
    handleClosing(){
      this.$emit("closeSaftyModal", false)
    },
    handleApprove(){
      this.$emit("closeSaftyModal", true)
    },
  }
}
</script>

<style scoped>


</style>