<template>
  <div>
    <main id="zeiterfassung">
      <div class="content-1400">
        <div class="pad-16">
          <h2 style="font-size: 1.2em;" class="color-primary">
            Deine
          </h2>
          <h1>
            Krankmeldungen
          </h1>
        </div>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
            <div class="bgcolor-white br-10 pad-16">
              <h3>Neue Krankmeldung einreichen</h3>
              <p class="color-grey">Teile deinem Arbeitgeber mit von wann bis wann du Krankgeschrieben bist:</p>
              <div class="pad-16"></div>
              <div class="grid-12" style="column-gap: 6px;">
                <div class="col-sp-6 row-sp-12">
                  <div class="field">
                    <label for="sickStartDate">Von</label>
                    <input type="date" name="SickStartDate" id="SickStartDate" v-model="SickStartDate">
                  </div>
                </div>
                <div class="col-sp-6 row-sp-12">
                  <div class="field">
                    <label for="sickEndDate">bis</label>
                    <input type="date" name="sickEndDate" id="sickEndDate" v-model="SickEndDate">
                  </div>
                </div>
              </div>
              <button class="mar-top-16 button primary fluid" @click="submitSickReport">
                Krankmeldung abschicken
              </button>
              <div class="t-center mar-top-16" v-if="SickSuccessMessage">
                <p class="color-green">Deine Krankmeldung wurde erfolgreich eingereicht.<br>Wir wünschen dir gute Besserung!</p>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
            <div class="bgcolor-white br-10 pad-16">
              <h3>Vergangende Krankmeldungen</h3>
              <div class="pad-8"></div>
              <div v-for="sickReport in sickReports" :key="sickReport.id" class="pad-4">
                <div class="bgcolor-lightblue br-10 pad-8 color-primary grid-12">
                  <div class="col-sp-12 col-tb-4 row-sp-12">
                    <p class="f-bold">Krankmeldung</p>
                  </div>
                  <div class="col-sp-12 col-tb-8 row-sp-12 t-right">
                    <p>{{renderDateForJS(sickReport.sickReportStart)}} - {{renderDateForJS(sickReport.sickReportEnd)}}</p>
                  </div>
                  <div style="margin-top: 6px;">
                    <button class="primary button" @click="editReportId = sickReport.id;
                    editReportStart = sickReport.sickReportStart;
                    editReportEnd = sickReport.sickReportEnd;editReport = true ">Bearbeiten</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditReport v-if="editReport"
                      :editReportId="editReportId"
                      :editReportStart="editReportStart"
                      :editReportEnd="editReportEnd"
                      @closingEditSickReport="(bool)=>{editReport= bool}"
                      @updateSickReports="()=>{getSickReports}"/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {dateForInput} from "@/modules/main";
import EditReport from "@/components/sickReport/editReport";

export default {
  name: "SickReport",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  components:{
    EditReport
  },
  data(){
    return{
      DateString:'',
      SickStartDate:'',
      SickEndDate:'',
      SickSuccessMessage: false,
      sickReports: '',
      editReport: false,
      editReportId:'',
      editReportStart:'',
      editReportEnd:''
    }
  },
  mounted() {
    this.dateForInput()
    this.getSickReports()
  },
  methods:{
    dateForInput(){
      this.SickStartDate = dateForInput(new Date(Date.now()))
      this.SickEndDate = this.SickStartDate
    },
    renderDateForJS(date){
      const jsDate = date.split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
    submitSickReport(){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.user.id)
      fD.append('sickReportStart', this.SickStartDate)
      fD.append('sickReportEnd', this.SickEndDate)
      this.axios.post('sickreport/setSickReport', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              this.SickSuccessMessage = true
              this.getSickReports()
            }
          })
    },
    getSickReports(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('sickreport/getSickReportsByUserId', fD)
          .then((response) => {
            if(response.status === 250){
              _this.sickReports = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
.field{
  margin-bottom: 8px;
}
.field input,
.field select{
  max-width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  width: 100%;
}
.field input:focus{
  outline: none;
}
</style>