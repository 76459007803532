<template>
  <div class="pad-8" />
  <div class="bgcolor-white br-10 pad-16" :class="{outlineTodayBox: today===weekDates.date}" v-if="rerenderDay">
    <div style="display: flex;justify-content: space-between; align-items: center">
      <div>
        <h4 style="display: inline;">
          {{ weekDates.name }}<span class="label tiny blue mar-left-8" style="vertical-align: 4px;">{{ weekDates.date }}</span>
        </h4>
      </div>
    </div>
    <div>
      <div class="pad-16" />
      <div class="hourLines" style="height:fit-content;width: 100%;box-sizing: border-box;position: relative;border-left: 1px solid #aaaaaa33">
        <div id="slotGrid" style="z-index:4;display: grid;grid-auto-columns: calc((100% - 48px)/48);column-gap: 1px;grid-auto-flow: row dense;" >
          <div v-for="singleSlot in slotItems"
               :key="singleSlot.id"
               onresize="this.ForceUpdateComponent()"
               :style="renderSlot2(singleSlot.slotStart, singleSlot.slotEnd)"
               style="z-index: 2;position: relative;user-select: none;"
               class="mobileSlot">
            <div
                v-if="checkIfUserActive(singleSlot.userId)"
                class="slot pad-8 br-4 color-white t-center "
                :class="checkIfUserSick(singleSlot.userId)"
                @click="()=>{openChangeLayer(singleSlot.userId, singleSlot.id);slotValues=singleSlot}"
                style=" cursor: pointer;box-sizing: border-box"
            >
              <div class="mar-bottom-8">
                <img :src="config.projectURL + '/assets/images/users/' + singleSlot.userimg" class="width-48 circular bgcolor-lightblue">
                <div style="display: inline-block;">
                  <span>{{ singleSlot.title }} {{ singleSlot.firstname }} {{ singleSlot.lastname }}</span>
                </div>
              </div>
              <strong>{{ singleSlot.workgroupTitle }}</strong> - {{ singleSlot.workareasTitle }}
              <div>{{ singleSlot.slotStart.slice(11, 16) }} - {{ singleSlot.slotEnd.slice(11, 16) }} Uhr</div>
            </div>
          </div>
          <div style="z-index:1;grid-column:  1 / 49;width:100%;height: 100%;position: absolute;">
            <div class="hourline2"
                 v-for="n in 24"
                 :key="n"
                 :style="renderHourline(n)"/>
          </div>
        </div>

      </div>
      <!-- Keine Schichten gefunden-->
      <div v-if="slotItems == null && freeDays == null">
          <h3 style="text-align: center">Dieser Tag wurde noch nicht geplant</h3>
      </div>
      <div v-if="freeDays != null" class="pad-16">
        <div class="t-center color-blue pad-16 br-10" style="background-color: #00000010">
          <svg width="80px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-v-21439d42=""><path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6 295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z" fill="#0173fe" class="fill-000000" data-v-21439d42=""></path></svg>
          <p>Als freier Tag markiert</p>
          <div class="pad-8"></div>
        </div>
      </div>
      <div class="pad-16" />
      <div class="grid-12 t-center mobileHidden" style="color: rgba(0,0,0,0.4); font-size: 0.4em;">
        <div class="col-sp-2 row-sp-12">
          2:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          6:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          10:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          14:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          18:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          22:00 Uhr
        </div>
      </div>
    </div>
<!--    ChangeBox-->
    <change-layer v-if="openChange" :actDate="weekDates.date" @loadSlotChanges="reloadSlotChanges" :actSlot="slotValues" :slotId="changeId" @closingLayerChange="this.openChange = false"/>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import changeLayer from "@/components/mylayers/changeLayer";


export default {
  name: "layerDay",
  props: ["weekDates","activeUsers"],
  components:{
    changeLayer
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      rerenderDay:true,
      slotItems: null,
      selectModal: false,
      slotId: null,
      slotValues:'',
      slotType: null,
      sickReports:[],
      freeDays: null,
      today:'',
      openChange:false,
      changeId:''
    }
  },
  mounted() {
    this.config.loading = false
    this.getSlots()
    this.getSickReports()
    this.getFreeDays()
    this.checkIfToday()
  },
  methods:{
    getSlots : function (){
      const _this = this
      const fD = new FormData()
      fD.append('date', this.weekDates.date)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/getSlots', fD)
          .then((response) => {

            if(response.status === 250){
              _this.slotItems = response.data
              this.slotItems.forEach((slot)=>{
                this.renderSlot2(slot.slotStart, slot.slotEnd)
              })

            }
            else{
              this.slotItems = null
              this.rerenderDay = false;
              this.$nextTick(()=>{

                this.rerenderDay = true
              })
            }
          })
    },
    renderSlot2(startTime, endTime){
      const startTimeInUnix = Date.parse(startTime)/ 1000
      const endTimeInUnix = Date.parse(endTime) /1000

      const startingTime =Math.round((((new Date(startTimeInUnix * 1000).getHours())*60)+(new Date(startTimeInUnix * 1000).getMinutes()))/30)
      const endingTime =Math.round((((new Date(endTimeInUnix * 1000).getHours())*60)+(new Date(endTimeInUnix * 1000).getMinutes()))/30)

      return'grid-column-start:'+ (startingTime +1) + ';grid-column-end:'+ (endingTime +1)
    },
    ForceUpdateComponent(){
      this.$forceUpdate()
    },
    renderHourline(number){
      return 'left:' + ((100 / 24) * number) + '%'
    },
    checkIfUserSick(userId){
      if(this.sickReports.includes(userId)){
        this.$emit('sick', true)
        return 'sick'
      }
      return 'active'
    },
    checkIfUserActive(userId){
      if(this.activeUsers.includes(userId)){
        return true
      }
      return false
    },
    getSickReports(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('date', this.renderDateToPhp(this.weekDates.date))
      this.axios.post('sickreport/getSickReports', fD)
          .then(r => {
            if(r.status === 250) {
              r.data.map(sI=>{
                this.sickReports.push(sI.userId)
              })
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getFreeDays(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('praxisId', this.config.organizerId)
      fD.append('date', this.renderDateToPhp(this.weekDates.date))
      this.axios.post('praxisplaner/getFreeDays', fD)
          .then(r => {
            if(r.status === 250) {
              _this.freeDays = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    renderDateToPhp(date){
      const substrings = date.split('.')
      const finalstring = substrings[2]+'-'+substrings[1]+'-'+substrings[0]
      return finalstring
    },
    checkIfToday(){
      const layerDate =this.weekDates.date
      const Today = new Date(Date.now())
      let TodayDay = Today.getDate()
      let TodayMonth = Today.getMonth()+1
      if (Today.getDate() < 10){TodayDay = '0'+Today.getDate()}
      if (Today.getMonth()+1 < 10){TodayMonth = '0'+(Today.getMonth()+1) }
      const TodayString = TodayDay+'.'+TodayMonth+'.'+Today.getFullYear()
      this.today = TodayString
    },
    openChangeLayer(userId, slotId){
      if (userId === this.user.id){
        this.changeId = slotId
        this.openChange = true
      }
    },
    reloadSlotChanges(){
      this.$emit('loadSlotChanges')
    }

  }
}
</script>

<style scoped>
.planerMenu .bgcolor-lightblue, #slotSettingsMenu .bgcolor-lightblue{
  transition: 0.3s;
}
.planerMenu .bgcolor-lightblue:hover , #slotSettingsMenu .bgcolor-lightblue:hover{
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .mobileSlot {
    grid-column: 1 / 49 !important;
  }
  .mobileHidden{
    display: none;
  }
}
.slot{
  margin-bottom: 4px;
  transition: 0.3s;
}
.slot:hover,
.slot.active, slot:active{
  background-color: var(--theme-diagnostika-primary);
  color: var(--white);
}
.slot.sick{
  background-color: var(--red);
  color: var(--white);
}
.slot:not(.active), slot:not(.active):active{
  padding: 13px 0;
}
.slot img{
  width: 32px;
  vertical-align: -10px;
  margin-right: 8px;
}
.search{
  padding: 8px;
  border-radius: 8px;
  background-color: #E3EEFB75;
  width: 100%;
  border: none;
}
.user:hover{
  background-color: #E3EEFB75;
  transform: scale(1.02);
}
.user{
  border-radius: 8px;
  background-color: #E3EEFB50;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.3s;
}
.user p{
  margin-bottom: 4px;
  color: rgba(0,0,0,0.6);
}
.hourline{
  height: 100%;
  width: 1px;
  background-color: #aaaaaa33;
  position: absolute;
  z-index: 1;
}
.hourline2{
  height: 100%;
  width: 1px;
  background-color: #aaaaaa33;
  z-index: 1;
  position: absolute;
}
.svgRed{
  filter: invert(31%) sepia(92%) saturate(6383%) hue-rotate(355deg) brightness(91%) contrast(131%);
}
.opacityZero{
  opacity: 0;
}
.outlineTodayBox{
  outline: 3px solid #69bdff33;
}

</style>