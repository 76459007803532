<template>
  <div class="grid-12">
    <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
      <div class="bgcolor-white pad-16 br-10">
        <h5>Voreinstellungen zur Schichtplanung</h5>
        <div class="pad-8" />
        <div class="grid-12 mar-bottom-24">
          <div class="col-sp-12 row-sp-12">
            <div class="field">
              <label>Überstunden vermeidung:</label>
              <input style="padding: 0;" type="range" v-model="overtimeMultikator" min="0" max="100">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <span class="tiny label">Unwichtig</span>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <span class="tiny label">Wichtig</span>
          </div>
        </div>
        <div class="grid-12 mar-bottom-24">
          <div class="col-sp-12 row-sp-12">
            <div class="field">
              <label>Wirtschaftlichkeit:</label>
              <input style="padding: 0;" type="range" v-model="priceMultikator" min="0" max="100">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <span class="tiny label">Unwichtig</span>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <span class="tiny label">Wichtig</span>
          </div>
        </div>
        <div class="grid-12 mar-bottom-24">
          <div class="col-sp-12 row-sp-12">
            <div class="field">
              <label>Mitarbeiter Präferenzen:</label>
              <input style="padding: 0;" type="range" v-model="preferenceMultikator" min="0" max="100">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <span class="tiny label">Unwichtig</span>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <span class="tiny label">Wichtig</span>
          </div>
        </div>
        <div class="t-center" v-if="preferenceSaveMessage">
          <span class="label green">Änderungen erfolgreich gespeichert</span>
        </div>
        <div class="t-right">
          <button @click="savePraxisPreferences" class="button primary">
            Änderungen Speichern
          </button>
        </div>
      </div>
    </div>
    <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
      <div class="bgcolor-white pad-16 br-10">
        <h5>Standard Schichten</h5>
        <div class="pad-8" />
        <div class="grid-12">
          <div v-for="workshift in workshifts" :key="workshift.id" class="col-sp-12 row-sp-12 singleWorkshift pad-4">
            <div class="bgcolor-lightblue br-10 pad-16 t-center">
              <div style="clear: both;">
                <div style="float: right; cursor: pointer;" @click="getWorkshift(workshift.id); workshiftModal = true;">
                  <svg width="24px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="m402.3 344.9 32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174 402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" fill="#0173fe" class="fill-000000" /></svg>
                </div>
              </div>
              <h6 class="color-primary">
                {{ workshift.title }}
              </h6>
              <p class="color-grey">
                {{ workshift.startTime.slice(0, 5) }} - {{ workshift.endTime.slice(0, 5) }} Uhr
              </p>
            </div>
          </div>
        </div>
        <button class="button primary fluid mar-top-16" @click="title = ''; startTime = ''; endTime = ''; addWorkshiftModal = true;">
          Schicht erstellen
        </button>
      </div>
    </div>
  </div>
  <div class="vueModal" id="workshiftModal" v-if="workshiftModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="workshiftModal = false" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: left; cursor: pointer;" @click="deleteWorkshift()">
          <svg width="24px" style="opacity: 0.5;" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title /><g data-name="1" id="_1"><path d="M356.65,450H171.47a41,41,0,0,1-40.9-40.9V120.66a15,15,0,0,1,15-15h237a15,15,0,0,1,15,15V409.1A41,41,0,0,1,356.65,450ZM160.57,135.66V409.1a10.91,10.91,0,0,0,10.9,10.9H356.65a10.91,10.91,0,0,0,10.91-10.9V135.66Z" /><path d="M327.06,135.66h-126a15,15,0,0,1-15-15V93.4A44.79,44.79,0,0,1,230.8,48.67h66.52A44.79,44.79,0,0,1,342.06,93.4v27.26A15,15,0,0,1,327.06,135.66Zm-111-30h96V93.4a14.75,14.75,0,0,0-14.74-14.73H230.8A14.75,14.75,0,0,0,216.07,93.4Z" /><path d="M264.06,392.58a15,15,0,0,1-15-15V178.09a15,15,0,1,1,30,0V377.58A15,15,0,0,1,264.06,392.58Z" /><path d="M209.9,392.58a15,15,0,0,1-15-15V178.09a15,15,0,0,1,30,0V377.58A15,15,0,0,1,209.9,392.58Z" /><path d="M318.23,392.58a15,15,0,0,1-15-15V178.09a15,15,0,0,1,30,0V377.58A15,15,0,0,1,318.23,392.58Z" /><path d="M405.81,135.66H122.32a15,15,0,0,1,0-30H405.81a15,15,0,0,1,0,30Z" /></g></svg>
        </div>
        <div style="float: right; cursor: pointer;" @click="workshiftModal = false">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <div>
        <h5 class="t-center">
          Schicht bearbeiten
        </h5>
        <div class="grid-12 mar-top-16 pad-bot-16">
          <div class="col-sp-12 row-sp-12">
            <div class="field">
              <label for="title">Titel</label>
              <input type="text" id="title" v-model="title" placeholder="Titel">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="startTime">Schichtstart</label>
              <input type="time" id="startTime" v-model="startTime" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="endTime">Schichtende</label>
              <input type="time" id="endTime" v-model="endTime" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
            </div>
          </div>
          <div class="col-sp-12 row-sp-12">
            <button class="button fluid primary" v-if="title.length > 0" @click="saveWorkshift()">
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="vueModal" id="addWorkshiftModal" v-if="addWorkshiftModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="addWorkshiftModal = false" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div>
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="addWorkshiftModal = false;">
            <svg enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 24px;"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <h5 class="t-center">
          Schicht erstellen
        </h5>
        <div class="grid-12 mar-top-16 pad-bot-16">
          <div class="col-sp-12 row-sp-12">
            <div class="field">
              <label for="ctitle">Titel</label>
              <input type="text" id="ctitle" v-model="title" placeholder="Titel">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="cstartTime">Schichtstart</label>
              <input type="time" id="cstartTime" v-model="startTime" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="cendTime">Schichtende</label>
              <input type="time" id="cendTime" v-model="endTime" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
            </div>
          </div>
          <div class="col-sp-12 row-sp-12">
            <button class="button fluid primary" v-if="title.length > 0" @click="addWorkshift()">
              Erstellen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "shiftSettings",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      workshift: '',
      workshifts: '',
      workshiftModal: false,
      addWorkshiftModal: false,
      title: '',
      startTime: '',
      endTime: '',
      workshiftId: '',
      overtimeMultikator: 0,
      priceMultikator: 0,
      preferenceMultikator: 0,
      preferenceSaveMessage: false,
    }
  },
  mounted() {
    this.getWorkshifts()
    this.getPraxisPreferences()
  },
  methods:{
    getWorkshifts(){
      this.workshifts = ''
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.getCurrentOrganizer.id)
      this.axios.post('praxisverwaltung/getWorkshifts', fD)
          .then((response) => {
            if(response.status === 250){
              _this.workshifts = response.data
            }
          })
    },
    savePraxisPreferences(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.config.getCurrentOrganizer.id)
      fD.append('overtime', this.overtimeMultikator)
      fD.append('price', this.priceMultikator)
      fD.append('preference', this.preferenceMultikator)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/savePraxisPreferences', fD)
          .then((response) => {
            if(response.status === 250){
              _this.preferenceSaveMessage = true
            }
          })
    },
    getPraxisPreferences(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.config.getCurrentOrganizer.id)
      this.axios.post('praxisverwaltung/getPraxisPreferences', fD)
          .then((response) => {
            if(response.status === 250){
              _this.overtimeMultikator = response.data.overtime
              _this.priceMultikator = response.data.price
              _this.preferenceMultikator = response.data.preference
            }
          })
    },
    getWorkshift(id){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getWorkshift', fD)
          .then((response) => {
            if(response.status === 250){
              _this.title = response.data.title
              _this.startTime = response.data.startTime
              _this.endTime = response.data.endTime
              _this.workshiftId = response.data.id
            }
          })
    },
    deleteWorkshift(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.workshiftId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/deleteWorkshift', fD)
          .then((response) => {
            if(response.status === 250){
              _this.workshiftModal = false
              _this.getWorkshifts()
            }
          })
    },
    saveWorkshift(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.workshiftId)
      fD.append('title', this.title)
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/saveWorkshift', fD)
          .then((response) => {
            if(response.status === 250){
              _this.workshiftModal = false
              _this.getWorkshifts()
            }
          })
    },
    addWorkshift(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.config.getCurrentOrganizer.id)
      fD.append('title', this.title)
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/addWorkshift', fD)
          .then((response) => {
            if(response.status === 250){
              _this.addWorkshiftModal = false
              _this.getWorkshifts()
            }
          })
    },
  }
}
</script>

<style scoped>

</style>