<template>
  <div class="vueModal" id="selectModal" style="position:fixed;">
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="handleClosing">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <h3 class="t-center pad-bottom-8">
        Urlaub ablehnen
      </h3>
      <div>
        <div style="display: flex;flex-direction:column;gap:20px;align-items: center">
          <h5 style="font-weight: 400; font-size: 16px">Möchtest du den Urlaub von <b>{{denyDatas.title}} {{denyDatas.firstname}} {{denyDatas.lastname}} ({{denyDatas.workgroupTitle}})</b> ablehnen ?</h5>
          <h6 style="font-weight: 400; font-size: 20px">
            {{denyDatas.vacationStart.slice(8,10)}}.{{denyDatas.vacationStart.slice(5,7)}}.{{denyDatas.vacationStart.slice(0,4)}}
             - {{denyDatas.vacationEnd.slice(8,10)}}.{{denyDatas.vacationEnd.slice(5,7)}}.{{denyDatas.vacationEnd.slice(0,4)}}
          </h6>
          <h5>{{denyDatas.daysTotal}} Urlaubstage</h5>
          <textarea v-model="reason" style="width: 80%;min-height: 80px" placeholder="Begründung für die Ablehnung (Optional)" class="pad-8 t-center"></textarea>
          <div style="display: flex;gap: 30px">
            <button class="primary button" @click="denyVacation">Ablehnen</button>
            <button class="button" @click="handleClosing">Abbrechen</button>
          </div>
        </div>
      </div>
    </div>
    <div class="vueModalOuter" style="height: 100%" @click="handleClosing;" />
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
export default {
  name: "changeLayer",
  props:['denyDatas'],
  data(){
    return{
      reason:''
    }
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  mounted(){
  },
  methods: {
    denyVacation(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('status', 2)
      fD.append('id', this.denyDatas.id)
      fD.append('denyText', this.reason)
      this.axios.post('vacation/approveOrDenyVacation', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              this.handleClosing()
            }
          })
    },
    handleClosing() {
      this.$emit("closingDenyVacation", false)
    },
  }
}
</script>

<style scoped>

</style>