<template>
  <main id="notifications">
    <div class="content-1400">
      <div class="pad-16">
        <h1 style="padding-left: 4px;">
          Benachrichtigungen
        </h1>
        <section>
          <notificationItems v-for="nitems in notificationItems" :notification-items="nitems" :key="nitems.id" />
          <div v-if="notificationItems == ''" class="t-center">
            <p class="color-white">
              Keine Benachrichtigungen vorhanden.
            </p>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notificationItems from "@/components/notifications/notification";


export default {
  name: "Notifications",
  title: "Notifications",
  components: {
    notificationItems: notificationItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      notificationItems: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.getNotifications()
  },
  methods: {
    getNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationItems = response.data
            }
          })
    },
  }
}
</script>

<style>
#notifications h1{
  font-size: 2em;
}

</style>