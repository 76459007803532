<template>
  <main id="usermanagement">
    <div class="content-1400">
      <div class="pad-16">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12">
            <h2 style="font-size: 1.2em;" class="color-primary">
              Übersicht
            </h2>
            <h1>Benutzerverwaltung</h1>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
            <router-link to="/usermanagement/create" class="primary button">
              Benutzer Erstellen
            </router-link>
          </div>
        </div>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-5 row-sp-12">
                  <h3 style="padding-top: 4px;">
                    Übersicht
                  </h3>
                </div>
                <div class="col-sp-12 col-tb-7 row-sp-12">
                  <div class="field">
                    <input style="width: 100%;" type="search" placeholder="Suche nach Benutzern..." v-model="searchUser">
                  </div>
                </div>
                <div class="col-sp-12 col-tb-12 row-sp-12">
                  <div v-html="addInfo" v-if="addInfo" />
                </div>
              </div>
              <div class="pad-8" />
              <div id="userOverview" class="grid-12">
                <userItems v-for="uitem in userFiltered" :user-item="uitem" :key="uitem.id" @update="getUserItems()" />
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12">
                  <h3 style="padding-top: 2px;" class="t-center">
                    Praxis auswählen
                  </h3>
                </div>
              </div>
              <praxisItems v-for="citems in praxisItems" :praxis-items="citems" :key="citems.id" @click="getUserItems()" />
              <div class="pad-8" />
              <router-link to="praxisverwaltung" class="button primary t-center" style="display: block;">
                Meine Praxen verwalten
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import praxisItems from "@/components/profil/praxis";
import userItems from "@/components/usermanagement/user";


export default {
  name: "Benutzerverwaltung",
  title: "Benutzerverwaltung",
  components: {
    praxisItems,
    userItems
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      praxisItems: [],
      userItems: [],
      addInfo: false,
      email: '',
      searchUser: ''
    }
  },
  computed: {
    userFiltered(){
      return this.userItems.filter(u => {
        try {
          return u.firstname.toLowerCase().includes(this.searchUser.toLowerCase()) || u.lastname.toLowerCase().includes(this.searchUser.toLowerCase())
        }catch (e) {
          console.log(u)
          return false
        }
      })
    }
  },
  mounted() {
    this.config.loading = false
    if(this.$route.query.invite){
      this.acceptInvite()
    }else{
      this.getPraxisItems()
      this.getUserItems()
    }
  },
  methods: {
    acceptInvite(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('token', this.$route.query.invite)
      this.axios.post('usermanagement/acceptInvite', fD)
          .then((response) => {
            if(response.status === 250){
              _this.addInfo = 'Du wurdest dem Club hinzugefügt'
            }else if(response.status === 281){
              _this.addInfo = 'Einladung ungültig.'
            }else if(response.status === 282){
              _this.addInfo = 'Einladung abgelaufen'
            }else if(response.status === 283){
              _this.addInfo = 'Einladung bereits angenommen'
            }
          })
          .catch(e => {console.log(e)})
          .finally(() => {
            _this.getPraxisItems()
            _this.getUserItems()
            _this.getRoleItems()
          })
    },
    getPraxisItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getMyClubs', fD)
          .then((response) => {
            if(response.status === 250){
              _this.praxisItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
    getUserItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getUserItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.userItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
  }
}
</script>

<style>
main#usermanagement{
  padding-bottom: 80px;
}
#usermanagement h1{
  font-size: 2em;
  margin-bottom: 16px;
}
#usermanagement h3{
  font-size: 1.4em;
}
#usermanagement .field input{
  display: block;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
#userOverview table{
  width: 100%;
}
#userOverview thead td{
  padding: 8px;
  font-weight: bold;
}
#createModal .vueModalInner{
  width: calc(100vw - 16px);
  max-width: 500px;
}
#createModal h3{
  font-size: 1.6em;
}
#createModal .field label{
  display: block;
}
#createModal .field input,
#addUserModal .field input{
  display: block;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
#createModal .field select,
#addUserModal .field select{
  display: block;
  width: calc(100%);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
</style>