<template>
  <article class="praxis" @click="updateOrganizerId" :class="{active: config.organizerId == praxisItems.id}">
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12 pad-8">
        <img :src="config.projectURL + 'assets/images/eventhoster/'+praxisItems.logo"/>
      </div>
      <div class="col-sp-9 row-sp-12">
        <h4 class="f-bold mar-bottom-4">
          {{ praxisItems.organizerName }}
        </h4>
        <svg style="vertical-align: 16px; margin-right: 4px; opacity: 0.5;" width="16px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>
        <p class="color-grey" style="display: inline-block;">
          {{ praxisItems.street }} {{ praxisItems.hnr }}<br>
          {{ praxisItems.plz }} {{ praxisItems.city }}
        </p>
        <div class="pad-4"></div>
        <svg style="vertical-align: -2px; margin-right: 4px; opacity: 0.5;" width="16px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="1" id="_1"><path d="M348.73,450.06a198.63,198.63,0,0,1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152C46.65,146.75,56.15,99.61,86.69,69.07l8.72-8.72a42.2,42.2,0,0,1,59.62,0l50.11,50.1a42.18,42.18,0,0,1,0,59.62l-29.6,29.59c14.19,24.9,33.49,49.82,56.3,72.63s47.75,42.12,72.64,56.31L334.07,299a42.15,42.15,0,0,1,59.62,0l50.1,50.1a42.16,42.16,0,0,1,0,59.61l-8.73,8.72C413.53,439,383.73,450.06,348.73,450.06ZM125.22,78a12,12,0,0,0-8.59,3.56l-8.73,8.72c-22.87,22.87-29.55,60-18.81,104.49,11.37,47.13,40.64,96.1,82.41,137.86s90.73,71,137.87,82.41c44.5,10.74,81.61,4.06,104.48-18.81l8.72-8.72a12.16,12.16,0,0,0,0-17.19l-50.09-50.1a12.16,12.16,0,0,0-17.19,0l-37.51,37.51a15,15,0,0,1-17.5,2.72c-30.75-15.9-61.75-39.05-89.65-66.95s-51-58.88-66.94-89.63a15,15,0,0,1,2.71-17.5l37.52-37.51a12.16,12.16,0,0,0,0-17.19l-50.1-50.11A12.07,12.07,0,0,0,125.22,78Z"/><path d="M364.75,269.73a15,15,0,0,1-15-15,99.37,99.37,0,0,0-99.25-99.26,15,15,0,0,1,0-30c71.27,0,129.25,58,129.25,129.26A15,15,0,0,1,364.75,269.73Z"/><path d="M428.15,269.73a15,15,0,0,1-15-15c0-89.69-73-162.66-162.65-162.66a15,15,0,0,1,0-30c106.23,0,192.65,86.43,192.65,192.66A15,15,0,0,1,428.15,269.73Z"/></g></svg>
        <p class="color-grey" style="display: inline-block;">
          {{ praxisItems.phone }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "PraxisItems",
  props: ["praxisItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    updateOrganizerId(){
      this.config.organizerId = this.praxisItems.id
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('clubId', this.praxisItems.id)
      this.axios.post(this.config.projectURL + 'profil/updateOrganizerId', fD)
          .then(response => {
            this.$emit('update')
          })
          .catch(e => {console.log(e)})
    },
  }
}
</script>

<style scoped>
.praxis{
  margin-top: 8px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 8px 4px;
  transition: 0.3s;
}
.praxis.active{
  border: 2px solid var(--theme-diagnostika-primary);
  color: var(--theme-diagnostika-primary)!important;
}
.praxis:hover{
  background-color: rgba(0,0,0,0.05);
}
.praxis img{
  max-width: 100%;
  width: 100%;
  padding-right: 8px;
}
.praxis h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  margin-top: 8px;
  margin-left: 4px;
}
</style>