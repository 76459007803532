<template>
  <h3 v-if="slots.length" class="color-primary" style="font-weight: normal; font-size: 18px; margin-top: 12px;">{{wochentag}} | <span style="font-weight: bold; ">{{ datum }}</span></h3>
  <div v-for="slot in slots" :key="slot.id" class="pad-16" style="background-color: #fafafa; border-radius: 8px; margin-top: 8px;">
    <div class="grid-12" style="align-items: center; text-align: left">
      <div class="col-sp-4 row-sp-12">
        <p>{{slot.slotStart.slice(11,16)}} - {{slot.slotEnd.slice(11,16)}} Uhr</p>
      </div>
      <div class="col-sp-1 row-sp-12" style="text-align: center">
        <p>|</p>
      </div>
      <div class="col-sp-3 row-sp-12" style="text-align: center">
        <p>{{slot.workareasTitle}}</p>
      </div>
      <div class="col-sp-1 row-sp-12" style="text-align: center">
        <p>|</p>
      </div>
      <div class="col-sp-3 row-sp-12" style="text-align: right">
        <p>{{ slot.praxisname }}</p>
      </div>

    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
export default {
  name: "dashboardDay",
  props:['day'],
  data(){
    return{
      wochentag:'',
      datum:'',
      slots:'',
      weekDates: [
        {id:1, name: 'Montag'},
        {id:2, name: 'Dienstag'},
        {id:3, name: 'Mittwoch'},
        {id:4, name: 'Donnerstag'},
        {id:5, name: 'Freitag'},
        {id:6, name: 'Samstag'},
        {id:0, name: 'Sonntag'}
      ],
    }
  },
  mounted(){
    this.renderDayForPhp()
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  methods:{

    renderDayForPhp(){
      const datum = new Date(this.day)
      const day = this.weekDates.find(({id}) => id === datum.getDay())
      this.wochentag = day.name
      this.datum = (('0'+datum.getDate()).slice(-2))+'.'+("0" + (datum.getMonth() + 1)).slice(-2)+'.'+datum.getFullYear()
      this.getSlots(datum.getFullYear()+'-'+("0" + (datum.getMonth() + 1)).slice(-2)+'-'+(('0'+datum.getDate()).slice(-2)))
    },
    getSlots(date){
      const _this = this
      const fD = new FormData()
      fD.append('date', date)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getMySlotsFromDate', fD)
          .then((response) => {
            if(response.status === 250){
              this.slots = response.data
            }
          })
    }
  }
}
</script>

<style scoped>

</style>