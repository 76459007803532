<template>
  <main>
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-primary">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;">
          Urlaubsplanung
        </h1>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16" style="width: 90vw;max-width: 630px;border-radius: 10px">
            <h3 style="font-size: 24px">Offene Anfragen</h3>
            <div style="display: flex;flex-direction: column;gap: 12px">
              <div v-for="vacation in openVacations" :key="vacation.id" class="bgcolor-lightblue pad-12" style="border-radius: 10px">
                <div style="display: flex;justify-content: space-between">
                  <div style="display: flex;flex-direction: column;align-items: flex-start">
                    <h4 style="font-size: 18px;font-weight: normal"><b>{{vacation.title}} {{vacation.firstname}} {{vacation.lastname}}</b></h4>
                    <h4 style="font-size: 14px;font-weight: normal" class="label tiny blue t-center">{{vacation.workgroupTitle}}</h4>
                  </div>
                  <div style="display: flex;flex-direction: column">
                    <h4 style="font-size: 18px;font-weight: normal">Vom : <b>{{renderTimestampForJS(vacation.vacationStart)}}</b> bis <b>{{renderTimestampForJS(vacation.vacationEnd)}}</b></h4>
                    <h4 style="font-size: 18px;font-weight: normal">Urlaubstage : <b>{{vacation.daysTotal}}</b></h4>
                  </div>
                </div>
                <div style="display: flex;align-items: center;gap: 20px;margin-top: 12px">
                  <button class="primary button" @click="confirmVacation(vacation.id)">Genehmigen</button>
                  <button class="button" @click="denyDatas = vacation; denyModal=true">Ablehnen</button>
                  <h6 v-if="vacation.otherVacations" style="font-size: 16px;font-weight: normal">Es sind <b>{{vacation.otherVacations}} {{vacation.workgroupTitle}}</b> in dem Zeitraum in Urlaub</h6>
                </div>
              </div>
              <div v-if="openVacations.length == 0">
                <p class="pad-16 t-center color-grey">
                  Keine Offenen Anfragen vorhanden
                </p>
              </div>
            </div>
          </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16" style="width: 90vw;max-width: 630px;border-radius: 10px">
            <h3 style="font-size: 24px">Genehmigte Urlaub</h3>
            <div style="display: flex;flex-direction: column;gap: 12px">
              <div v-for="vacation in confirmedVacations" :key="vacation.id" class="bgcolor-lightgreen pad-12" style="border-radius: 10px">
                <div style="display: flex;flex-direction: column;align-items: flex-start">
                  <h4 style="font-size: 18px;font-weight: normal"><b>{{vacation.title}} {{vacation.firstname}} {{vacation.lastname}}</b></h4>
                  <h4 style="font-size: 14px;font-weight: normal" class="label tiny green t-center">{{vacation.workgroupTitle}}</h4>
                </div>
                <h4 style="font-size: 18px;font-weight: normal">Vom : <b>{{renderTimestampForJS(vacation.vacationStart)}}</b> bis <b>{{renderTimestampForJS(vacation.vacationEnd)}}</b></h4>
                <h4 style="font-size: 18px;font-weight: normal">Urlaubstage : <b>{{vacation.daysTotal}}</b></h4>
                <div style="margin-top: 12px">
                  <button class="button green">Bitte um Rückzug</button>
                </div>
              </div>
              <div v-if="confirmedVacations.length == 0">
                <p class="pad-16 t-center color-grey">
                  Keine genehmigten Urlaube vorhanden
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>

      </div>
      <deny-vacation v-if="denyModal" :denyDatas="denyDatas" @closingDenyVacation="denyModal=false; getVacations(0)"/>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import denyVacation from "@/components/vacationplaner/denyVacation";

export default {
  name: "VacationPlaner",
  title: "Urlaubsplaner",
  components: {
    denyVacation
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      openVacations:[],
      confirmedVacations:[],
      denyDatas:'',
      denyModal :false
    }
  },
  mounted() {
    this.config.loading = false
    this.getVacations(0)
    this.getVacations(1)
  },
  methods: {
      getVacations(status){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('praxisId', this.config.organizerId)
      fD.append('status', status)
      this.axios.post('vacation/getVacations', fD)
          .then((response) => {
            if(response.status === 250){
              if (status === 0){
                this.openVacations = response.data
                this.openVacations.map(async (singleVacation)=>{
                 singleVacation.otherVacations =await this.getVacationsInPeriod(singleVacation.id)
                  console.log(this.openVacations)
                })

              }
              else if(status === 1){
                this.confirmedVacations = response.data
                console.log(this.confirmedVacations)
              }
            }
          })
    },
    confirmVacation(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('status', 1)
      fD.append('id', id)
      this.axios.post('vacation/approveOrDenyVacation', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              this.getVacations(0)
            }
          })
    },
    async getVacationsInPeriod(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      const other= await this.axios.post('vacation/getVacationsInPeriod', fD)
      console.log(other)
          return other.data
    },

    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
  }
}
</script>

<style>

</style>