<template>
  <div class="vueModal" id="selectNewTemplate" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%; cursor: pointer" title="Schließen" @click="handleClosing" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="handleClosing">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <div v-if="!editTemplate" id="slotSettingsMenu">
        <h4 class="t-center">Vorlage wählen</h4>
        <div style="overflow-y: scroll; width: 100%; max-height: 500px;" class="mar-top-16">
          <div v-for="template in templates" :key="template.id" class="templateOverview">
            <div style="width: 100%;display: flex;justify-content: space-between">
              <div style="width: 24px; cursor: pointer" title="Löschen" @click="()=>deleteTemplate(template.id)">
                <img src="assets/images/icons/trash-icon.svg" style="opacity: 0.5">
              </div>
              <div style="width: 24px; cursor: pointer" title="Bearbeiten" @click="()=>editTemplateById(template.id)">
                <img src="assets/images/icons/edit-icon.svg" style="width: 100%;border-radius: 0">
              </div>
            </div>
            <div @click="()=>setTemplateSlots(template.id)" style="cursor: pointer" class="templateOverview">
              <h5 style="margin-top: -32px;" class="color-primary">
                {{ template.title }}
              </h5>
              <p class="color-grey">{{ template.description }}</p>
              <p>Schichten: {{ templateElements.find(obj=>{return obj[0].parentId === template.id})?.length }}</p>
<!--              <span class="label blue mar-top-16">von {{ getTemplateStartTime(template.id) }} bis {{ getTemplateEndTime(template.id) }}</span>-->
            </div>
          </div>
          <div v-if="templates.length == 0">
            <p class="pad-16 t-center color-grey">
              Es wurden noch keine Vorlagen erstellt
            </p>
          </div>
        </div>
      </div>
      <div v-if="editTemplate">
        <h4 class="t-center">Vorlage Bearbeiten</h4>
        <div class="field">
          <label>Vorlagen Titel:</label>
          <input v-model="editValues.title" type="text" id="templateNameInput" placeholder="Vorlagen Titel">
        </div>
        <div class="field">
          <label>Zusatzinfos:</label>
          <textarea v-model="editValues.description" placeholder="Zusatzinfos" rows="2" />
        </div>
        <div style="overflow-y: scroll; width: 100%; max-height: 200px;">
          <EditTemplateElementSlot :templateId="editValues.id" @editedTemplateElements="(values)=>{editElements= values}"/>
        </div>
        <button class="primary fluid button mar-top-16" @click="saveEditedTemplate">
          Speichern
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

import EditTemplateElementSlot from "@/components/praxisplaner/dayPlanModels/editTemplateElementSlot";
export default {
  name: "SelectTemplateModal",
  components:{
    EditTemplateElementSlot
  },
  props:["weekDates","slotItems","slotId"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      editTemplate:false,
      templateId:'',
      editValues:'',
      templates:[],
      templateElements:[],
      editElements:''
    }
  },
  mounted() {
    this.getTemplates()
  },
  methods:{
    handleClosing(){
      this.$emit("closingSelectSlotTemplate", false)
    },
    renderDateForJS(date){
      const jsDate = date.split('.')
      return jsDate[2] + '-' + jsDate[1] + '-' + jsDate[0]
    },
    getTemplates(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('praxisId', this.config.organizerId)

      this.axios.post('praxisplaner/getTemplates', fD)
          .then((response)=>{
            if(response.status === 250){
              this.templates = response.data
              this.templates.map(singleTemplate =>{
                this.getTemplateElements(singleTemplate.id)
                })
            }
            else{console.log(response)}
          })
    },
    getTemplateElements(templateID){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('parentId', templateID)

      this.axios.post('praxisplaner/getTemplateElements', fD)
          .then((response)=>{
            if (response.status === 250){
              this.templateElements.push(response.data)
            }
          })
    },
    getTemplateStartTime(id){
      const ElementsById = this.templateElements.find(obj=>{return obj[0].parentId === id})
      if (ElementsById){
        const firstTime = ElementsById.sort((a,b)=> parseInt(a.slotStart) - parseInt(b.slotStart))
        return firstTime[0]?.slotStart.slice(0,5)
      }
    },
    getTemplateEndTime(id){
      const ElementsById = this.templateElements.find(obj=>{return obj[0].parentId === id})
      if (ElementsById){
        const firstTime = ElementsById.sort((a,b)=> parseInt(b.slotEnd) - parseInt(a.slotEnd))
        return firstTime[0]?.slotEnd.slice(0,5)
      }
    },
    setTemplateSlots(id){
      const slotDate = this.renderDateForJS(this.weekDates.date)
      const slots = this.templateElements.find(obj=>{return obj[0].parentId === id})
      slots.map(singleSlot=>{
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        fD.append('organizerId', this.config.organizerId)
        fD.append('newSlotStart', slotDate + ' ' + singleSlot.slotStart)
        fD.append('newSlotEnd', slotDate + ' ' + singleSlot.slotEnd)
        fD.append('selctedworkgroup', singleSlot.workgroupId)
        fD.append('selectedworkarea', singleSlot.workareaId)

        this.axios.post('praxisplaner/setNewSlot', fD)

            .then(r => {
              if(r.status === 250) {
                this.$emit('closingSelectSlotTemplate', true)
                this.$emit('activateGetSlots', true)
                this.handleClosing()
              }
            })
            .catch(e => {
              console.log(e)
            })
      })
    },
    deleteTemplate(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('templateId', id)
      this.axios.post('praxisplaner/deleteTemplate', fD)
          .then(r => {
            if(r.status === 250) {
              this.$emit('closingSelectSlotTemplate', true)
              this.$emit('activateGetSlots', true)
              this.handleClosing()
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    editTemplateById(id){
      this.templateId=id
      this.getTemplate()
    },
    getTemplate(){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('templateId', this.templateId)
      this.axios.post('praxisplaner/getTemplateById', fD)
          .then(r => {
            if(r.status === 250) {
              this.editValues= r.data[0]
              this.editTemplate=true
              this.getTemplateElements(r.data[0].id)

            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    saveEditedTemplate(){
      const fD = new FormData
      const _this = this
      fD.append('sessionID', this.auth.sessionID)
      fD.append('templateId', this.editValues.id)
      fD.append('title', this.editValues.title)
      fD.append('description', this.editValues.description)
      this.axios.post('praxisplaner/saveTemplate', fD)
          .then(r => {
            if(r.status === 250) {
              if(this.editElements != ''){
                //Lösche alle bisherigen Elemente
                _this.deleteTemplateElements()
                //Fügt neue Elemente hinzu
                this.editElements.forEach(function (arrayItem) {
                  _this.saveEditedTemplateElements(arrayItem.parentId, arrayItem.slotEnd, arrayItem.slotStart, arrayItem.workareaId, arrayItem.workgroupId)
                });
                _this.handleClosing()
              }else{
                this.handleClosing()
              }
            }
          })
          .catch(e => {
            console.log(e)
          })


    },
    saveEditedTemplateElements(parentId,slotEnd,slotStart,workareaId,workgroupId){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('parentId', parentId)
      fD.append('slotEnd', slotEnd)
      fD.append('slotStart', slotStart)
      fD.append('workareaId', workareaId)
      fD.append('workgroupId', workgroupId)
      this.axios.post('praxisplaner/setNewTemplateElements', fD)
          .then(r => {
            if(r.status === 250) {
            }
          })
          .catch(e => {
            console.log(e)
          })


    },
    deleteTemplateElements(){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('templateId', this.editValues.id)
      this.axios.post('praxisplaner/deleteTemplateElements', fD)
          .then(r => {
            if(r.status === 250) {}
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>
  .templateOverview{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: #eeeeee;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 8px;
  }
  .field{
    margin-bottom: 8px;
  }
  .field input,
  .field select,
  .field textarea{
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    width: 100%;
  }
  .field input:focus{
    outline: none;
  }
</style>