<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12 pad-8" style="padding-top: 10px!important;">
        <img @click="config.sidebar = !config.sidebar" src="assets/images/icons/menu-icon.svg" class="width-28 menubtn">
      </div>
      <div class="col-sp-9 row-sp-12 pad-8 t-right">
        <router-link to="/notifications" style="margin-right: 8px;" @click="readNotifications()" id="notificationBell">
          <svg style="vertical-align: 4px;" width="24px" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M13.7 20h-3.5c-.7 0-1.3.8-.9 1.5.6.9 1.6 1.5 2.7 1.5s2.1-.6 2.6-1.5c.4-.7-.1-1.5-.9-1.5zM21.8 16.7l-.4-.5C19.8 14.1 19 11.6 19 9v-.7c0-3.6-2.6-6.8-6.2-7.2C8.6.6 5 3.9 5 8v1c0 2.6-.8 5.1-2.4 7.2l-.4.5c-.2.2-.3.6-.2.8.3.9 1.1 1.5 2 1.5h16c.9 0 1.7-.6 1.9-1.5.1-.3 0-.6-.1-.8z" fill="#5c5c5c" class="fill-000000"></path></svg>
          <span v-if="notificationCount > 0">{{ notificationCount }}</span>
        </router-link>
        <router-link to="/profil" class="desktopView">
          <img :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image width-32 circular">
        </router-link>
      </div>
    </div>
  </header>
  <transition name="modal" id="sideMenu">
    <div class="vueModal" v-if="config.sidebar" style="backdrop-filter: blur(20px) brightness(.9);">
      <div class="vueModalOuter" @click="config.sidebar = false">
        <div class="menuContent" @click.stop.prevent>
          <div class="header">
            <div class="pad-8">
              <router-link to="/dashboard" class="pad-8">
                <img style="margin-left: 16px;" src="assets/images/diagnostika-logo-horizontal.png" class="width-250">
              </router-link>
            </div>
          </div>
          <div class="pad-8" />
          <h5>Allgemein</h5>
          <ul class="navTop pad-4">
            <li>
              <router-link to="/dashboard">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z" fill="#0173fe" class="fill-000000" /></svg>                Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/mylayers">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" fill="#0173fe" class="fill-000000" /></svg>
                Meine Schichten
              </router-link>
            </li>
            <li>
              <router-link to="/timetracking">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" fill="#0173fe" class="fill-000000" /></svg>
                Meine Zeiten
              </router-link>
            </li>
            <li>
              <router-link to="/myvacation">
                <svg viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32"><circle cx="24" cy="9" r="4" fill="none" stroke="#0173fe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" /><path d="M24 1v1M24 16v1M17.1 5l.8.5M30.9 13l-.8-.5M30.9 5l-.8.5M27 31c-2.9-3.6-7.4-6-12.5-6S4.9 27.4 2 31h25zM11 11c-4.6 0-8.4 3.4-8.9 7.9-.1.6.4 1.1 1 1.1H19c.6 0 1.1-.5 1-1.1-.6-4.5-4.4-7.9-9-7.9z" fill="none" stroke="#0173fe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" /><path d="M15 20c-.2-5.1-1.9-9-4-9s-3.7 3.9-4 9M11 20v5" fill="none" stroke="#0173fe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" /></svg>
                Mein Urlaub
              </router-link>
            </li>
            <li>
              <router-link to="/profil">
                <svg viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 4c2.2 0 4 2.2 4 5s-1.8 5-4 5-4-2.2-4-5 1.8-5 4-5zm6.6 15.5C16.9 21 14.5 22 12 22s-4.9-1-6.6-2.5c-.4-.4-.5-1-.1-1.4 1.1-1.3 2.6-2.2 4.2-2.7.8.4 1.6.6 2.5.6s1.7-.2 2.5-.6c1.7.5 3.1 1.4 4.2 2.7.4.4.4 1-.1 1.4z" fill="#0173fe" class="fill-000000" /></svg>
                Mein Profil
              </router-link>
            </li>
            <li>
              <router-link to="/sickreport">
                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="4-Sick"><path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0Zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14Z" fill="#0173fe" class="fill-000000" /><circle cx="11" cy="11" r="2" fill="#0173fe" class="fill-000000" /><circle cx="21" cy="11" r="2" fill="#0173fe" class="fill-000000" /><path d="m19.62 18.19-2.22 1.58-2.22-1.58a1 1 0 0 0-1.16 0l-2.22 1.58-2.22-1.58-1.16 1.62 2.8 2a1 1 0 0 0 1.16 0l2.22-1.58 2.22 1.58a1 1 0 0 0 1.16 0l2.22-1.59 2.22 1.59 1.16-1.63-2.8-2a1 1 0 0 0-1.16.01Z" fill="#0173fe" class="fill-000000" /></g></svg>
                Krankmeldung
              </router-link>
            </li>
          </ul>
          <div class="pad-8" />
          <div v-if="user.checkPermission('shiftPlaner') || !user.checkPermission('praxisPlaner') || user.checkPermission('userManagement') || user.checkPermission('vacationPlaner') || user.checkPermission('timeEvaluation')">
            <h5>Verwaltung</h5>
            <ul class="navTop pad-4">
            <li v-if="user.checkPermission('shiftPlaner')">
              <router-link to="/praxisplaner">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zm4.799-2.013H8v-1.799l4.977-4.97 1.799 1.799-4.977 4.97zm5.824-5.817-1.799-1.799L15.196 11l1.799 1.799-1.372 1.371zM5 7h14v2H5V7z" fill="#0173fe" class="fill-000000" /></svg>
                Schichtplaner
              </router-link>
            </li>
            <li v-if="user.checkPermission('praxisPlaner')">
              <router-link to="/praxisverwaltung">
                <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M128 244v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12zm140 12h40c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12zm-76 84v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm76 12h40c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12zm180 124v36H0v-36c0-6.627 5.373-12 12-12h19.5V85.035C31.5 73.418 42.245 64 55.5 64H144V24c0-13.255 10.745-24 24-24h112c13.255 0 24 10.745 24 24v40h88.5c13.255 0 24 9.418 24 21.035V464H436c6.627 0 12 5.373 12 12zM79.5 463H192v-67c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v67h112.5V112H304v24c0 13.255-10.745 24-24 24H168c-13.255 0-24-10.745-24-24v-24H79.5v351zM266 64h-26V38a6 6 0 0 0-6-6h-20a6 6 0 0 0-6 6v26h-26a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h26v26a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V96h26a6 6 0 0 0 6-6V70a6 6 0 0 0-6-6z" fill="#0173fe" class="fill-000000" /></svg>
                Praxisverwaltung
              </router-link>
            </li>
            <li v-if="user.checkPermission('userManagement')">
              <router-link to="/benutzerverwaltung">
                <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" fill="#0173fe" class="fill-000000" /></svg>
                Benutzerverwaltung
              </router-link>
            </li>
            <li v-if="user.checkPermission('vacationPlaner')">
              <router-link to="/vacationplaner">
                <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g data-name="Glyph"><circle cx="42" cy="51" r="1" fill="#0173fe" class="fill-000000" /><path d="M51.91 32.73 22 50h16a1 1 0 0 1 0 2H11a1 1 0 0 1 0-2h7.84l-4.93-8.54a1.79 1.79 0 0 1 1.29-2.64l2.46-.35a1.74 1.74 0 0 1 1.79.87L21 42l6-3.47-9.72-9.28a2.39 2.39 0 0 1 .72-3.73 3.89 3.89 0 0 1 4.24.23l11.88 8.73L43.53 29a6.42 6.42 0 0 1 8.75 2.34 1 1 0 0 1-.37 1.39Z" fill="#0173fe" class="fill-000000" /><path d="M54 6h-3v-.5a3.5 3.5 0 0 0-7 0V6h-8.5v-.5a3.5 3.5 0 0 0-7 0V6H20v-.5a3.5 3.5 0 0 0-7 0V6h-3a5 5 0 0 0-5 5v46a5 5 0 0 0 5 5h44a5 5 0 0 0 5-5V11a5 5 0 0 0-5-5Zm-8-.5a1.5 1.5 0 0 1 3 0v4a1.5 1.5 0 0 1-3 0Zm-15.5 0a1.5 1.5 0 0 1 3 0v4a1.5 1.5 0 0 1-3 0ZM15 5.5a1.5 1.5 0 0 1 3 0v4a1.5 1.5 0 0 1-3 0Zm33 49.09V50a3 3 0 0 1 3-3h4.59ZM57 44a1 1 0 0 1-1 1h-5a5 5 0 0 0-5 5v5a1 1 0 0 1-1 1l-35-.06a3 3 0 0 1-3-3V20h50Z" fill="#0173fe" class="fill-000000" /></g></svg>
                Urlaubsplan
              </router-link>
            </li>
            <li v-if="user.checkPermission('timeEvaluation')">
              <router-link to="/timeevaluation">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" fill="#0173fe" class="fill-000000" /></svg>
                Zeitauswertung
              </router-link>
            </li>
          </ul>
          </div>
          <div class="footer">
            <div class="grid-12">
              <div class="col-sp-2 row-sp-12 pad-4">
                <img :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image width-40 circular">
              </div>
              <div class="col-sp-9 row-sp-12 pad-4">
                <h3>{{ user.firstname }} {{ user.lastname }}</h3>
                <h4>{{ user.email }}</h4>
              </div>
              <div class="col-sp-1 row-sp-12 t-right" style="padding-top: 16px;">
                <svg @click="signOutModal = true" style="cursor: pointer;" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4 12a1 1 0 0 0 1 1h7.59l-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 .21-.33 1 1 0 0 0 0-.76 1 1 0 0 0-.21-.33l-4-4a1 1 0 1 0-1.42 1.42l2.3 2.29H5a1 1 0 0 0-1 1ZM17 2H7a3 3 0 0 0-3 3v3a1 1 0 0 0 2 0V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a1 1 0 0 0-2 0v3a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Z" fill="#ffffff" class="fill-6563ff" /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="signOutModal" style="position: fixed;">
    <div class="vueModal" v-if="signOutModal">
      <div class="vueModalOuter" @click="signOutModal = false">
        <div class="vueModalInner">
          <h5 class="pad-4 t-center f-bold">
            Möchtest du dich wirklich ausloggen?
          </h5>
          <div class="pad-16" />
          <div class="grid-12">
            <div class="col-sp-12 row-sp-12 pad-4">
              <button class="button black fluid" @click="signOutModal = false">
                Abbrechen
              </button>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <button class="button primary fluid" @click="auth.logout();signOutModal = false">
                Abmelden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      menuModal: false,
      notificationCount: 0,
      signOutModal: false,
    }
  },
  mounted() {
    this.getNotificationsCount()
  },
  methods:{
    getNotificationsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotificationsCount', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = response.data.length
            }
          })
    },
    readNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = 0
            }
          })
    },
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0px;
}
header img.image{
  border: 2px solid #ffffff;
}
header .menubtn{
  background-color: #000000;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  filter: invert(1);
}
header .menubtn img{
  filter: invert(1);
}
#sideMenu{
  position: fixed;
}
#sideMenu .menuContent{
  width: 100%;
  max-width: 300px;
  position: fixed;
  left: 0;
  background-color: #ffffff;
  height: 100vh;
  top: 0;
  padding: 16px;
  padding-top: 8px;
  z-index: 99;
}
#sideMenu .menuContent h1{
  font-size: 2em;
  padding-left: 8px;
  padding-bottom: 16px;
}
#sideMenu .menuContent h5{
  text-transform: uppercase;
  font-size: 0.9em;
  color: var(--theme-diagnostika-secondary);
  font-weight: 800;
  margin-top: 16px;
  text-align: center;
}
#sideMenu .menuContent .navTop{
  padding: 0;
  margin: 0;
  padding-top: 8px;
}
#sideMenu .menuContent .navTop li{
  list-style: none;
  display: block;
  margin-bottom: 8px;
}
#sideMenu .menuContent .navTop li a{
  text-decoration: none;
  display: block;
  color: rgba(0,0,0,0.6);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.1em;
  transition: 0.3s;
  padding-left: 16px;
}
#sideMenu .menuContent .navTop li a svg{
  width: 24px;
  height: 24px;
  vertical-align: -6px;
  margin-right: 16px;
}
#sideMenu .menuContent .navTop li a button{
  float: right;
  margin-top: -4px;
}
#sideMenu .menuContent .navTop li a:hover{
  color: var(--theme-diagnostika-primary);
}
#sideMenu .menuContent .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  padding: 16px;
  background-color: var(--theme-diagnostika-primary);
}
#sideMenu .menuContent .footer h3{
  padding-top: 4px;
  font-weight: 700;
  font-size: 1em;
  color: #ffffff;
}
#sideMenu .menuContent .footer h4{
  font-weight: 300;
  color: #ffffff80;
  font-size: 0.9em;
}
#notificationBell {
  position: relative;
}
#notificationBell span{
  position: absolute;
  background-color: #0173fe;
  color: #ffffff;
  z-index: 3;
  left: 12px;
  top: -20px;
  border-radius: 90%;
  width: 12px;
  height: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  padding: 2px;

}
.desktopView{
  display: inline-block;
}
@media (max-width: 768px) {
  .desktopView{
    display: none;
  }
}
.vueModalOuter{
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: 0;
  left: 0;
}
</style>