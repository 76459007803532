import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage, deleteToken} from "firebase/messaging"
import {authStore} from "@/store/authStore";

export const notificationStore = defineStore('notification', {
    state: () => ({
        firebaseConfig: {
            apiKey: "AIzaSyDmLJlhs_vQHJGrPv6ZjHfrs5lB-GsIVOM",
            authDomain: "diagnostika-20north.firebaseapp.com",
            projectId: "diagnostika-20north",
            storageBucket: "diagnostika-20north.appspot.com",
            messagingSenderId: "981183830198",
            appId: "1:981183830198:web:b390850f072b7d873bae17"
        },
        fcmToken: null,
        messages: []
    }),
    getters: {
      unread(){
          return this.messages.filter(m => m.unread === 1).length
      }
    },
    actions: {
        /**
         * get push token
         */
        pushToken(){
            const config = configStore()
            if(config.cordova && typeof cordova.plugins.firebase !== 'undefined'){
                this.firebaseSetup()
            }else if("serviceWorker" in navigator && "PushManager" in window){
                this.pushWebToken()
            }else{
                console.log('no push method available')
            }
        },
        deleteToken(){
            const config = configStore()
            if(config.cordova && typeof cordova.plugins.firebase !== 'undefined'){
                cordova.plugins.firebase.messaging.deleteToken();
            }else if("serviceWorker" in navigator && "PushManager" in window){
                let fbMessaging = getMessaging(initializeApp(this.firebaseConfig))
                deleteToken(fbMessaging).then(r => {console.log('token deleted', r)}).catch(e => {console.log('error deleting token', e)})
            }
        },
        clearNotification(){
            aSyncData('notifications/read')
                .catch(e => {console.error(e)})

            const config = configStore()
            if(config.cordova && typeof cordova.plugins.firebase !== 'undefined'){
                cordova.plugins.firebase.messaging.clearNotifications();
                cordova.plugins.firebase.messaging.setBadge(0);
            }else if("serviceWorker" in navigator && "PushManager" in window){
                //geht net :D
            }
        },
        /**
         * push web token to backend
         */
        pushWebToken () {
            const _this = this
            try {
                let fbMessaging = null
                try {
                    fbMessaging = getMessaging(initializeApp(this.firebaseConfig))
                    getToken(fbMessaging).then(currentToken => {
                        if (currentToken) {
                            _this.fcmToken = currentToken
                            aSyncData('settings/addPushToken', {
                                token: JSON.stringify(currentToken),
                                platform: 'web'
                            })
                                .then()
                                .catch(e => {console.log(e)})
                            onMessage(fbMessaging, (payload) => {
                                _this.loadNotification()
                                new Audio('/assets/audio/notification.mp3').play()
                                    .catch(e => {
                                        console.error('audio not permitted')
                                    })
                            })
                        } else {
                            console.log('No registration token available. Request permission to generate one.')
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err)
                    })
                } catch (e) {
                    console.error(e)
                }
            } catch (e) {
                console.log(e)
            }
        },
        /**
         * init firebase
         */
        firebaseSetup () {
            const _this = this

            cordova.plugins.firebase.messaging.requestPermission({forceShow: false}).then(() => {
                cordova.plugins.firebase.messaging.onTokenRefresh(() => {
                    cordova.plugins.firebase.messaging.getToken().then(token =>  {
                        _this.sendToken(token)
                    })
                })
                cordova.plugins.firebase.messaging.getToken().then(token =>  {
                    _this.sendToken(token)
                })
            })

            cordova.plugins.firebase.messaging.onBackgroundMessage(payload => {
                _this.loadNotification()
                    .then(() => {
                        cordova.plugins.firebase.messaging.setBadge(_this.unread);
                    })
                _this.openLink(payload.link)
                //console.log(this.messages)
            })

            cordova.plugins.firebase.messaging.onMessage(payload => {
                new Audio('/assets/audio/notification.mp3').play()
                    .catch(e => {
                        console.error('audio not permitted')
                    })
                _this.loadNotification()
                    .then(() => {
                        cordova.plugins.firebase.messaging.setBadge(_this.unread);
                    })
                //console.log(this.messages)
            })
        },
        /**
         * send token to backend
         * @param fcmToken
         */
        sendToken (fcmToken) {
            const _this = this
            const config = configStore()
            if (this.fcmToken !== fcmToken) {
                _this.fcmToken = fcmToken
                aSyncData('settings/addPushToken', {
                    token: JSON.stringify(fcmToken),
                    platform: config.platform
                })
                    .then(r => {
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        openLink(link){
            const config = configStore()
            if(link && link.length > 0){
                const linkSmall = link.toLowerCase()
                if(linkSmall.startsWith('http://' + config.projectURLRaw) || linkSmall.startsWith('https://' + config.projectURLRaw) ||linkSmall.startsWith( + config.projectURLRaw)){
                    this.$router.push(link)
                }else{
                    window.open(linkSmall.startsWith('http') ? link : 'https://' + link, '_system')
                }
            }else{
                this.$router.push({name: 'Notifications'})
            }
        },
        loadNotification(){
            const auth = authStore()
            const config = configStore()
            return new Promise(resolve => {
                if(!auth.isAuth) resolve()
                aSyncData('notifications/getNotifications')
                    .then(r => {
                        if(r.status === 250){
                            this.messages = []
                            r.data.forEach(nT => {
                                try {
                                    this.messages.push({
                                        title: nT.data.notification.title,
                                        body: nT.data.notification.body,
                                        link: nT.data.data.link,
                                        unread: nT.unread,
                                        date: nT.date
                                    })
                                }catch (e) {

                                }
                            })
                            if(config.cordova) cordova.plugins.firebase.messaging.setBadge(this.unread)
                        }

                    })
                    .catch(e => {console.error(e)})
                    .finally(() => {
                        resolve()
                    })
            })
        }
    }
})