<template>
  <div class="vueModal" id="selectModal" style="position:fixed;">
    <div class="vueModalOuter" style="height: 100%" @click="handleClosing" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px;max-height: 80vh">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="handleClosing">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <h3 class="t-center pad-bottom-8">
        Schicht auswählen
      </h3>
      <div>
        <h5 />
      </div>
      <div>
        <div class="grid-12 pad-bottom-16">
          <div class="col-sp-10 row-sp-12">
            <input type="search" class="search" placeholder="Suche...">
          </div>
          <div class="col-sp-2 row-sp-12">
            <button class="primary button">
              Suchen
            </button>
          </div>
        </div>
        <div class="grid-12 user pad-4 mar-8" style="cursor: pointer;background-color: #E3EEFB50;border-radius:8px" v-for="singleUser in socialscoredata" :key="singleUser.id" @click="singleUser.secureBox = true">
          <div class="col-sp-2 row-sp-12 t-left circular">
            <img :src="config.projectURL + 'assets/images/users/' + singleUser.userimg" class="width-80 image circular">
          </div>
          <div class="col-sp-10 row-sp-12 pad-left-24">
            <h6>{{ singleUser.title }} {{ singleUser.firstname }} {{ singleUser.lastname }}</h6>
            <p>{{ singleUser.workareasTitle }}</p>
            <p style="font-size: 13px">
              {{ singleUser.worktypeTitle }}
            </p>
            <span class="label blue tiny">{{ singleUser.slotStart.slice(11,16) + ' - ' + singleUser.slotEnd.slice(11,16)}}</span>
          </div>
          <div v-if="singleUser.secureBox === true" class="col-sp-12">
            <h4 style="font-size: 18px" class="t-center pad-8">Möchtest du die schicht mit {{ singleUser.title }} {{ singleUser.firstname }} {{ singleUser.lastname }} Tauschen</h4>
            <div style="display: flex;justify-content: center" class="pad-8">

              <textarea v-model="reason" class="t-center" style="width: 75%" placeholder="Möchtest du deine Anfrage begründen ?"></textarea>
            </div>
            <div style="display: flex;justify-content: space-evenly;">
              <button class="primary button" @click="changeLayer(singleUser)">Bestätigen</button>
              <button class="button" @click.stop="singleUser.secureBox = false">Abbrechen</button>
            </div>
          </div>
        </div>
        <div v-if="socialscoredata == null">
          <p class="t-center pad-16">
            Keine passenden Mitarbeiter*innen gefunden
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
export default {
  name: "changeLayer",
  props:['actDate','slotId','actSlot'],
  data(){
    return{
      socialscoredata: null,
      actualDate:new Date(this.actDate),
      reason:'',
      OtherLayer:[],
      MyLayers:[]
    }
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  mounted(){
    // this.getOtherLayersByBenny()
    this.getOtherLayersByDate()
  },
  methods: {
    getOtherLayersByDate(){
      const _this = this
      const fD = new FormData()
      fD.append('date',this.actDate.slice(6,10)+'-'+this.actDate.slice(3,5)+'-'+this.actDate.slice(0,2))
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.slotId)
      this.axios.post('mylayers/getOtherLayersByDate', fD)
          .then((response) => {
            this.socialscoredata = response.data
            this.getMyOtherLayers()
          })
    },
    getMyOtherLayers(){
      const _this = this
      const fD = new FormData()
      fD.append('date',this.actDate.slice(6,10)+'-'+this.actDate.slice(3,5)+'-'+this.actDate.slice(0,2))
      fD.append('id',this.slotId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('mylayers/getMySlotsFromDateFiltered', fD)
          .then((response) => {
            this.MyLayers = response.data
            this.sliceArrays()
          })
    },
    sliceArrays(){
      //Alle Rausschneiden die sich überlappen
      let deleteArr = []
      this.socialscoredata.map((layer)=>{
        this.MyLayers.map((MyLayer)=>{
          if (new Date(layer.slotEnd) > new Date(MyLayer.slotStart) && new Date(layer.slotEnd) <= new Date(MyLayer.slotEnd)){
            deleteArr.push(layer.id)
            return
          }
          if (new Date(layer.slotStart) >= new Date(MyLayer.slotStart) && new Date(layer.slotStart) < new Date(MyLayer.slotEnd)){
            deleteArr.push(layer.id)
            return;
          }
          if (new Date(layer.slotStart) <= new Date(MyLayer.slotStart) && new Date(layer.slotEnd) >= new Date(MyLayer.slotEnd)){
            deleteArr.push(layer.id)
            return;
          }
        })
      })
      deleteArr.map((del)=>{
        const findI = this.socialscoredata.findIndex(({id})=>id === del)
        this.socialscoredata.splice(findI,1)
      })
    },
    changeLayer(changeId){
      console.log(this.slotId, changeId.userId, changeId.id ,this.reason)
      const fD = new FormData()
      fD.append('id2', changeId.userId)
      fD.append('slot1', this.slotId)
      fD.append('slot2', changeId.id)
      fD.append('reason', this.reason)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('mylayers/setNewChangeSlot', fD)
          .then((response) => {
            if (response.status === 250){
              this.handleClosing()
              this.$emit('loadSlotChanges')
            }
          })
    },
    handleClosing() {
      this.$emit("closingLayerChange", false)
    },
  }
}
</script>

<style scoped>

</style>