import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        firstname: '',
        lastname: '',
        email: '',
        avatar: ''
    }),
    actions: {
        checkPermission(permission){
            const auth = authStore()
            const config = configStore()
            try {
                return auth.permissions[config.organizerId][permission] === 1
            }catch (e) {
                return  false
            }
        }
    }
})





