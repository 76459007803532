<template>
  <div>
    <main id="praxisverwaltung">
      <div class="content-1400">
        <div class="pad-16">
          <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-primary">
            Übersicht
          </h2>
          <h1 style="padding-left: 4px;">
            Praxisverwaltung
          </h1>
          <div class="grid-12">
            <div class="col-sp-12 col-sd-8 row-sp-12">
              <div class="pad-4">
                <div class="grid-12" id="tabMenu">
                  <div class="col-sp-4 row-sp-12 t-center tabMenu" :class="menu === 0 ? 'active': ''" @click="menu = 0">
                    <h6>Allgemein</h6>
                  </div>
                  <div class="col-sp-4 row-sp-12 t-center tabMenu" :class="menu === 1 ? 'active': ''" @click="menu = 1">
                    <h6>Schichten</h6>
                  </div>
                  <div class="col-sp-4 row-sp-12 t-center tabMenu" :class="menu === 2 ? 'active': ''" @click="menu = 2">
                    <h6>Bereiche</h6>
                  </div>
                </div>
                <div class="bgcolor-white br-10">
<!--                  Untermenü-->
                  <!--                  Menüviews-->
                  <div class="pad-8" />
                  <div v-if="menu === 0" class="grid-12 pad-16">
                    <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
                      <div class="pad-16 t-center">
                        <img :src="logo" class="width-200" v-if="logo && !logoNew">
                      </div>
                      <cropper-upload @cropped="c => {logoNew = c;}" :ratio="1" ref="cropper">
                        <button class="button bgcolor-lightgrey color-black fluid">
                          Logo {{ organizerId > 0 ? 'Ändern' : 'Hochladen' }}
                        </button>
                      </cropper-upload>
                    </div>
                    <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
                      <div class="field">
                        <label>Praxisname</label>
                        <input type="text" placeholder="Praxisname" v-model="organizerName" @keyup="saveMessage = false">
                      </div>
                      <div class="grid-12 mar-top-16 mar-bottom-16">
                        <div class="col-sp-10 row-sp-12">
                          <div class="field pad-right-8">
                            <label>Straße</label>
                            <input type="text" placeholder="Straße" v-model="street" @keyup="saveMessage = false">
                          </div>
                        </div>
                        <div class="col-sp-2 row-sp-12">
                          <div class="field">
                            <label>Haus Nr.</label>
                            <input type="text" placeholder="Haus Nr." v-model="hnr" @keyup="saveMessage = false">
                          </div>
                        </div>
                        <div class="col-sp-4 row-sp-12">
                          <div class="field pad-right-8">
                            <label>PLZ</label>
                            <input type="text" placeholder="PLZ" v-model="plz" @keyup="saveMessage = false">
                          </div>
                        </div>
                        <div class="col-sp-8 row-sp-12">
                          <div class="field">
                            <label>Ort</label>
                            <input type="text" placeholder="Ort" v-model="city" @keyup="saveMessage = false">
                          </div>
                        </div>
                        <div class="col-sp-12 row-sp-12">
                          <div class="field">
                            <label>Bundesland</label>
                            <select v-model="region" required>
                              <option value="BW">
                                Baden-Württemberg
                              </option>
                              <option value="BY">
                                Bayern
                              </option>
                              <option value="BE">
                                Berlin
                              </option>
                              <option value="BB">
                                Brandenburg
                              </option>
                              <option value="HB">
                                Bremen
                              </option>
                              <option value="HH">
                                Hamburg
                              </option>
                              <option value="HE">
                                Hessen
                              </option>
                              <option value="MV">
                                Mecklenburg-Vorpommern
                              </option>
                              <option value="NI">
                                Niedersachsen
                              </option>
                              <option value="NW">
                                Nordrhein-Westfalen
                              </option>
                              <option value="RP">
                                Rheinland-Pfalz
                              </option>
                              <option value="SL">
                                Saarland
                              </option>
                              <option value="SN">
                                Sachsen
                              </option>
                              <option value="ST">
                                Sachsen-Anhalt
                              </option>
                              <option value="SH">
                                Schleswig-Holstein
                              </option>
                              <option value="TH">
                                Thüringen
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="grid-12 mar-top-16 mar-bottom-16">
                        <div class="col-sp-12 col-tb-6 row-sp-12">
                          <div class="field pad-right-8">
                            <label>Telefonnummer</label>
                            <input type="tel" placeholder="Telefonnummer" v-model="phone" @keyup="saveMessage = false">
                          </div>
                        </div>
                        <div class="col-sp-12 col-tb-6 row-sp-12">
                          <div class="field">
                            <label>E-Mail-Adresse</label>
                            <input type="email" placeholder="E-Mail-Adresse" v-model="email" @keyup="saveMessage = false">
                          </div>
                        </div>
                      </div>
                      <div class="t-right">
                        <div class="pad-4" />
                        <button @click="savePraxisDetails" class="button primary" :class="{disabled: organizerName.length < 1 || saveMessage}" :disabled="organizerName.length < 1 || saveMessage">
                          {{ saveMessage ? 'Gespeichert' : (organizerId > 0 ? 'Änderungen Speichern' : 'Praxis erstellen') }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <shift-settings v-if="menu === 1"/>
                  <work-settings v-if="menu === 2" />
                </div>
              </div>
            </div>
            <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
              <div class="bgcolor-white pad-16 br-10">
                <div class="grid-12">
                  <div class="col-sp-12 row-sp-12">
                    <h3 style="padding-top: 2px;" class="t-center">
                      Praxis auswählen
                    </h3>
                  </div>
                </div>
                <praxisItems v-for="pitems in config.organizer" :praxis-items="pitems" :key="pitems.id" @update="getPraxisDetails(), getMembers(), getWorkshifts(), getPraxisPreferences()" />
                <div class="pad-4" />
                <button @click="createClub" class="primary fluid button">
                  Neue Praxis erstellen
                </button>
              </div>
              <div class="bgcolor-white pad-16 br-10 mar-top-8">
                <h3 class="t-center">
                  Mitarbeiter*innen
                </h3>
                <div class="pad-8" style="display: flex;flex-flow: wrap;gap: 4px;">
                  <div v-for="member in members" :key="member.id" class="singleMember">
                    <router-link :to="'/benutzerverwaltung/edit/'+member.id" :title="member.title+' '+member.firstname+' '+member.lastname">
                      <img :src="config.projectURL + 'assets/images/users/'+member.userImg" class="image width-40 circular bgcolor-lightblue">
                    </router-link>
                  </div>
                </div>
                <div>
                  <router-link to="/benutzerverwaltung" class="primary fluid button">
                    Benutzerverwaltung
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import praxisItems from "@/components/profil/praxis";
import CropperUpload from "@/components/images/cropperUpload";
import ShiftSettings from "@/components/praxisverwaltung/shiftSettings";
import WorkSettings from "@/components/praxisverwaltung/workSettings";

export default {
  name: "Praxisverwaltung",
  title: "Praxisverwaltung",
  components: {
    WorkSettings,
    ShiftSettings,
    CropperUpload,
    praxisItems: praxisItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      menu:0,
      praxisItems: [],
      categoryOptionItems: [],
      organizerName: '',
      logo: '',
      logoNew: false,
      organizerId: 0,
      saveMessage: false,
      members: '',
      street: '',
      hnr: '',
      city: '',
      plz: '',
      phone: '',
      email: '',
      region: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.getPraxisDetails()
    this.getMembers()
  },
  methods: {

    getPraxisDetails(){
      const _this = this
      const fD = new FormData()
      this.$refs.cropper.reset()
      this.saveMessage = false
      this.logoNew = false
      this.organizerId = this.config.getCurrentOrganizer.id
      this.organizerName = this.config.getCurrentOrganizer.organizerName
      this.logo = this.config.projectURL + 'assets/images/eventhoster/'+this.config.getCurrentOrganizer.logo
      fD.append('organizerId', this.config.getCurrentOrganizer.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getPraxisById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.street = response.data.street
              _this.hnr = response.data.hnr
              _this.plz = response.data.plz
              _this.city = response.data.city
              _this.phone = response.data.phone
              _this.email = response.data.email
              _this.region = response.data.region
            }
          })
    },
    createClub(){
      this.$refs.cropper.reset()
      this.saveMessage = false
      this.logoNew = false
      this.organizerId = -1
      this.organizerName = ''
      this.logo = false
    },
    savePraxisDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.organizerId)
      fD.append('organizerName', this.organizerName)
      fD.append('street', this.street)
      fD.append('hnr', this.hnr)
      fD.append('plz', this.plz)
      fD.append('city', this.city)
      fD.append('region', this.region)
      fD.append('phone', this.phone)
      fD.append('email', this.email)
      fD.append('logo', this.logoNew)
      this.axios.post('praxisverwaltung/savePraxisDetails', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((r) => {
            if(r.status === 250){
              _this.config.organizer = r.data.organizer
              _this.config.organizerId = r.data.organizerId
              _this.getPraxisDetails()
              _this.saveMessage = true
            }
          })
    },
    getEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/geteventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    },
    getMembers(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getMembers', fD)
          .then((response) => {
            if(response.status === 250){
              _this.members = response.data
            }
          })
    },


  }
}
</script>

<style>
main#praxisverwaltung{
  padding-bottom: 80px;
}
  #praxisverwaltung h1{
    font-size: 2em;
  }
  #createClubModal h3,
  #praxisverwaltung h3{
    font-size: 1.2em;
  }
  #createClubModal .field input,
  #createClubModal .field textarea,
  #praxisverwaltung .field input,
  #praxisverwaltung .field textarea{
    display: block;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #createClubModal .field select,
  #praxisverwaltung .field select{
    display: block;
    width: 100%;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  .disabled{
    background-color: grey !important;
  }
  .singleMember img{
    border: 3px solid var(--lightblue);
    transition: 0.3s;
  }
.singleMember img:hover{
  border: 3px solid var(--theme-diagnostika-primary);
  transition: 0.3s;
}
.field{
  margin-bottom: 8px;
}
.field input,
.field select{
  max-width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  width: 100%;
}
.field input:focus{
  outline: none;
}
.activeBoxShadow{
  box-shadow: 0px -6px 5px -2px rgba(1,115,254,0.48) inset;
}
</style>