<template>
  <div class="content-1400">
    <div style="display: flex;flex-direction: column;gap: 20px">
      <div v-for="shift in workshifts" :key="shift.id" class="grid-12 mar-bottom-24">
        <div class="field col-sp-12 row-sp-12">
          <label>{{shift.title}} ({{shift.startTime.slice(0,5)}} - {{shift.endTime.slice(0,5)}})</label>
          <input type="range" min="0" max="100">
        </div>
        <div class="col-sp-6 row-sp-12">
          <span class="label tiny">Unbeliebt</span>
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <span class="label tiny">beliebt</span>
        </div>
      </div>
      <div class="grid-12">
        <div style="grid-column-start: 7" class="col-sp-6 row-sp-12 t-right">
          <button class="button primary">Änderungen Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "WorkTimePreferences",
  setup: function () {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      workshifts: []
    }
  },
  mounted() {
    this.getWorkshifts()
    this.getWorkshiftsPreference()
  },
  methods: {

    getWorkshifts(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      this.axios.post('praxisverwaltung/getWorkshifts', fD)
          .then((response) => {
            if(response.status === 250){
              this.workshifts =response.data
              console.log(this.workshifts)
            }
          })
    },
    getWorkshiftsPreference(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getMyShiftPreferences', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              // this.workshifts =response.data
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1] + ':' + jsTime[2]
    },
  }
}

</script>

<style scoped>
 input{
   padding: 0px;
 }
</style>