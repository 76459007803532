<template>
  <div>
    <div>
      <main id="zeiterfassung">
        <div class="content-1400">
          <div class="pad-16">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-6 row-sp-12">
                <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-primary">
                  Übersicht
                </h2>
                <h1 style="padding-left: 4px;">
                  Zeiterfassung
                </h1>
              </div>
              <div class="col-sp-12 col-sd-6 row-sp-12 t-right">
                <!--
                <button class="primary button" @click="addTimeModal = true">
                  Manuelle Zeit erfassen
                </button>
                -->
              </div>
            </div>
            <div class="grid-12 mar-top-16">
              <div class="col-sp-12 col-sd-3 row-sp-12 pad-4">
                <div class="bgcolor-lightblue pad-16 br-10 color-primary t-center mar-bottom-8">
                  <svg width="48px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-v-132370e2=""><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" fill="#0173fe" class="fill-000000" data-v-132370e2="" /></svg>
                  <h6 class="f-normal">
                    Arbeitszeit
                  </h6>
                  <h2>{{ sumOfTime }}h</h2>
                </div>
                <div class="bgcolor-lightblue pad-16 br-10 color-primary t-center mar-bottom-8">
                  <svg width="48px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-v-132370e2=""><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" fill="#0173fe" class="fill-000000" data-v-132370e2="" /></svg>
                  <h6 class="f-normal">
                    Überstunden
                  </h6>
                  <h2>{{ overTimeHours }}:{{overTimeMinutes}}</h2>
                </div>
                <div class="bgcolor-lightblue pad-16 br-10 color-primary t-center mar-bottom-8">
                  <svg width="48px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" data-v-132370e2=""><g data-name="4-Sick" data-v-132370e2=""><path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0Zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14Z" fill="#0173fe" class="fill-000000" data-v-132370e2="" /><circle cx="11" cy="11" r="2" fill="#0173fe" class="fill-000000" data-v-132370e2="" /><circle cx="21" cy="11" r="2" fill="#0173fe" class="fill-000000" data-v-132370e2="" /><path d="m19.62 18.19-2.22 1.58-2.22-1.58a1 1 0 0 0-1.16 0l-2.22 1.58-2.22-1.58-1.16 1.62 2.8 2a1 1 0 0 0 1.16 0l2.22-1.58 2.22 1.58a1 1 0 0 0 1.16 0l2.22-1.59 2.22 1.59 1.16-1.63-2.8-2a1 1 0 0 0-1.16.01Z" fill="#0173fe" class="fill-000000" data-v-132370e2="" /></g></svg>
                  <h6 class="f-normal">
                    Krankheitstage
                  </h6>
                  <h2>0</h2>
                </div>
                <div class="bgcolor-lightblue pad-16 br-10 color-primary t-center mar-bottom-8">
                  <svg width="48px" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" data-v-132370e2=""><circle cx="24" cy="9" r="4" fill="none" stroke="#0173fe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" data-v-132370e2="" /><path d="M24 1v1M24 16v1M17.1 5l.8.5M30.9 13l-.8-.5M30.9 5l-.8.5M27 31c-2.9-3.6-7.4-6-12.5-6S4.9 27.4 2 31h25zM11 11c-4.6 0-8.4 3.4-8.9 7.9-.1.6.4 1.1 1 1.1H19c.6 0 1.1-.5 1-1.1-.6-4.5-4.4-7.9-9-7.9z" fill="none" stroke="#0173fe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" data-v-132370e2="" /><path d="M15 20c-.2-5.1-1.9-9-4-9s-3.7 3.9-4 9M11 20v5" fill="none" stroke="#0173fe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" data-v-132370e2="" /></svg>
                  <h6 class="f-normal">
                    Urlaubstage
                  </h6>
                  <h2>0</h2>
                </div>
              </div>
              <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
                <div class="bgcolor-white br-10 pad-16 mar-bottom-16 grid-12">
                  <div class="col-sp-12 row-sp-12">
                    <h5 class="pad-8">
                      Erfasste Zeiten:
                    </h5>
                  </div>
                  <div class="col-sp-12 col-sd-5 row-sp-12 pad-4">
                    <div class="field">
                      <label>Von</label>
                      <input type="datetime-local" v-model="startTime">
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-5 row-sp-12 pad-4">
                    <div class="field">
                      <label>Bis</label>
                      <input type="datetime-local" v-model="endTime">
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-2 row-sp-12 pad-4">
                    <button class="primary button fluid mar-top-20 pad-12" @click="getTrackItems()">
                      Filtern
                    </button>
                  </div>
                </div>
                <div v-if="successMessage" class="pad-16 t-center">
                  <span class="green label">{{ successMessage }}</span>
                </div>
                <section class="bgcolor-white pad-16 br-10">
                  <div v-for="singleItem in trackItems" :key="singleItem.id" class="pad-16 br-8 mar-bottom-12 trackItem">
                    <div class="grid-12">
                      <div class="col-sp-12 col-tb-6 row-sp-12">
                        <h6 style="font-size: 1em;" class="mar-left-8">
                          {{ singleItem.trackType }}
                          <span class="f-light" style="font-size: 0.9em;">[#{{ singleItem.id }}]</span>
                        </h6>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 t-right mar-bottom-8">
                        <button class="button tiny primary" @click="getBreaks(singleItem.id)">
                          Pausenzeiten ansehen
                        </button>
                      </div>
                      <div class="col-sp-12 row-sp-12">
                        <div>
                          <span class="label tiny grey">{{ renderTimestampForJS(singleItem.trackStart) }} </span>
                          <span> - </span>
                          <span class="label tiny grey" v-if="singleItem.trackEnd != null">{{ renderTimestampForJS(singleItem.trackEnd) }} </span>
                          <span class="label tiny grey" v-else>Jetzt</span>
                        </div>
                        <span class="mar-top-4 label blue">
                          <b style="font-size: 0.9em;">Arbeitszeit:</b>
                          {{ singleItem.total }}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-sp-12 col-sd-1 row-sp-12 pad-4">
                <div class="bgcolor-lightblue pad-8 br-10 t-center mar-bottom-8" style="cursor: pointer;" @click="download()">
                  <svg width="40px" data-name="Layer 1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.92 15.62a1.15 1.15 0 0 0-.21-.33l-3-3a1 1 0 0 0-1.42 1.42l1.3 1.29H12a1 1 0 0 0 0 2h5.59l-1.3 1.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l3-3a.93.93 0 0 0 .21-.33 1 1 0 0 0 0-.76ZM14 20H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h4a1 1 0 0 0 .92-.62 1 1 0 0 0-.21-1.09l-6-6a1.07 1.07 0 0 0-.28-.19h-.09l-.28-.1H6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h8a1 1 0 0 0 0-2ZM13 5.41 15.59 8H14a1 1 0 0 1-1-1Z" fill="#0173fe" class="fill-6563ff" /></svg>
                  <p class="color-blue">
                    Export
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div class="vueModal" id="selectNewSlotModal" v-if="breaksModal">
        <div class="vueModalOuter" @click="breaksModal = false;" />
        <div class="vueModalInner">
          <div>
            <h3 class="t-center pad-16">
              Pausen
            </h3>
            <div v-if="breakTimeTotal != null">
              <h5 style="font-size: 0.9em;" class="f-normal t-center">
                Zeit gesamt: <b>{{ breakTimeTotal }}</b>
              </h5>
              <div v-for="singleBreak in breakItems" :key="singleBreak.id" class="pad-8 br-8">
                <span class="label tiny grey" style="border-bottom-right-radius: 0; border-top-right-radius: 0;">
                  {{ renderTimestampForJS(singleBreak.breakStart) }} - {{ renderTimestampForJS(singleBreak.breakEnd) }}
                </span>
                <span class="label tiny blue f-bold" style="border-bottom-left-radius: 0; border-top-left-radius: 0;">
                  {{ singleBreak.total }}
                </span>
              </div>
            </div>
            <div v-else>
              <p class="t-center pad-16">
                Es wurden keine Pausen in dieser Schicht erfasst
              </p>
            </div>
            <div class="t-center pad-16">
              <button class="primary button" @click="breaksModal = false">
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vueModal" v-if="addTimeModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="addTimeModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="addTimeModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h4 class="t-center">
            Zeit erfassen
          </h4>
          <div class="pad-16">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12">
                <div class="field">
                  <label for="newStartTime">Arbeitsbeginn:</label>
                  <input type="datetime-local" id="newStartTime" v-model="newStartTime" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                </div>
              </div>
              <div class="col-sp-6 row-sp-12">
                <div class="field">
                  <label for="newEndTime">Arbeitsende:</label>
                  <input type="datetime-local" id="newEndTime" v-model="newEndTime" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                </div>
              </div>
            </div>
            <div class="pad-8" />
            <button class="primary fluid button" @click="addNewTime()">
              Hinzufügen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import moment from "moment";

export default {
  name: "Timetracking",
  title: "Zeiterfassung",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      trackItems: [],
      breakItems: [],
      breakTimeTotal: 0,
      sumOfTime: 0,
      breaksModal: false,
      startTime: moment().format('yyyy-MM')+'-01 00:00:00',
      endTime: moment().format('yyyy-MM-DD')+'T'+moment().format('HH:mm'),
      addTimeModal: false,
      newStartTime: '',
      newEndTime: '',
      successMessage: '',
      overTimeHours:0,
      overTimeMinutes:0
    }
  },
  mounted() {
    this.config.loading = false
    this.getTrackItems()
    this.getOverTimeById()
  },
  methods: {
    addNewTime(){
      const _this = this
      const fD = new FormData()
      fD.append('startTime', this.newStartTime)
      fD.append('endTime', this.newEndTime)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/addNewTime', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.addTimeModal = false
              _this.getTrackItems()
              _this.successMessage = 'Zeit erfolgreich hinzugefügt'
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1] + ':' + jsTime[2]
    },
    download(){
      let csv = ''
      for(let row = 0; row < this.trackItems.length; row++){
        let keysAmount = Object.keys(this.trackItems[row]).length
        let keysCounter = 0
        if(row === 0){
          for(let key in this.trackItems[row]){
            csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
            keysCounter++
          }
        }
        for(let key in this.trackItems[row]){
          csv += this.trackItems[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }
        keysCounter = 0
      }
      let link = document.createElement("a")
      link.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodeURI(csv));
      link.setAttribute("download", "zeiten_diagnostika.csv");

      link.click();
    },
    getBreaks(id){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getBreaks', fD)
          .then((response) => {
            if(response.status === 250){
              _this.breakItems = response.data
            }
          })
      this.axios.post('timetracking/getBreaksTotal', fD)
          .then((response) => {
            if(response.status === 250){
              _this.breakTimeTotal = response.data.totalBreak
              _this.breaksModal = true
            }
          })
    },
    getTrackItems(){
      const _this = this
      const fD = new FormData()
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getTrackItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.trackItems = response.data
              _this.getSumOfTime()
            }
          })
    },
    getSumOfTime(){
      const _this = this
      const fD = new FormData()
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getSumOfTime', fD)
          .then((response) => {
            if(response.status === 250){
              _this.sumOfTime = response.data
              console.log(this.sumOfTime)
            }
          })
    },
    getOverTimeById(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getOverTimeById', fD)
          .then((response) => {
            if(response.status === 250){
              console.log(response.data)
              if (response.data.overTime === 0 || response.data.overTime === null){
                this.overTimeHours = 0
                this.overTimeMinutes = 0
                return
              }
              this.overTimeHours = response.data.overTime.slice(0,-3)
              const splitMin = response.data.overTime.split('.')
                  this.overTimeMinutes = ((splitMin[1]/100)*60)
              console.log(this.overTimeMinutes)
            }
          })
    },

  }
}
</script>

<style>
  main#zeiterfassung{
    padding-bottom: 80px;
  }
  #zeiterfassung h1{
    font-size: 2em;
  }
  #zeiterfassung h3{
    font-size: 1.2em;
  }
  .trackItem{
    background-color: rgba(0,0,0,0.02);
  }
  @media (max-width: 767px){
    .trackItem,
    .trackItem .t-right{
      text-align: center!important;
    }
  }
  .field{
    margin-bottom: 8px;
  }
  .field input,
  .field select{
    max-width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    width: 100%;
  }
  .field input:focus{
    outline: none;
  }
</style>