<template>
  <select v-model="editWorkarea" name="editworkgroup" required style="min-width: 150px;display: block;" @change="setNewValue">
    <option v-for="workarea in workareas"
            :key="workarea.id"
            :value="workarea.id"
            :selected="editWorkarea === ''? workarea.id : editWorkarea"
    >

      {{ workarea.title }}
    </option>
  </select>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "WorkareaTemplateEdit",
  props:["workgroupId","editWork"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      editWorkarea:this.editWork,
      editWorkgroup:this.workgroupId,
      workareas:[]
    }
  },
  mounted() {
    this.getWorkareas()
  },
  methods:{
    getWorkareas(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroupid', this.editWorkgroup)
      this.axios.post('usermanagement/getWorkareas', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workareas = r.data
              if (this.editWorkarea=== ''){
                this.editWorkarea = r.data[0].id
                this.setNewValue()
              }
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    setNewValue(){
      this.$emit("newWorkarea", this.editWorkarea)
    }
  }
}
</script>

<style scoped>

</style>