<template>
  <main id="dashBoard">
    <div class="content-1400">
      <div class="pad-16">
        <div class="col-sp-12 row-sp-12" v-if="sickMessage">
          <div class="label red t-center pad-16" style="display: block;">
            <p>
              <b>Achtung!</b> Mindestens eine Schicht muss aufgrund eines Krankheitsfalles neu besetzt werden.
            </p>
          </div>
        </div>
        <div style="display: flex;flex-wrap: wrap;gap: 25px;justify-content: center">
          <div style="display: flex;flex-direction: column;gap: 25px">
<!--            Dashboard opener-->
            <div class="bgcolor-white" style="min-height: 150px;max-width: 600px;width: 90vw;padding: 32px;box-sizing: border-box;border-radius: 20px">
              <h2 class="color-primary" style="font-size: 20px;">👋 Hey {{user.firstname}},</h2>
              <h3 style="font-size: 24px">Willkommen im Dashboard</h3>
            </div>
            <!--Überstunden anzeige -->
            <div class="bgcolor-white contentBox">
              <h3 style="font-size: 24px;">⏱️ Stundenkonto</h3>
                <p :class="overtime < 0?'color-red':'color-green'" style="font-size: 20px" class=" pad-16">{{overTimeHours}} Stunden :{{overTimeMinutes}} Minuten</p>
            </div>
<!--            Aktuelle Schicht-->
            <div class="bgcolor-white contentBox">
              <h3 style="font-size: 24px;">⏱️ Deine aktuelle Schicht</h3>
              <div v-for="slot in currentSlots" :key="slot.id" style="margin-top: 16px">
                <div style="width: 100%;height: 20px;background-color:#0173fe10;border: 2px solid #0173fe;border-radius: 8px">
                  <div :style="renderSlotProgress(slot.slotStart, slot.slotEnd)" style="height: 100%;" class="bgcolor-primary"></div>
                </div>
                <div style="display: flex;justify-content: space-between">
                  <p>{{slot.slotStart.slice(11,16)}}</p>
                  <p>{{slot.slotEnd.slice(11,16)}}</p>
                </div>
              </div>
              <div class="" v-if="!currentSlots[0]">
                <p class="color-grey t-center pad-16">Du hast keine aktuelle Schicht.</p>
              </div>
            </div>
<!--            Geburtstage-->
            <div class="bgcolor-white contentBox" style="display: flex;flex-direction: column;gap: 20px">
              <h3 style="font-size: 24px;">🎂 Teamgeburtstage</h3>
              <div v-for="birthday in birthdays" :key="birthday.id" class="pad-16" style="border-radius: 16px;background-color: #0053B610;display: flex;gap: 16px;align-items: center">
                <div style="height: 50px;width: 50px;border-radius: 50%;overflow: hidden ">
                  <img style="height: 100%;width: 100%;" :src="config.projectURL + 'assets/images/users/'+birthday.profileImg"></div>
                <div>
                  <h4 style="color: #0053B6;font-size: 16px; font-weight: 600; padding-bottom: 4px;" v-if="birthday.id == user.id">Du 🥳</h4>
                  <h4 style="color: #0053B6;font-size: 16px; font-weight: 600; padding-bottom: 4px;" v-else>{{birthday.title}} {{birthday.firstname}} {{birthday.lastname}}</h4>
                  <h5 style="color: #0053B6;font-size: 16px;font-weight: 400">{{renderBirtday(birthday.dob)}}</h5>
                </div>
              </div>
              <div class="t-center pad-16" v-if="birthdays.length == 0">
                <p class="color-grey">
                  Es gibt keine Geburtstage innerhalb der nächsten 3 Monate<br>
                  <span style="font-size: 0.9em;" class="f-bold">Dies ist aber kein Grund auf Kuchen zu verzichten! 🍰</span>
                </p>
              </div>
            </div>
          </div>
          <div style="display: flex;flex-direction: column;gap: 25px">
            <div class="bgcolor-white contentBox" style="display: flex;flex-direction: column;justify-content: space-between">
              <h3 style="font-size: 24px;margin-bottom: 16px">🔄️ Offene Schichttausch Anfragen</h3>
              <div v-for="openSlot in openSlotChanges" :key="openSlot.id" style="background-color: rgb(250, 250, 250);" class="br-10 pad-16">
                <div class="grid-12">
                  <div class="col-sp-6 row-sp-12">
                    <p>Aktuelle Schicht</p>
                    <h3 class="color-primary">{{openSlot.slot2_data.title}} {{openSlot.slot2_data.firstname}} {{openSlot.slot2_data.lastname}}</h3>
                    <p>{{openSlot.slot2_data.workareasTitle}}</p>
                    <p>{{renderTimestampForJS(openSlot.slot2_data.slotStart).slice(0,-8)}}</p>
                    <p>{{renderTimestampForJS(openSlot.slot2_data.slotStart).slice(10,16)}} - {{renderTimestampForJS(openSlot.slot2_data.slotEnd).slice(10,16)}}</p>
                  </div>
                  <div class="col-sp-6 row-sp-12" style=" text-align: right;">
                    <p>Schichtanfrage</p>
                    <h3 class="color-primary">{{openSlot.slot1_data.title}} {{openSlot.slot1_data.firstname}} {{openSlot.slot1_data.lastname}}</h3>
                    <p>{{openSlot.slot1_data.workareasTitle}}</p>
                    <p>{{renderTimestampForJS(openSlot.slot1_data.slotStart).slice(0,-8)}}</p>
                    <p>{{renderTimestampForJS(openSlot.slot1_data.slotStart).slice(10,16)}} - {{renderTimestampForJS(openSlot.slot1_data.slotEnd).slice(10,16)}}</p>
                    <p>Begründung: {{openSlot.reason}}</p>
                  </div>
                </div>
                <div class="grid-12" style="column-gap: 12px">
                  <div class="col-sp-6 row-sp-12 t-center">
                    <button class="button lightgreen" style="width: 100%; margin-top: 20px;" @click="acceptSlotChange(openSlot.id)">Anfrage annehmen</button>
                  </div>
                  <div class="col-sp-6 row-sp-12 t-center">
                    <button class="button lightred" style="width: 100%; margin-top: 20px;" @click="denySlotChange(openSlot.id)">Anfrage ablehnen</button>
                  </div>
                </div>
              </div>
              <div class="t-center pad-16" v-if="openSlotChanges.length === 0">
                <p class="color-grey pad-bottom-8">
                  Aktuell sind keine offenen Schichtwechsel Anfragen vorhanden.
                </p>
                <router-link to="/mylayers" class="button primary">Schicht tauschen</router-link>
              </div>
              <h4 v-if="openSlotChanges[1]" class="t-center" style="font-size: 16px;cursor: pointer">Mehr Anzeigen</h4>
            </div>
            <div class="bgcolor-white contentBox">
              <h3 class="pad-top-8" style="font-size: 24px">📅 Die nächsten 7 Tage</h3>
              <div v-for="n in 7" :key="n">
                <dashboard-day :day="renderDate(n)" />
              </div>
              <div class="t-center pad-16" v-if="!SlotsAtCurrentWeek">
                <p class="color-grey pad-bottom-8">
                  Du hast keine Schichten innerhalb der nächsten 7 Tage
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {notificationStore} from "@/store/notificationStore";
import dashboardDay from "@/components/dashboard/dashboardDay";

export default {
  name: "Dashboard",
  title: "Dashboard",
  components: {
    dashboardDay
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const notificaions = notificationStore()

    return {auth, config, notificaions, user}
  },
  data(){
    return {
      birthdays:[],
      currentSlots: [],
      openSlotChanges:[],
      SlotsAtCurrentWeek: false,
      overtime:0,
      overTimeHours :0,
      overTimeMinutes : 0
    }
  },
  mounted() {
    this.config.loading = false
    this.notificaions.pushToken()
    this.getBirthdays()
    this.getMyCurrentSlot()
    this.getMyOpenSlotChanges(2)
  },
  methods: {
    getBirthdays(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getBirthdays', fD)
          .then((response) => {
            _this.birthdays = response.data
          })
    },
    renderBirtday(bday){
      const bd = new Date(bday)
      const thisYear =new Date(Date.now()).getFullYear()
      const age = (thisYear - bd.getFullYear()) + ' Jahre | '+(('0'+bd.getDate()).slice(-2))+'.'+("0" + (bd.getMonth() + 1)).slice(-2)+'.'+thisYear
      return age
    },

    renderDate(n){
      this.SlotsAtCurrentWeek = true;
      const Datum = new Date(Date.now())
      return Datum.setDate(Datum.getDate()+(n-1))
    },
    getMyCurrentSlot(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getMyCurrentSlot', fD)
          .then((response) => {
            this.currentSlots = response.data
          })
    },
    getOverTimeById(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getOverTimeById', fD)
          .then((response) => {
            if(response.status === 250){
              console.log(response.data)
              this.overtime = response.data
              if (response.data.overTime === 0 || response.data.overTime === null){
                this.overTimeHours = 0
                this.overTimeMinutes = 0
                return
              }
              this.overTimeHours = response.data.overTime.slice(0,-3)
              const splitMin = response.data.overTime.split('.')
              this.overTimeMinutes = ((splitMin[1]/100)*60)
              console.log(this.overTimeMinutes)
            }
          })
    },
    renderSlotProgress(start, ende){
      const unixStart = Math.floor(new Date(start).getTime() / 1000)
      const unixEnde = Math.floor(new Date(ende).getTime() / 1000)
      const now = Math.floor(Date.now() / 1000)
      const width = Math.round(((100 /(unixEnde -unixStart)) * (now - unixStart))*100)/100
      if (width >= 100){return 'width:100%'}
      if (width<= 0){return 'width:0%'}
      setTimeout(()=>{this.renderSlotProgress();console.log("TimeRendering")}, 60000)
      return 'width:'+width+'%'
    },
    getMyOpenSlotChanges(limit){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', limit)
      this.axios.post('dashboard/getOpenSlotsChanges', fD)
          .then((response) => {
            this.openSlotChanges = response.data
          })
    },
    renderTimestampForJS(date){
        const jsTimestamp = date.split(' ')
        const jsDate = jsTimestamp[0].split('-')
        const jsTime = jsTimestamp[1].split(':')
        return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1] + ':' + jsTime[2]
    },
    acceptSlotChange(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('mylayers/successSlotsChanges', fD)
          .then((response) => {
            this.getMyOpenSlotChanges(2)
          })
    },
    denySlotChange(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('mylayers/denySlotsChanges', fD)
          .then((response) => {
            this.getMyOpenSlotChanges(2)
          })
    }
  }
}
</script>

<style scoped>
  main#dashBoard{
    padding-bottom: 80px;
  }
  #dashBoard h1{
    font-size: 2em;
  }
  #dashBoard h3{
    font-size: 1.2em;
  }

  #dashBoard{

  }
  .contentBox{
    min-height: 150px;
    max-width: 600px;
    width: 90vw;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 20px;
  }
</style>