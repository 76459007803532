<template>
  <footer>
    <ul>
      <li>
        <router-link to="/dashboard">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z" fill="#0173fe" class="fill-000000" /></svg>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/mylayers">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" fill="#0173fe" class="fill-000000" /></svg>
          <span>Schichtplan</span>
        </router-link>
      </li>
      <li>
        <router-link to="/timetracking">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" fill="#0173fe" class="fill-000000" /></svg>
          <span>Meine Zeiten</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profil">
          <img style="filter: none;" :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image circular">
          <span>Profil</span>
        </router-link>
      </li>
    </ul>
  </footer>
  <div id="TimeTracker">
    <button class="button primary" @click="timeTracking()">
      <svg v-if="running == false" viewBox="0 0 100.4 100.4" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 100.4 100.4"><path d="M56.6 16.5v-4.4h4.1c.8 0 1.5-.7 1.5-1.5V3.4c0-.8-.7-1.5-1.5-1.5H43.2c-.8 0-1.5.7-1.5 1.5v7.2c0 .8.7 1.5 1.5 1.5h4.1v4.4c-9.7 1.1-18.3 5.6-24.8 12.2L20.8 27l2.8-2.8c.6-.6.6-1.5 0-2.1l-5.8-5.8c-.6-.6-1.5-.6-2.1 0L7.8 24c-.6.6-.6 1.5 0 2.1l5.8 5.8c.3.3.7.4 1.1.4s.8-.1 1.1-.4l2.9-2.9 1.9 1.9C14.6 38 11 47.2 11 57.2c0 22.6 18.4 41 41 41s41-18.4 41-41c-.1-21-16-38.4-36.4-40.7zM14.7 28.8 11 25l5.6-5.6 3.7 3.7-2.2 2.2-1.1 1.1-2.3 2.4zM48.8 9.1h-4.1V4.9h14.5v4.2h-4.1c-.8 0-1.5.7-1.5 1.5v5.7h-3.2v-5.7c-.1-.9-.8-1.5-1.6-1.5zm3.1 86.1c-20.9 0-38-17-38-38s17-38 38-38c.8 0 1.6 0 2.4.1.4 0 .8.1 1.2.1 19.3 1.8 34.4 18.1 34.4 37.8 0 21-17 38-38 38z" fill="#ffffff" class="fill-000000" /><path d="m73.5 32.6-.3-.3c-5.7-4.9-13.1-7.8-21.2-7.8-18.1 0-32.7 14.7-32.7 32.7S33.9 90 51.9 90s32.7-14.7 32.7-32.7c.1-9.9-4.3-18.7-11.1-24.7zM51.9 87c-16.4 0-29.7-13.3-29.7-29.7s13.3-29.7 29.7-29.7c6.9 0 13.2 2.4 18.2 6.3L53.9 52.5c-.6-.2-1.3-.4-1.9-.4-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5-2.3 5-5.1c0-1-.3-2-.8-2.8l16.3-18.7c5.7 5.4 9.2 13 9.2 21.5C81.7 73.6 68.3 87 51.9 87zM54 57.2c0 1.2-.9 2.1-2.1 2.1s-2.1-.9-2.1-2.1c0-1.1.9-2.1 2.1-2.1s2.1 1 2.1 2.1z" fill="#ffffff" class="fill-000000" /></svg>
      <span class="pad-8" v-if="running == true">
        <svg style="width: 28px; vertical-align: -14px;" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M18 32h4V16h-4v16zm6-28C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm2-8h4V16h-4v16z" fill="#ffffff" class="fill-000000" /></svg>
        {{ time }}
      </span>
    </button>
  </div>
  <transition name="modal" id="TimeTrackerModal">
    <div class="vueModal" v-if="TimeTrackerModal">
      <div class="vueModalOuter">
        <div class="vueModalInner bgcolor-primary color-white">
          <div class="t-center">
            <svg width="64px" viewBox="0 0 100.4 100.4" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 100.4 100.4"><path d="M56.6 16.5v-4.4h4.1c.8 0 1.5-.7 1.5-1.5V3.4c0-.8-.7-1.5-1.5-1.5H43.2c-.8 0-1.5.7-1.5 1.5v7.2c0 .8.7 1.5 1.5 1.5h4.1v4.4c-9.7 1.1-18.3 5.6-24.8 12.2L20.8 27l2.8-2.8c.6-.6.6-1.5 0-2.1l-5.8-5.8c-.6-.6-1.5-.6-2.1 0L7.8 24c-.6.6-.6 1.5 0 2.1l5.8 5.8c.3.3.7.4 1.1.4s.8-.1 1.1-.4l2.9-2.9 1.9 1.9C14.6 38 11 47.2 11 57.2c0 22.6 18.4 41 41 41s41-18.4 41-41c-.1-21-16-38.4-36.4-40.7zM14.7 28.8 11 25l5.6-5.6 3.7 3.7-2.2 2.2-1.1 1.1-2.3 2.4zM48.8 9.1h-4.1V4.9h14.5v4.2h-4.1c-.8 0-1.5.7-1.5 1.5v5.7h-3.2v-5.7c-.1-.9-.8-1.5-1.6-1.5zm3.1 86.1c-20.9 0-38-17-38-38s17-38 38-38c.8 0 1.6 0 2.4.1.4 0 .8.1 1.2.1 19.3 1.8 34.4 18.1 34.4 37.8 0 21-17 38-38 38z" fill="#ffffff" class="fill-000000" /><path d="m73.5 32.6-.3-.3c-5.7-4.9-13.1-7.8-21.2-7.8-18.1 0-32.7 14.7-32.7 32.7S33.9 90 51.9 90s32.7-14.7 32.7-32.7c.1-9.9-4.3-18.7-11.1-24.7zM51.9 87c-16.4 0-29.7-13.3-29.7-29.7s13.3-29.7 29.7-29.7c6.9 0 13.2 2.4 18.2 6.3L53.9 52.5c-.6-.2-1.3-.4-1.9-.4-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5-2.3 5-5.1c0-1-.3-2-.8-2.8l16.3-18.7c5.7 5.4 9.2 13 9.2 21.5C81.7 73.6 68.3 87 51.9 87zM54 57.2c0 1.2-.9 2.1-2.1 2.1s-2.1-.9-2.1-2.1c0-1.1.9-2.1 2.1-2.1s2.1 1 2.1 2.1z" fill="#ffffff" class="fill-000000" /></svg>
            <h4 class="pad-4 t-center f-bold">
              Zeit pausiert
            </h4>
            <h5 class="f-light">
              {{ time }}
            </h5>
          </div>
          <div class="t-center">
            <div class="pad-16" />
            <div class="pad-4">
              <button class="button white fluid large" @click="addBreak()">
                Pause beenden
              </button>
            </div>
            <div class="pad-4" />
            <div class="pad-4">
              <button class="button white fluid large" @click="reset(); WorkEndModal = true">
                Schicht beenden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="WorkEndModal">
    <div class="vueModal" v-if="WorkEndModal">
      <div class="vueModalOuter" @click="WorkEndModal = false;">
        <div class="vueModalInner">
          <div class="t-center">
            <img src="https://media3.giphy.com/media/1wX5TJZPqVw3HhyDYn/giphy.gif?cid=6c09b95261fb8f1da9a42252ef54d342825d70430202c2a3&rid=giphy.gif&ct=s" class="width-150">
            <h4 class="pad-4 t-center f-bold">
              Danke für deine Arbeit!
            </h4>
            <h5 class="f-light">
              Wir wünschen dir einen schönen Feierabend!
            </h5>
          </div>
          <div class="t-center">
            <div class="pad-16" />
            <div class="pad-4">
              <button class="button primary fluid large" @click="auth.logout(); WorkEndModal = false;">
                Ausloggen
              </button>
            </div>
            <div class="pad-4" />
            <div class="pad-4">
              <button class="button grey fluid large" @click="WorkEndModal = false;">
                Eingeloggt bleiben
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Footer",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      TimeTrackerModal: false,
      WorkEndModal: false,
      time: '00:00:00',
      timeTrackingId: null,
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false
    }
  },
  computed: {},
  mounted() {
    this.getCurrentTracking()
  },
  methods:{
    getCurrentTracking(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getCurrentTracking', fD)
          .then((response) => {

            if(response.data != 0){

              _this.timeTrackingId = response.data.id
              if(response.data.running == 0){
                _this.running = false
                _this.TimeTrackerModal = true

                _this.timeBegan = new Date(response.data.trackStart)
                _this.timeStopped = new Date(response.data.tempTrackStop)

                var timeElapsed = new Date(_this.timeStopped - _this.timeBegan), hour = timeElapsed.getUTCHours(), min = timeElapsed.getUTCMinutes(), sec = timeElapsed.getUTCSeconds(), ms = timeElapsed.getUTCMilliseconds()

                _this.time = _this.zeroPrefix(hour, 2) + ":" + _this.zeroPrefix(min, 2) + ":" + _this.zeroPrefix(sec, 2)

              }else{
                _this.timeBegan = new Date(response.data.trackStart)
                _this.start()
              }


            }


          })
    },
    timeTracking(){
      const _this = this
      const fD = new FormData()
      if(_this.running == false){
        _this.TimeTrackerModal = false
        _this.start()
        if(_this.timeTrackingId == null){
          fD.append('sessionID', this.auth.sessionID)
          this.axios.post('timetracking/startTracking', fD)
              .then((response) => {
                if(response.status === 250){
                  _this.timeTrackingId = response.data
                }
              })
        }
      }else{
        _this.TimeTrackerModal = true
        _this.stop()
      }
    },
    addBreak(){
      var dateStr = this.timeStopped.getFullYear() + "-" + ("00" + (this.timeStopped.getMonth() + 1)).slice(-2) + "-" + ("00" + this.timeStopped.getDate()).slice(-2) + " " + ("00" + this.timeStopped.getHours()).slice(-2) + ":" + ("00" + this.timeStopped.getMinutes()).slice(-2) + ":" + ("00" + this.timeStopped.getSeconds()).slice(-2);
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('breakStart', dateStr)
      fD.append('id', this.timeTrackingId)
      this.axios.post('timetracking/addBreak', fD)
          .then((response) => {
            if(response.status === 250){
              _this.timeTracking()
            }
          })
    },
    start() {
      const _this = this
      if(_this.running) return
      if (_this.timeBegan === null) {
        _this.reset();
        _this.timeBegan = new Date()
      }
      if (_this.timeStopped !== null) {
        _this.stoppedDuration += (new Date() - _this.timeStopped)
      }
      _this.started = setInterval(_this.clockRunning, 10)
      _this.running = true
    },
    stop() {
      const _this = this
      const fD = new FormData()
      _this.running = false
      _this.timeStopped = new Date()
      clearInterval(_this.started)
      fD.append('id', this.timeTrackingId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/setTempStop', fD)
          .then((response) => {})
    },
    reset() {
        const _this = this
        const fD = new FormData()
        if(this.timeTrackingId != null) {
          fD.append('sessionID', this.auth.sessionID)
          fD.append('time', this.time)
          fD.append('id', this.timeTrackingId)
          this.axios.post('timetracking/stopTracking', fD)
              .then((response) => {
                if (response.status === 250) {
                  _this.running = false
                  clearInterval(_this.started)
                  _this.stoppedDuration = 0
                  _this.timeBegan = null
                  _this.timeStopped = null
                  _this.time = "00:00:00"
                  _this.timeTrackingId = null
                  _this.TimeTrackerModal = false
                }
              })
        }else{
          _this.running = false
          clearInterval(_this.started)
          _this.stoppedDuration = 0
          _this.timeBegan = null
          _this.timeStopped = null
          _this.time = "00:00:00"
          _this.timeTrackingId = null
          _this.TimeTrackerModal = false
        }
    },
    clockRunning(){
      const _this = this
        var currentTime = new Date(),
        timeElapsed = new Date(currentTime - _this.timeBegan - _this.stoppedDuration),
        hour = timeElapsed.getUTCHours(),
        min = timeElapsed.getUTCMinutes(),
        sec = timeElapsed.getUTCSeconds(),
        ms = timeElapsed.getUTCMilliseconds()

      _this.time = _this.zeroPrefix(hour, 2) + ":" + _this.zeroPrefix(min, 2) + ":" + _this.zeroPrefix(sec, 2)
    },
    zeroPrefix(num, digit) {
        var zero = ''
        for(var i = 0; i < digit; i++) {
          zero += '0'
        }
        return (zero + num).slice(-digit)
    },
    getTracking(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('timetracking/getTracking', fD)
          .then((response) => {
            if(response.status === 250){
              _this.timeTrackingStart = response.data.start
              _this.timeTrackingId = response.data.id
            }
          })
    },
  }

}
</script>

<style scoped>
#TimeTracker{
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 101;
}
#TimeTracker button{
  border-radius: 90px;
  padding: 8px 10px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .2);
}
#TimeTracker svg{
  width: 40px;
  height: 40px;
}
footer{
  display: none;
}
@media (max-width: 768px) {
  #TimeTracker{
    bottom: 72px;
  }
}
@media (max-width: 768px) {
  footer {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    min-height: 48px;
    padding: 8px 0 6px 0;
    background-color: #FFFFFF;
  }

  footer ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  footer ul li {
    width: 25%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    text-align: center;
  }

  footer ul li a {
    display: block;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    text-decoration: none;
  }

  footer ul li a img,
  footer ul li a svg{
    width: 24px;
    display: block;
    margin: 0 auto;
  }

  footer ul li a span {
    width: 24px;
    display: inline;
    font-size: 0.8em;
    margin: 0 auto;
  }

  footer ul li.middle {
    position: relative;
    margin-top: -40px;
  }

  footer ul li.middle img,
  footer ul li.middle svg{
    background-color: var(--theme-diagnostika-primary)!important;
    border-radius: 90%;
    width: 32px;
    height: 32px;
    margin-top: 0px;
    padding: 16px;
  }
}
</style>