<template>
  <div>
    <main id="editUser">
      <div class="content-1400">
        <div class="pad-16">
          <div class="grid-12">
            <div class="col-sp-12 col-tb-6 row-sp-12">
              <h2 style="font-size: 1.2em;" class="color-primary">
                Benutzer bearbeiten
              </h2>
              <h1>Benutzerverwaltung</h1>
            </div>
            <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
              <button v-if="inviteSend == false" class="button primary" @click="invite()">Einladungsemail versenden</button>
              <button v-else class="button green">Erfolgreich versendet</button>
            </div>
          </div>
          <div class="pad-4" />
          <form method="post" @submit.prevent="createUser()">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-4 row-sp-12">
                <div class="pad-4">
                  <div class="bgcolor-white pad-24 br-10 eventDataForm">
                    <div id="uploadBox">
                      <img :src="config.projectURL + 'assets/images/users/' + (avatarImage ?? 'default.svg')" class="image width-150 circular bgcolor-lightblue">
                      <div class="pad-16">
                        <button type="button" class="button primary" @click="avatarModal = true">
                          Bild hochladen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-sd-8 row-sp-12">
                <div class="pad-4">
                  <div class="bgcolor-white pad-16 br-10 eventDataForm">
                    <div class="grid-12">
                      <div class="col-sp-8 row-sp-12">
                        <h3 style="padding-left: 4px;">
                          Angaben zur Person
                        </h3>
                      </div>
                      <div class="col-sp-4 row-sp-12 t-right">
                        <button type="button" class="button primary" @click="personEdit = true;" v-if="personEdit == false">
                          Bearbeiten
                        </button>
                      </div>
                    </div>
                    <div class="pad-8" />
                    <div v-if="personEdit" class="grid-12">
                      <div class="col-sp-12 col-sd-2 row-sp-12 pad-4">
                        <div class="field">
                          <label>Title</label>
                          <select v-model="title" name="title">
                            <option tabindex="-1" selected disabled>
                              Titel auswählen
                            </option>
                            <option value="Dr.">
                              Dr.
                            </option>
                            <option value="Dr. med.">
                              Dr. med.
                            </option>
                            <option value="Prof.">
                              Prof.
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
                        <div class="field">
                          <label>Vorname*</label>
                          <input type="text" placeholder="Vorname" name="firstname" v-model="firstname" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Nachname*</label>
                          <input type="text" placeholder="Nachname" name="lastname" v-model="lastname" required>
                        </div>
                      </div>
                      <div class="col-sp-10 col-sd-5 row-sp-12 pad-4">
                        <div class="field">
                          <label>Geburtsdatum*</label>
                          <input type="date" name="birthday" v-model="birthday" required>
                        </div>
                      </div>
                      <div class="col-sp-2 col-sd-1 row-sp-12">
                        <div class="pad-16" />
                        <button class="button lightblue" type="button" style="padding: 12px;" title="Information" @click="ageInfoModal = true;">
                          <svg style="vertical-align: middle;" width="24px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" fill="#1495e6" class="fill-000000" /></svg>
                        </button>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Geschlecht*</label>
                          <select v-model="gender" name="gender" required>
                            <option tabindex="-1" selected disabled>
                              Geschlecht auswählen
                            </option>
                            <option value="male">
                              Männlich
                            </option>
                            <option value="female">
                              Weiblich
                            </option>
                            <option value="divers">
                              Divers
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>E-Mail-Adresse*</label>
                          <input type="email" placeholder="E-Mail-Adresse" name="email" v-model="email" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Telefonnummer</label>
                          <input type="tel" placeholder="Telefonnummer" name="phone" v-model="phone">
                        </div>
                      </div>
                      <div class="col-sp-12 row-sp-12 t-right pad-top-16">
                        <button type="button" class="button mar-right-16" @click="getUserDetails(); personEdit = false">
                          Abbrechen
                        </button>
                        <button type="button" class="button primary" @click="saveUser()">
                          Speichern
                        </button>
                      </div>
                    </div>
                    <div v-else class="grid-12">
                      <div class="col-sp-12 col-sd-2 row-sp-12 pad-4">
                        <div class="field">
                          <label>Title</label>
                          <input type="text" readonly placeholder="Title" name="title" v-model="title" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
                        <div class="field">
                          <label>Vorname*</label>
                          <input type="text" readonly placeholder="Vorname" name="firstname" v-model="firstname" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Nachname*</label>
                          <input type="text" readonly placeholder="Nachname" name="lastname" v-model="lastname" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Geburtsdatum*</label>
                          <input type="date" readonly name="birthday" v-model="birthday" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Geschlecht*</label>
                          <input type="text" readonly placeholder="Geschlecht" name="gender" value="Männlich" v-if="gender == 'male'" required>
                          <input type="text" readonly placeholder="Geschlecht" name="gender" value="Weiblich" v-else-if="gender == 'female'" required>
                          <input type="text" readonly placeholder="Geschlecht" name="gender" value="Divers" v-else required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>E-Mail-Adresse*</label>
                          <input type="email" readonly placeholder="E-Mail-Adresse" name="email" v-model="email" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Telefonnummer</label>
                          <input type="tel" readonly placeholder="Telefonnummer" name="phone" v-model="phone">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <div class="pad-8" />
                <div class="pad-4">
                  <div class="bgcolor-white pad-16 br-10 eventDataForm">
                    <div class="grid-12">
                      <div class="col-sp-8 row-sp-12">
                        <h3 style="padding-left: 4px;">
                          Angaben zur Beschäftigung
                        </h3>
                      </div>
                      <div class="col-sp-4 row-sp-12 t-right">
                        <button type="button" class="button primary" @click="workEdit = true;" v-if="workEdit == false">
                          Bearbeiten
                        </button>
                      </div>
                    </div>
                    <div class="pad-8" />
                    <div v-if="selectedworktype == 4">
                      <div class="bgcolor-lightblue color-blue br-10 pad-16">
                        <svg style="vertical-align: -6px;" width="24px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" fill="#1495e6" class="fill-000000" /></svg>
                        Bitte beachten Sie, dass Werkstudent*innen maximal 20 Stunden pro Woche arbeiten dürfen.
                        In den Semesterferien liegt die Grenze bei 40 Stunden pro Woche.
                      </div>
                      <div class="pad-4" />
                    </div>
                    <div v-if="workEdit" class="grid-12">
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Art der Beschäftigung*</label>
                          <select v-model="selectedworktype" name="selectedworktype" required>
                            <option tabindex="-1" selected disabled>
                              Bitte auswählen
                            </option>
                            <option v-for="worktype in worktypes" :key="worktype.id" :value="worktype.id">
                              {{ worktype.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Wöchentliche Arbeitszeit*</label>
                          <input type="number" v-model="weeklytime" placeholder="Wöchentliche Arbeitszeit" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Beschäftigungsbeginn*</label>
                          <input type="date" v-model="jobstart" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Beschäftigungsende</label>
                          <input type="date" v-model="jobend">
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Gehalt / Lohn pro Stunde (in €)</label>
                          <input type="number" min="0" v-model="pay" placeholder="14,00€">
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Urlaubstage (im Jahr)</label>
                          <input type="number" min="0" v-model="vacationdays" placeholder="Urlaubstage">
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Berufsgruppe*</label>
                          <select v-model="selectedworkgroup" name="selectedworkgroup" required @change="getWorkareas()">
                            <option tabindex="-1" selected disabled>
                              Bitte auswählen
                            </option>
                            <option v-for="workgroup in workgroups" :key="workgroup.id" :value="workgroup.id">
                              {{ workgroup.title }}
                            </option>
                          </select>
                          <div class="t-right mar-top-4">
                            <router-link to="/praxisverwaltung" class="color-blue">
                              Berufsgruppen bearbeiten
                            </router-link>
                          </div>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Tätigkeitsbereiche (Mehrfachauswahl möglich)*</label>
                          <select v-model="selectedworkareas" name="selectedworkareas" multiple required v-if="selectedworkgroup">
                            <option v-for="workarea in workareas" :key="workarea.id" :value="workarea.id">
                              {{ workarea.title }}
                            </option>
                          </select>
                          <p v-else class="t-center pad-24">
                            <span class="label">Bitte wähle zuerst eine Berufsgruppe</span>
                          </p>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Rechtegruppe*</label>
                          <select v-model="role" name="role" required>
                            <option tabindex="-1" selected disabled>
                              Bitte auswählen
                            </option>
                            <option value="2">
                              Mitarbeiter*in
                            </option>
                            <option value="3">
                              Schichtplaner*in
                            </option>
                            <option value="6">
                              Personalleiter*in
                            </option>
                            <option value="1">
                              Administrator*in
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Tätig in folgenden Praxen (Mehrfachauswahl möglich)*</label>
                          <select v-model="selectedworkplaces" multiple required>
                            <option v-for="workplace in workplaces" :key="workplace.id" :value="workplace.id">
                              {{ workplace.organizerName }}
                            </option>
                          </select>
                        </div>
                        <div class="t-right mar-top-4">
                          <router-link to="/praxisverwaltung" class="color-blue">
                            Praxen bearbeiten
                          </router-link>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Arbeitstage*</label><br>
                          Mo. <input type="checkbox" v-model="workingDays.monday">
                          Di. <input type="checkbox" v-model="workingDays.tuesday">
                          Mi. <input type="checkbox" v-model="workingDays.wednesday">
                          Do. <input type="checkbox" v-model="workingDays.thursday">
                          Fr. <input type="checkbox" v-model="workingDays.friday">
                          Sa. <input type="checkbox" v-model="workingDays.saturday">
                          So. <input type="checkbox" v-model="workingDays.sunday">
                        </div>
                      </div>
                      <div class="col-sp-12 row-sp-12 t-right pad-top-16">
                        <button type="button" class="button mar-right-16" @click="getUserDetails(); workEdit = false">
                          Abbrechen
                        </button>
                        <button type="button" class="button primary" @click="saveWork()">
                          Speichern
                        </button>
                      </div>
                    </div>
                    <div v-else class="grid-12">
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Art der Beschäftigung</label>
                          <input type="text" :value="worktypes.find(w => w.id === selectedworktype)?.title" placeholder="Art der Beschäftigung" readonly required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Wöchentliche Arbeitszeit</label>
                          <input type="number" v-model="weeklytime" placeholder="Wöchentliche Arbeitszeit" readonly required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Beschäftigungsbeginn</label>
                          <input type="date" v-model="jobstart" required readonly>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Beschäftigungsende</label>
                          <input type="date" v-model="jobend" readonly>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Gehalt / Lohn pro Stunde (in €)</label>
                          <input type="number" v-model="pay" placeholder="14,00€" readonly>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Urlaubstage (im Jahr)</label>
                          <input type="number" v-model="vacationdays" placeholder="Urlaubstage" readonly>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Berufsgruppe</label>
                          <input :value="workgroups.find(w => w.id === selectedworkgroup)?.title" name="workgroupTitle" readonly>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Tätigkeitsbereiche</label>
                          <div class="mar-top-8">
                            <span class="blue label mar-right-8" v-for="workarea in workareas.filter(w => selectedworkareas.includes(w.id))" :key="workarea.id">{{ workarea.title }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Rechtegruppe</label>
                          <select v-model="role" name="role" disabled>
                            <option tabindex="-1" selected>
                              Bitte auswählen
                            </option>
                            <option value="2">
                              Mitarbeiter*in
                            </option>
                            <option value="3">
                              Schichtplaner*in
                            </option>
                            <option value="6">
                              Personalleiter*in
                            </option>
                            <option value="1">
                              Administrator*in
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Tätig in folgenden Praxen</label>
                          <div class="mar-top-8">
                            <span class="blue label mar-right-8" v-for="workplace in workplaces.filter(w => selectedworkplaces.includes(w.id))" :key="workplace.id">{{ workplace.organizerName }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Arbeitstage*</label><br>
                          Mo. <input type="checkbox" v-model="workingDays.monday" disabled>
                          Di. <input type="checkbox" v-model="workingDays.tuesday" disabled>
                          Mi. <input type="checkbox" v-model="workingDays.wednesday" disabled>
                          Do. <input type="checkbox" v-model="workingDays.thursday" disabled>
                          Fr. <input type="checkbox" v-model="workingDays.friday" disabled>
                          Sa. <input type="checkbox" v-model="workingDays.saturday" disabled>
                          So. <input type="checkbox" v-model="workingDays.sunday" disabled>
                        </div>
                      </div>
                    </div>
                    <div class="pad-16" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <UserTimes />
    </main>
    <transition name="modal" id="ageInfoModal">
      <div class="vueModal" v-if="ageInfoModal">
        <div class="vueModalOuter" @click="ageInfoModal = false;" />
        <div class="vueModalInner tiny bottom" style="background-color: #E2F1FF!important;">
          <div class="t-center pad-8">
            <svg style="vertical-align: middle;" width="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" fill="#1495e6" class="fill-000000" /></svg>
            <div class="pad-16" />
            <p class="color-blue">
              Das Geburtsdatum wird benötigt, um ggf. Bestimmungen des Arbeitsschutzgesetzes bei der Arbeitszeitplanung berücksichtigen zu können.
            </p>
            <div class="pad-16" />
            <button @click="ageInfoModal = false;" class="button primary fluid">
              Okay
            </button>
          </div>
        </div>
      </div>
    </transition>
    <VueModal :show="avatarModal" @close="avatarModal = false" approve="Speichern"
              @approve="avatarModal = false; changeAvatar()">
      <cropper-upload :init="true" :ratio="1" @cropped="c => {avatarBlob = c}" />
    </VueModal>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";
import UserTimes from "@/components/usermanagement/userTimes";
import {aSyncData} from "@/modules/main";

export default {
  name: "UserEdit",
  title: 'Benutzer bearbeiten',
  components: {
    UserTimes,
    CropperUpload
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      personEdit: false,
      workEdit: false,
      userDetails: {},
      saveMessage: '',
      ageInfoModal: false,
      gender: '',
      title: '',
      firstname: '',
      lastname: '',
      birthday: '',
      selectedworktype: '',
      selectedworkgroup: '',
      workgroups: [],
      email: '',
      phone: null,
      weeklytime: null,
      jobstart: null,
      jobend: null,
      pay: null,
      role: null,
      vacationdays: null,
      workareas: [],
      worktypes: [],
      selectedworkareas: [],
      selectedworkplaces: [],
      workplaces: [],
      avatarModal: false,
      avatarBlob: false,
      loadingAvatar: false,
      worktypeTitle: '',
      workgroupTitle: '',
      avatarImage: null,
      workingDays: {},
      inviteSend: false
    }
  },
  mounted() {
    this.config.loading = false
    this.getUserDetails()
    this.getWorkplaces()
    this.getWorkgroups()
    this.getWorktypes()
  },
  methods: {
    getUserDetails() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getUserById', fD)
          .then((response) => {
            //console.log(response.data)
            if(response.status === 250){
              _this.avatarImage = response.data.avatar
              _this.userDetails = response.data
              _this.title = response.data.title
              _this.firstname = response.data.firstname
              _this.lastname = response.data.lastname
              _this.email = response.data.email
              _this.phone = response.data.phone
              _this.vacationdays = response.data.vacationdays
              _this.weeklytime = response.data.weeklytime
              _this.jobstart = response.data.jobstart
              _this.jobend = response.data.jobend
              _this.pay = response.data.pay
              _this.birthday = response.data.dob
              _this.gender = response.data.gender
              _this.worktypeTitle = response.data.worktypeTitle
              _this.workgroupTitle = response.data.workgroupTitle
              _this.selectedworkgroup = response.data.workgroup
              _this.selectedworktype = response.data.worktype
              _this.selectedworkareas =  response.data.selectedWorkAreas
              _this.selectedworkplaces =  response.data.selectedWorkPlaces
              _this.role = response.data.role
              _this.workingDays = JSON.parse(response.data.workingdays) ?? {}

              _this.getWorkareas()
            }
          })
    },
    saveUser(){
      aSyncData('usermanagement/editUser', {
        userId: this.$route.params.id,
        title: this.title,
        firstname: this.firstname,
        lastname: this.lastname,
        birthday: this.birthday,
        gender: this.gender,
        email: this.email,
        phone: this.phone
      })
          .then(r => {
            if(r.status === 250){
              this.saveMessage = 'Erfolgreich gespeichert'
              this.personEdit = false
            }
          })
    },
    saveWork(){
      aSyncData('usermanagement/editEmployment', {
        userId: this.$route.params.id,
        weeklytime: this.weeklytime,
        jobstart: this.jobstart,
        jobend: this.jobend,
        pay: this.pay,
        vacationdays: this.vacationdays,
        selectedworktype: this.selectedworktype,
        selectedworkgroup: this.selectedworkgroup,
        selectedworkareas: this.selectedworkareas,
        selectedworkplaces: this.selectedworkplaces,
        role: this.role,
        workingDays: this.workingDays
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) this.workEdit = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteUser(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.$route.params.id)
      this.axios.post('usermanagement/deleteUser', fD)
          .then((response) => {
            console.log(response.status, response.data)
            if(response.status === 250){
              _this.$router.push('/usermanagement')
            }
          })
    },
    getWorkplaces(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkplaces', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workplaces = r.data
              console.log(r.data)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkgroups(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkgroups', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workgroups = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorktypes(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorktypes', fD)
          .then(r => {
            if(r.status === 250) {
              _this.worktypes = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkareas(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroupid', this.selectedworkgroup)
      this.axios.post('usermanagement/getWorkareas', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workareas = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    changeAvatar(){
      console.log(this.avatarBlob)
      aSyncData('Usermanagement/changeAvatarOther', {userId: this.$route.params.id, avatar: this.avatarBlob}, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.avatarImage = r.data.avatar
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    invite(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.$route.params.id)
      fD.append('organizerId', this.config.organizerId)
      fD.append('roleId', this.role)
      fD.append('email', this.email)
      this.axios.post('usermanagement/invite', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.addUser = false
              _this.inviteSend = true
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>
#editUser h1{
  font-size: 2em;
}
#editUser h3{
  font-size: 1.6em;
}

#uploadBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 32px;
}
.eventDataForm .field input,
.eventDataForm .field textarea,
.eventDataForm .field select{
  display: block;
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  box-sizing: border-box;
}
.eventDataForm .field input[type=checkbox]{
  width: auto;
  display: inline;
  margin-right: 2px;
}
.eventDataForm .field label{
  margin-top: 8px;
  display: inline-block;
  color: rgba(0,0,0,0.6);
}
input:read-only,
select:disabled{
  border: none!important;
  outline: none!important;
  padding-left: 0!important;
  background-color: transparent;
  color: #000000;
}
</style>