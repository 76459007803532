<template>
  <div class="content-1400" v-if="openSlotChanges.length || denySlotChanges.length ||openSlotChanges2.length">
    <div class="bgcolor-white pad-16 br-10">
      <h3>Offene Schichttausch anfragen</h3>
      <div class="grid-12" style="column-gap: 20px">

        <div v-for="openSlot in openSlotChanges" :key="openSlot.id" class="col-sp-6 row-sp-12 bgcolor-lightblue pad-12 br-10">
          <div class="grid-12" >
            <div class="col-sp-6 row-sp-12">
              <p>Schichtanfrage</p>
              <h3 class="color-primary" style="font-size: 1.2em">
                {{ openSlot.slot2_data.title }} {{ openSlot.slot2_data.firstname }} {{ openSlot.slot2_data.lastname }}
              </h3>
              <p>{{ openSlot.slot2_data.workareasTitle }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot2_data.slotStart).slice(0,-8) }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot2_data.slotStart).slice(10,16) }} - {{ renderTimestampForJS(openSlot.slot2_data.slotEnd).slice(10,16) }}</p>
              <p v-if="openSlot.reason">Begründung: {{ openSlot.reason }}</p>
            </div>
            <div class="col-sp-6 row-sp-12" style=" text-align: right;">
              <p>Aktuelle Schicht</p>
              <h3 class="color-primary" style="font-size: 1.2em">
                {{ openSlot.slot1_data.title }} {{ openSlot.slot1_data.firstname }} {{ openSlot.slot1_data.lastname }}
              </h3>
              <p>{{ openSlot.slot1_data.workareasTitle }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot1_data.slotStart).slice(0,-8) }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot1_data.slotStart).slice(10,16) }} - {{ renderTimestampForJS(openSlot.slot1_data.slotEnd).slice(10,16) }}</p>
            </div>
            <button style="grid-column-start: 5" class="col-sp-4 button primary" @click="()=>{safetyMod=true, this.deleteId = openSlot.id}">Anfrage löschen</button>
          </div>
        </div>
        <div v-for="denySlot in denySlotChanges" :key="denySlot.id" class="col-sp-6 row-sp-12 bgcolor-lightred pad-12 br-10">
          <div class="grid-12" >
            <div class="col-sp-6 row-sp-12">
              <p>Schichtanfrage</p>
              <h3 class="color-red" style="font-size: 1.2em">
                {{ denySlot.slot2_data.title }} {{ denySlot.slot2_data.firstname }} {{ denySlot.slot2_data.lastname }}
              </h3>
              <p>{{ denySlot.slot2_data.workareasTitle }}</p>
              <p>{{ renderTimestampForJS(denySlot.slot2_data.slotStart).slice(0,-8) }}</p>
              <p>{{ renderTimestampForJS(denySlot.slot2_data.slotStart).slice(10,16) }} - {{ renderTimestampForJS(denySlot.slot2_data.slotEnd).slice(10,16) }}</p>
            </div>
            <div class="col-sp-6 row-sp-12" style=" text-align: right;">
              <p>Aktuelle Schicht</p>
              <h3 class="color-red" style="font-size: 1.2em">
                {{ denySlot.slot1_data.title }} {{ denySlot.slot1_data.firstname }} {{ denySlot.slot1_data.lastname }}
              </h3>
              <p>{{ denySlot.slot1_data.workareasTitle }}</p>
              <p>{{ renderTimestampForJS(denySlot.slot1_data.slotStart).slice(0,-8) }}</p>
              <p>{{ renderTimestampForJS(denySlot.slot1_data.slotStart).slice(10,16) }} - {{ renderTimestampForJS(denySlot.slot1_data.slotEnd).slice(10,16) }}</p>
            </div>
            <button style="grid-column-start: 5" class="col-sp-4 button red" @click="deleteSlotChange(denySlot.id)">Anfrage löschen</button>
          </div>
        </div>
        <div v-for="openSlot in openSlotChanges2" :key="openSlot.id" class="col-sp-6 row-sp-12 pad-12 br-10" style="background-color: rgb(250,250,250)">
          <div class="grid-12" style="column-gap: 20px" >
            <div class="col-sp-6 row-sp-12">
              <p>Schichtanfrage</p>
              <h3 class="color-primary" style="font-size: 1.2em">
                {{ openSlot.slot1_data.title }} {{ openSlot.slot1_data.firstname }} {{ openSlot.slot1_data.lastname }}
              </h3>
              <p>{{ openSlot.slot1_data.workareasTitle }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot1_data.slotStart).slice(0,-8) }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot1_data.slotStart).slice(10,16) }} - {{ renderTimestampForJS(openSlot.slot1_data.slotEnd).slice(10,16) }}</p>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <p>Aktuelle Schicht</p>
              <h3 class="color-primary" style="font-size: 1.2em">
                {{ openSlot.slot2_data.title }} {{ openSlot.slot2_data.firstname }} {{ openSlot.slot2_data.lastname }}
              </h3>
              <p>{{ openSlot.slot2_data.workareasTitle }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot2_data.slotStart).slice(0,-8) }}</p>
              <p>{{ renderTimestampForJS(openSlot.slot2_data.slotStart).slice(10,16) }} - {{ renderTimestampForJS(openSlot.slot2_data.slotEnd).slice(10,16) }}</p>
              <p v-if="openSlot.reason">Begründung: {{ openSlot.reason }}</p>
            </div>
            <button  class="col-sp-6 row-sp-1 button lightgreen mar-top-12" @click="acceptSlotChange(openSlot.id)">Anfrage annehmen</button>
            <button  class="col-sp-6 row-sp-1 button lightred mar-top-12" @click="denySlotChange(openSlot.id)">Anfrage löschen</button>
          </div>
        </div>
      </div>
    </div>
    <safety-modal v-if="safetyMod" @closeSaftyModal="(bool)=>{safetyMod = false; bool? deleteSlotChange(deleteId) : '' }"/>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import safetyModal from "@/components/basic/safetyModal";

export default {
  name: "ChangeLayerOverview",
  components: {
    safetyModal
  },

  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      openSlotChanges:[],
      openSlotChanges2:[],
      denySlotChanges:[],
      safetyMod:false,
      deleteId: ''
    }
  },
  mounted() {
    this.getSlotChanges(0)
    this.getSlotChanges(2)
    this.getMyOpenSlotChanges(2)
  },
  methods:{
    getSlotChanges(status){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('status', status)
      this.axios.post('mylayers/getMySlotsChanges', fD)
          .then((response) => {
            if (status === 0){
              this.openSlotChanges = response.data
            }
            if (status === 2){
              this.denySlotChanges = response.data
            }
          })
    },
    deleteSlotChange(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('mylayers/deleteSlotChanges', fD)
          .then((response) => {
              this.getSlotChanges(0)
              this.getSlotChanges(2)
          })
    },
    getMyOpenSlotChanges(limit){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', limit)
      this.axios.post('dashboard/getOpenSlotsChanges', fD)
          .then((response) => {
            this.openSlotChanges2 = response.data
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1] + ':' + jsTime[2]
    },
    acceptSlotChange(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('mylayers/successSlotsChanges', fD)
          .then((response) => {
            this.getMyOpenSlotChanges(2)
          })
    },
    denySlotChange(id){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', id)
      this.axios.post('mylayers/denySlotsChanges', fD)
          .then((response) => {
            this.getMyOpenSlotChanges(2)
          })
    }
  }
}
</script>

<style scoped>

</style>