<template>
  <div>
    <main id="praxisplaner">
      <div class="content-1400">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 t-right">
            <a class="button primary mar-right-8" :href="downloadURL" download="Schichten.ics">Woche für Kalender Downloaden</a>
            <a class="button secondary" href="webcal://diagnostika.app/calendar/calendar.ics" download="Schichten.ics" style="cursor: not-allowed">Kalender Abonnieren</a>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-3 pad-8">
            <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-primary">
              Übersicht
            </h2>
            <h1 style="padding-left: 4px;">
              Meine Schichten
            </h1>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
            <praxis-selection @getMembers="()=>{getMembers();getNow()}"/>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-9 pad-8">
            <section>
              <div class="pad-8" style="display: flex;flex-flow: wrap;gap: 4px;">
                <div v-for="member in members"
                     :key="member.id" class="singleMember" @click="()=>{toggleActiveMembers(member.id)}">
                  <a style="cursor: pointer;" :title="member.title+' '+member.firstname+' '+member.lastname">
                    <img :src="config.projectURL + 'assets/images/users/'+ member.userImg"
                         class="image width-40 circular bgcolor-lightblue"
                         :class="{active : activeMembers.includes(member.id)}"
                    >
                  </a>
                </div>
                <div v-for="workG in workgroups" :key="workG">
                  <button @click="()=>toggleActiveGroups(workG)" class="button tiny">{{workG}}</button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="pad-8" />
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="grid-12">
              <div class="col-sp-3 row-sp-12">
                <button class="button tiny white" @click="renderWeek('left')">
                  <svg height="24px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " /></svg>
                </button>
              </div>
              <div class="col-sp-6 row-sp-12 t-center" id="PlanerMenu">
                <div class="field">
                  <select v-model="year" @change="getAllWeeks(); ForceUpdateComponent()">
                    <option tabindex="-1" selected disabled>
                      Bitte auswählen
                    </option>
                    <option value="2023">
                      2023
                    </option>
                    <option value="2024">
                      2024
                    </option>
                    <option value="2025">
                      2025
                    </option>
                    <option value="2026">
                      2026
                    </option>
                    <option value="2027">
                      2027
                    </option>
                    <option value="2028">
                      2028
                    </option>
                    <option value="2029">
                      2029
                    </option>
                    <option value="2030">
                      2030
                    </option>
                  </select>
                </div>
                <div class="field">
                  <select v-model="currentKW" @change="loadPlan()">
                    <option tabindex="-1" selected disabled>
                      Bitte auswählen
                    </option>
                    <option v-for="kw in allWeeksdata" :key="kw.KW" :value="kw.KW">
                      KW {{ kw.KW }} | {{ kw.Monday }} - {{ kw.Sunday }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sp-3 row-sp-12 t-right">
                <button class="button tiny white" @click="renderWeek('right')">
                  <svg height="24px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " /></svg>
                </button>
              </div>
            </div>
            <change-layer-overview v-if="reRenderSlotChanges" />
            <div v-if="renderComponent">
              <div v-if="weekend">
                <layerDay v-for="weekDates in weekDates"
                          :week-dates="weekDates"
                          :activeUsers="activeMembers"
                          :key="weekDates.id"
                          @loadSlotChanges="reRenderSlotChanges= false;$nextTick(()=>{this.reRenderSlotChanges=true})"
                />
                <div style="width: 100%;display: flex;align-items: center">
                  <button style="margin: 12px auto" class="primary button" @click="weekend= false">Wochenende Ausblenden</button>
                </div>
              </div>
              <div v-else>
                <layerDay v-for="weekDates in weekDates.slice(0, -2)"
                          :week-dates="weekDates"
                          :activeUsers="activeMembers"
                          :key="weekDates.id"
                          @loadSlotChanges="reRenderSlotChanges= false;$nextTick(()=>{this.reRenderSlotChanges=true})"
                />
                <div style="width: 100%;display: flex;align-items: center">
                  <button style="margin: 12px auto" class="primary button" @click="weekend= true">Wochenende anzeigen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pad-40" />
    </main>

  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { createEvents } from 'ics'
import layerDay from "@/components/mylayers/layerDay";
import ChangeLayerOverview from "@/components/mylayers/changeLayerOverview";
import PraxisSelection from "@/components/praxis/praxisSelection";
import moment from "moment";



export default {
  name: "MyLayers",
  components: {
    PraxisSelection,
    ChangeLayerOverview,
    layerDay: layerDay,

  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      slots:[],
      date: Date.now(),
      icsEvents:[],
      downloadURL:'',
      downloadName:'',
      allWeeksdata: [],
      currentKW: '10',
      year: 2023,
      weekDates: [],
      renderComponent: true,
      weekend:true,
      saturday:true,
      members: '',
      activeMembers:[],
      workgroups:[],
      reRenderSlotChanges:true,

    }
  },
  mounted() {
    this.getNow()
    this.getMembers()
  },
  methods:{
    autoSet(){
      const _this = this
      const fD = new FormData()
      fD.append('monday', this.allWeeksdata[this.currentKW - 1]['Monday'])
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/autoSet', fD)
          .then((response) => {
            if(response.status === 250){
              _this.loadPlan()
            }
          })
    },
    getMembers(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getMembers', fD)
          .then((response) => {
            if(response.status === 250){
              _this.members = response.data
              response.data.map(singleMember=>{
                if (singleMember.id === this.user.id){this.activeMembers.push(singleMember.id)}

                if (!this.workgroups.includes(singleMember.workgroupTitle)){
                  this.workgroups.push(singleMember.workgroupTitle)
                }
              })
            }
          })
    },
    //Member Leiste Oben
    toggleActiveMembers(id){
      const found = this.activeMembers.indexOf(id)
      if(found >= 0){
        this.activeMembers.splice(found,1)
      }
      else {
        this.activeMembers.push(id)
      }
      this.forceRerender()
    },
    toggleActiveGroups(name){
      if (event.target.classList.contains('activeGroup')){
        event.target.classList.remove('activeGroup')
        this.members.map(sMember =>{
          if (sMember.workgroupTitle === name){
            const found = this.activeMembers.indexOf(sMember.id)
            if(found >= 0){
              this.activeMembers.splice(found,1)
            }
          }
        })
      }
      else {
        event.target.classList.add('activeGroup')
        this.members.map(sMember =>{
          if (sMember.workgroupTitle === name) {
            this.activeMembers.push(sMember.id)
          }
        })
      }
      this.forceRerender()
    },
    getNow(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/getNow', fD)
          .then((response) => {
            _this.currentKW = response.data.KW
            _this.year = response.data.Year
            _this.getAllWeeks()
          })
    },
    //Wochenrendering
    getAllWeeks(){
      const _this = this
      const fD = new FormData()
      fD.append('year', this.year)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/getAllWeeks', fD)
          .then((response) => {
            _this.allWeeksdata = response.data
            _this.loadPlan()
          })
    },
    loadPlan(){
      var thisweek = this.allWeeksdata[this.currentKW - 1]
      this.weekDates = [
        {id:1, name: 'Montag', date:thisweek['Monday']},
        {id:2, name: 'Dienstag', date:thisweek['Tuesday']},
        {id:3, name: 'Mittwoch', date:thisweek['Wednesday']},
        {id:4, name: 'Donnerstag', date:thisweek['Thursday']},
        {id:5, name: 'Freitag', date:thisweek['Friday']},
        {id:6, name: 'Samstag', date:thisweek['Saturday']},
        {id:7, name: 'Sonntag', date:thisweek['Sunday']}
      ]
      this.getICS(this.allWeeksdata[this.currentKW - 1])
      this.forceRerender()
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    renderWeek(direction){
      if (direction === 'left'){
        const toInt = parseInt(this.currentKW) -1
        if (toInt <= 0){
          this.currentKW = '52'
          const yearToInt = parseInt(this.year) -1
          this.year = String(yearToInt)
          this.loadPlan()
          return;
        }
        else {
          const toInt = parseInt(this.currentKW) -1
          if (toInt <= 9 && toInt > 0){
            const toPhp= "0"+ String(toInt)
            this.currentKW = toPhp
            this.loadPlan()
            return
          }
          else{
            this.currentKW = String(toInt)
            this.loadPlan()
          }
        }
      }
      else if(direction === 'right'){
        const toInt = parseInt(this.currentKW) +1
        if (toInt <= 0){
          this.currentKW = '52'
          const yearToInt = parseInt(this.year) -1
          this.year = String(yearToInt)
          this.loadPlan()
          return;
        }
        if (toInt <= 9 && toInt > 0){
          const toPhp= "0"+ String(toInt)
          this.currentKW = toPhp
          this.loadPlan()
          return
        }
        if (toInt >= 53){
          this.currentKW = '01'
          const yearToInt = parseInt(this.year) +1
          this.year = String(yearToInt)
          this.loadPlan()
          return;
        }
        else {
          const toPhp= String(toInt)
          this.currentKW = toPhp
          this.loadPlan()
          return
        }
      }
    },
    getDay(date){
      let day = ''
      const newDate = Date.parse(date)
      const newDateDay = new Date(newDate).getDay()
      switch (newDateDay){
        case 0:{day = 'Sonntag';break}
        case 1:{day = 'Montag';break}
        case 2:{day = 'Dienstag';break}
        case 3:{day = 'Mittwoch';break}
        case 4:{day = 'Donnerstag';break}
        case 5:{day = 'Freitag';break}
        case 6:{day = 'Samstag';break}
      }
      const newDateMonth = new Date(newDate).getMonth() +1
      const newDateDate = new Date(newDate).getDate()

      const finalString = day + ' - '+newDateDate+ '.' +newDateMonth
      return finalString
    },
    async downloadICS(){
      const filename = 'Schichten.ics'
      const file = await new Promise((resolve, reject) => {
        createEvents(this.icsEvents, (error, value) => {
          if (error) {
            reject(error)
          }

          resolve(new File([value], filename, { type: 'plain/text' }))
        })
      })
      const url = URL.createObjectURL(file);
      this.downloadURL = url
      this.downloadName = filename
    },
    getICS : function (week){
      const _this = this
      const fD = new FormData()
      this.icsEvents = []
      const timeSpanBegin = week.Monday.slice(6,11)+'-'+week.Monday.slice(3,5)+'-'+week.Monday.slice(0,2)
      const timeSpanEnd = week.Sunday.slice(6,11)+'-'+week.Sunday.slice(3,5)+'-'+week.Sunday.slice(0,2)
      fD.append('begin', timeSpanBegin)
      fD.append('end', timeSpanEnd)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('personallayers/getAllSlots', fD)
          .then((response) => {
            console.log(response.data)
            if(response.status === 250){
              _this.slots = response.data
              _this.slots.map(s=>{
                const startTime = Date.parse(s.slotStart)/1000
                const endTime = Date.parse(s.slotEnd)/1000
                const hours = Math.floor((endTime - startTime)/3600)
                const min = ((endTime - startTime) % 3600)/60

                const date = new Date(s.slotStart)
                const event = {
                  title:s.workareasTitle,
                  start:[date.getFullYear(),(date.getMonth()+1),date.getDate(),date.getHours(),date.getMinutes()],
                  location:s.praxisname + ','+s.street +' '+s.hnr+','+ s.plz + ' ' + s.city,
                  duration:{hours:hours, minutes:min}
                }
                this.icsEvents.push(event)
              })
              this.downloadICS()
            }
          })
    },
  }
}
</script>

<style scoped>
#praxisplaner h1{
  font-size: 2em;
}
#praxisplaner h2{
  font-size: 1.2em;
}
#user-list{
  padding: 0;
  margin: 0;
}
#user-list li{
  display: inline;
  margin-right: 4px;
}
#user-list li img{
  border-radius: 90%;
  max-width: 48px;
  border: 3px solid #ffffff;
  cursor: pointer;
}
#user-list li img:hover{
  border: 3px solid var(--theme-diagnostika-primary);
  transition: 0.3s;
  transform: scale(1.1);
}
#PlanerMenu .field{
  display: inline-block;
}
#PlanerMenu .field select{
  border: 1px solid rgba(0,0,0,0.06);
  font-weight: bold;
  padding: 8px;
  margin-right: 8px;
  border-radius: 10px;
}
.singleMember img{
  border: 3px solid var(--lightblue);
  transition: 0.3s;
}
.singleMember img:hover,
.singleMember img.active{
  border: 3px solid var(--theme-diagnostika-primary);
  transition: 0.3s;
}
.button.activeGroup{
  background-color: var(--theme-diagnostika-primary);
  color: var(--white);
}
</style>