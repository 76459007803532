<template>
  <main>
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-primary">
          Übersicht
        </h2>
        <h1 style="padding-left: 4px;">
          Mein Urlaub
        </h1>
        <div style="display: flex;flex-wrap: wrap;gap:20px">
          <div class="bgcolor-white"
               style="min-height: 150px;max-width: 630px;width: 90vw;
                      padding: 32px;box-sizing: border-box;border-radius: 20px;
                      display: flex;justify-content: space-around;align-items: center"
          >
            <div style="display: flex;flex-direction: column;align-items: center">
              <h4 style="font-size: 18px;font-weight: normal">Jährliche Urlaubstage</h4>
              <div style="background-color: #f9f9fd;height: 50px;width: 50px;border-radius: 50%;display: flex;align-items: center;justify-content: center">
                <h4 class="color-green t-center">{{userVacationDatas.vacationdays}}</h4>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;align-items: center">
              <h4 style="font-size: 18px;font-weight: normal">Verbleibende Urlaubstage</h4>
              <div style="background-color: #f9f9fd;height: 50px;width: 50px;border-radius: 50%;display: flex;align-items: center;justify-content: center">
                <h4 class="color-green t-center">{{userVacationDatas.vacationdays - userVacationDatas.currentVacationdays}}</h4>
              </div>
            </div>
          </div>
          <div class="bgcolor-white pad-16" style="width: 90vw;max-width: 630px;border-radius: 10px;box-sizing: border-box">
            <h3 style="font-size: 24px;padding-bottom: 8px">Genehmigter Urlaub</h3>
            <div style="display: flex;flex-direction: column;gap: 12px">
              <div v-for="vacation in confirmedVacations" :key="vacation.id" class="bgcolor-lightgreen pad-12" style="border-radius: 10px">
                <h4 style="font-size: 18px;font-weight: normal">Vom : <b>{{renderTimestampForJS(vacation.vacationStart)}}</b> bis <b>{{renderTimestampForJS(vacation.vacationEnd)}}</b></h4>
                <h4 style="font-size: 18px;font-weight: normal">Urlaubstage : <b>{{vacation.daysTotal}}</b></h4>
                <div style="margin-top: 12px">
<!--                  Status 3-->
                  <button class="button green" style="cursor: not-allowed">Bitte um Rückzug</button>
                </div>
              </div>
            </div>
          </div>
<!--          Urlaub einreichen-->
          <div class="bgcolor-white" style="min-height: 150px;max-width: 630px;width: 90vw;padding: 32px;box-sizing: border-box;border-radius: 20px">
            <h2 style="font-size: 20px">Urlaub einreichen</h2>
            <div style="display: flex;flex-direction: column;align-items: center">
              <VDatePicker :attributes="calenderAttributes"  :min-date="new Date()" show-weeknumbers expanded v-model.range="newVacation" mode="date"  @click="renderVacationDays"/>
            </div>
            <div style="display: flex;justify-content: space-between;padding-top: 12px">
              <h4 style="font-size: 18px; font-weight: 600">Tage: {{vacationDays}}</h4>
              <button class="primary button" @click="setVacation">Anfragen</button>
            </div>
          </div>
          <div class="bgcolor-white pad-16" style="width: 90vw;max-width: 630px;border-radius: 10px;box-sizing: border-box">
            <h3 style="font-size: 24px;padding-bottom: 8px">Offene Urlaubsanfragen</h3>
            <div style="display: flex;flex-direction: column;gap: 12px">
              <div v-for="vacation in openVacationRequest" :key="vacation.id" class="bgcolor-lightblue pad-12" style="border-radius: 10px">
                <h4 style="font-size: 18px;font-weight: normal">Vom : <b>{{renderTimestampForJS(vacation.vacationStart)}}</b> bis <b>{{renderTimestampForJS(vacation.vacationEnd)}}</b></h4>
                <h4 style="font-size: 18px;font-weight: normal">Urlaubstage : <b>{{vacation.daysTotal}}</b></h4>
                <div style="margin-top: 12px">
                  <button class="primary button" @click="deleteVacation(vacation.id)">Anfrage Löschen</button>
                </div>
              </div>
            </div>
          </div>
          <div class="bgcolor-white pad-16" style="width: 90vw;max-width: 630px;border-radius: 10px;box-sizing: border-box">
            <h3 style="font-size: 24px;padding-bottom: 8px">Abgelehnte Urlaubsanfragen</h3>
            <div style="display: flex;flex-direction: column;gap: 12px">
              <div v-for="vacation in denyVacations" :key="vacation.id" class="bgcolor-lightred pad-12" style="border-radius: 10px">
                <h4 style="font-size: 18px;font-weight: normal">Vom : <b >{{renderTimestampForJS(vacation.vacationStart)}}</b> bis <b>{{renderTimestampForJS(vacation.vacationEnd)}}</b></h4>
                <h4 style="font-size: 18px;font-weight: normal">Urlaubstage : <b>{{vacation.daysTotal}}</b></h4>
                <h4 style="font-size: 18px;font-weight: normal" v-if="vacation.denyText"><b>Begründung </b>: {{vacation.denyText}}</h4>
                <div style="margin-top: 12px">
                  <button class="button red" @click="deleteVacation(vacation.id)">Anfrage Löschen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { getHolidays, isHoliday, isSpecificHoliday} from 'feiertagejs';


export default {
  name: "MyVacation",
  title: "Mein Urlaub",
  components: {

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()


    return {auth, config, user}
  },
  data(){
    return {
      newVacation:{
        start:new Date(new Date(Date.now()).toISOString().slice(0,10)),
        end:new Date(new Date(Date.now()).toISOString().slice(0,10)),
      },
      ferienHighlight:{
        style:{
          backgroundColor: 'white'
        },
        contentStyle:{
          color:'black'
        }
        },
      calenderAttributes:[
          //  Feiertage
          {
            highlight: {
              style:{
                backgroundColor: '#80ff8000',     // Red background
                // borderColor: '#ff6666',
                // borderWidth: '2px',
                // borderStyle: 'solid',
              },
              contentStyle: {              // Text styles
                color: 'black'
              },
            },
            dates: [

            ],
            dot:{
              color:'green'
            },
            popover:{
              label:'Feiertag',
              hideIndicator:true
            }
          },

      ],
      vacationDays:1,
      userVacationDatas:'',
      yearlyVacation:'',
      usedVacation:'',
      weeklyWorkingDays:2,
      openVacationRequest:'',
      confirmedVacations:'',
      denyVacations:''
    }
  },
  mounted() {
    this.getUserVacationDatas()
    this.getVacations(0)
    this.getVacations(1)
    this.getVacations(2)
    this.renderFeiertage()
    this.getFerien()
  },
  methods: {
    getUserVacationDatas(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('vacation/getUserWorkData', fD)
          .then((response) => {
            if(response.status === 250){
              this.userVacationDatas = response.data
              this.weeklyWorkingDays = response.data.workingdaysTotal
              // console.log(response.data)
            }
          })
    },
    getVacations(status){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('status', status)
      this.axios.post('vacation/getMyVacations', fD)
          .then((response) => {
            if(response.status === 250){
              if (status === 0){
                this.openVacationRequest = response.data
              }
              else if(status === 1){
                this.confirmedVacations = response.data
                console.log(this.confirmedVacations)
                response.data.map((singleHoliday)=>{
                  this.calenderAttributes.push(
                      {
                        highlight:this.ferienHighlight,
                        dates: [{start:new Date(singleHoliday.vacationStart),end:new Date(singleHoliday.vacationEnd)}],
                        dot: {color:'blue',opacity:0.75},
                        popover: {label:'Urlaub',hideIndicator:true}
                      })
                })
              }
              else if(status === 2){
                this.denyVacations= response.data
              }
            }
          })
    },
    setVacation(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('praxisId', this.config.organizerId)
      fD.append('vacationStart', this.newVacation.start.toISOString().slice(0,10))
      fD.append('vacationEnd', this.newVacation.end.toISOString().slice(0,10))
      fD.append('daysTotal', this.vacationDays)
      this.axios.post('vacation/setVacation', fD)
          .then((response) => {
            if(response.status === 250){
              this.getVacations(0)
            }
          })
    },
    deleteVacation(slotId){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', slotId)
      this.axios.post('vacation/deleteVacation', fD)
          .then((response) => {
            if(response.status === 250){
              this.getVacations(0)
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0]
    },
    renderVacationDays(){
      const startDate = this.newVacation.start
      const endDate = this.newVacation.end
      let holidayDays = 0
      //Berechnung der Woche
      let weekNumber = Math.ceil((Math.floor(new Date(startDate.toISOString().slice(0,10)) - new Date(startDate.getFullYear() ,0,1)) / (24*60*60*1000)) / 7)
      let weekholidays = 0
      if (startDate > endDate){return}
      let whileDate = startDate
      while (whileDate <= endDate){
        //Ist der Tag ein gesetzlicher Feiertag in Niedersachsen ?
        if (!isHoliday(whileDate, this.config.region)){
          //Ist der Tag ein Sonntag ?
          if (whileDate.getDay() === 0 ||whileDate.getDay() === 6){
            if (whileDate.getDay()=== 0){
              weekNumber = weekNumber +1
              weekholidays = 0
            }
          }
          else {
            if(weekholidays < this.weeklyWorkingDays){
              weekholidays ++
              holidayDays ++
            }
          }
        }
        whileDate =new Date(whileDate.setDate(whileDate.getDate() +1))
      }
    this.vacationDays = holidayDays
    },
    //
    renderFeiertage(){
      getHolidays(new Date().getFullYear(), this.config.region).map((singleHoliday)=>{
        this.calenderAttributes[0].dates.push(new Date(singleHoliday.date))
      })
    },
    getFerien(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('year', new Date().getFullYear())
      fD.append('region', this.config.region)
      this.axios.post('holidays/getHolidays', fD)
          .then((response) => {
            if(response.status === 250){
              response.data.map((singleHoliday)=>{
                this.calenderAttributes.push(
                    {
                    highlight:this.ferienHighlight,
                    dates: [{start:new Date(singleHoliday.start),end:new Date(singleHoliday.end)}],
                  dot: {color:'red',opacity:0.75},
                  popover: {label:singleHoliday.holidayName,hideIndicator:true}
                })
              })
              console.log(this.calenderAttributes)
            }
          })
    }
  }
}
</script>

<style>

</style>