import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import profil from "@/views/profil";
import login from "@/views/login";
import invite from "@/views/invite";
import timetracking from "@/views/timetracking";
import dashboard from "@/views/dashboard";
import vacationplaner from "@/views/vacationplaner";
import myvacation from "@/views/myvacation";
import praxisverwaltung from "@/views/praxisverwaltung";
import usermanagement from "@/views/usermanagement";
import notifications from "@/views/notifications";
import praxisplaner from "@/views/praxisplaner";

import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";

import userOverview from "@/views/usermanagement/overview";
import userCreate from "@/views/usermanagement/create";
import userEdit from "@/views/usermanagement/edit";
import playground from "@/views/playground.vue";
import sickReport from "@/views/sickReport.vue";
import myLayers from "@/views/myLayers";
import timeEvaluation from "@/views/timeEvaluation";
import register from "@/views/register";

const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/',
    alias: '/register',
    name: 'Register',
    components: {
      default: register
    },
    meta: { noAuth: true },
  },
  {
    path: '/invite',
    name: 'Invite',
    components: {
      default: invite
    },
    meta: { noAuth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profil,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/vacationplaner',
    name: 'Vacationplaner',
    components: {
      navBar: navBar,
      default: vacationplaner,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/myvacation',
    name: 'MyVacation',
    components: {
      navBar: navBar,
      default: myvacation,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/timetracking',
    name: 'Timetracking',
    components: {
      navBar: navBar,
      default: timetracking,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/praxisverwaltung',
    name: 'Praxisverwaltung',
    components: {
      navBar: navBar,
      default: praxisverwaltung,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/praxisplaner',
    name: 'Praxisplaner',
    components: {
      navBar: navBar,
      default: praxisplaner,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/timeevaluation',
    name: 'Zeitauswertung',
    components: {
      navBar: navBar,
      default: timeEvaluation,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/benutzerverwaltung/:page?',
    alias: '/usermanagement',
    name: 'Usermanagement',
    components: {
      navBar: navBar,
      default: usermanagement,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'UserOverview',
        component: userOverview
      },
      {
        path: 'create',
        name: 'UserCreate',
        component: userCreate
      },
      {
        path: 'edit/:id',
        name: 'UserEdit',
        component: userEdit
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    components: {
      navBar: navBar,
      default: notifications,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/playground',
    name: 'playground',
    components: {
      navBar: navBar,
      default: playground,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/sickreport',
    name: 'sickReport',
    components: {
      navBar: navBar,
      default: sickReport,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/mylayers',
    name: 'myLayers',
    components: {
      navBar: navBar,
      default: myLayers,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/home' }
    },
  }
]

const router = createRouter({
  history: process.env.CORDOVA_PLATFORM ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  config.sidebar = false

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          next({
            path: '/login',
            query: { redirect: encodeURI(to.fullPath) }
          })
        })
  }
  else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  }
  else {
    auth.update()
        .catch(e =>{
          //BEHEBUNG!
          console.log(e)
        })
    config.loading = true
    next()
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
