<template>
  <div class="content-1400">
    <div class="pad-16">
      <div class="bgcolor-white br-10 pad-16" style="display: flex;flex-direction: column;gap: 20px">
        <div style="display: flex;justify-content: space-between">
          <h1>Getrackte Zeiten</h1>
          <button class="button primary">
            Bearbeiten
          </button>
        </div>

        <div class="col-sp-10 row-sp-12" style="grid-column-start: 2">
          <VDatePicker v-model="usedDate" :attributes="calenderDates" expanded @click="renderDate" />
        </div>
        <div class="grid-12" style="grid-gap: 20px">
          <div v-for="timeSlot in renderValues"
               :key="timeSlot.id"
               @click="editModal = true; editValues = timeSlot"
               style="cursor: pointer;"
               class="bgcolor-lightblue br-10 pad-8 col-sp-3 row-sp-6"
          >
            <p>{{ timeSlot.trackType }}</p>
            <p>Arbeitsbeginn: {{ renderTimestampForJS(timeSlot.trackStart) }}</p>
            <p v-if="!timeSlot.running">Arbeitsende: {{ renderTimestampForJS(timeSlot.trackEnd) }}</p>
            <p v-if="!timeSlot.running">GesamtZeit: {{ timeSlot.total }}</p>
            <p v-if="timeSlot.running"><b>Arbeitet derzeit</b></p>
          </div>
        </div>
      </div>
    </div>
    <edit-time-modal 
      v-if="editModal" 
      @closingEditTimeModal="editModal=false" 
      :time-values="editValues"
    />
  </div>
</template>

<script>
  import {authStore} from "@/store/authStore";
  import {userStore} from "@/store/userStore";
  import {configStore} from "@/store/configStore";
  import EditTimeModal from "@/components/usermanagement/editTimeModal";

  export default {
    name: "UserTimes",
    components: {EditTimeModal},
    setup: function () {
        const auth = authStore()
        const config = configStore()
        const user = userStore()

        return {auth, config, user}
      },
    data() {
      return {
        renderValues:[],
        currentMonth:'',
        usedDate:'',
        allTrackedTimes:[],
        editValues:'',
        editModal:false,
        calenderDates:[
          {
            highlight:{
              style:{
                backgroundColor:'white',
                borderStyle:'solid',
                borderColor:'#0173fe60'
              },
              contentStyle:{
                color:'#0f172a'
              }
            },dates:[

            ]
          }
        ]
      }
    },
    mounted() {
      this.getTrackedTime()
    },
    methods: {
      renderDate(){
        const found = this.allTrackedTimes.filter(({trackStart})=> new Date(trackStart).toDateString() === new Date(this.usedDate).toDateString())
        this.renderValues = found
      },
      getTrackedTime(){
        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        fD.append('userId', this.$route.params.id)
        fD.append('month', '2023-03')
        this.axios.post('timetracking/getTrackItemsByUser', fD)
            .then((response) => {
              if(response.status === 250){
                this.allTrackedTimes= response.data
                response.data.map((singleTime)=>{
                  this.calenderDates[0].dates.push(new Date(singleTime.trackStart))
                })
              }
            })
      },
      renderTimestampForJS(date){
        const jsTimestamp = date.split(' ')
        const jsDate = jsTimestamp[0].split('-')
        const jsTime = jsTimestamp[1].split(':')
        return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1] + ':' + jsTime[2]
      },
    }
  }

</script>

<style>

</style>