<template>
  <div class="vueModal" id="selectNewSlotModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="handleClosing" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="handleClosing">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <div>
        <h3>Schichtzeit bearbeiten</h3>{{timeValues}}
        <div class="pad-16"></div>
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="editTimeStart">Am</label>
              <input type="date" name="editTimeStart" id="editTimeStart" v-model="editStartDate" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="editTimeStart">von</label>
              <input type="time" name="sickEndDate" id="sickEndDate" v-model="editStartTime"  style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
            </div>
          </div>
        </div>
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="editTimeStart">Am</label>
              <input type="date" name="editTimeStart" id="editTimeStart" v-model="editEndDate" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
            </div>
          </div>
          <div class="col-sp-6 row-sp-12">
            <div class="field">
              <label for="editTimeStart">bis</label>
              <input type="time" name="sickEndDate" id="sickEndDate" v-model="editEndTime"  style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
            </div>
          </div>
        </div>
        <h5>Pausen</h5>
        <div v-for="pause in timeValues.breaks_data" :key="pause.id">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12">
              <div class="field">
                <label for="editTimeStart">Am</label>
                <input type="date" name="editTimeStart" id="editTimeStart" v-model="pause.breakStart" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
              </div>
            </div>
            <div class="col-sp-6 row-sp-12">
              <div class="field">
                <label for="editTimeStart">bis</label>
                <input type="time" name="sickEndDate" id="sickEndDate" v-model="pause.breakEnd"  style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
              </div>
            </div>
          </div>


        </div>
        <button class="mar-top-16 button primary fluid" @click="submitSickReport">
          Anpassungen abschicken
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "editTimeModal",
  props:['timeValues'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      editStartDate:this.timeValues.trackStart.slice(0,10),
      editStartTime:this.timeValues.trackStart.slice(11,16),
      editEndDate:this.timeValues.trackEnd.slice(0,10),
      editEndTime:this.timeValues.trackEnd.slice(11,16),
    }
  },
  mounted() {
    console.log(this.timeValues)
  },
  methods:{
    handleClosing(){
      this.$emit("closingEditTimeModal", false)
    },
    submitSickReport(){
      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('reportId', this.editReportId)
      fD.append('sickReportStart', this.SickStartDate)
      fD.append('sickReportEnd', this.SickEndDate)
      this.axios.post('sickreport/updateSickReport', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('updateSickReports')
              this.handleClosing()
            }
          })
    },
  }
}
</script>

<style scoped>

</style>