import { authStore } from '@/store/authStore'
import { configStore } from '@/store/configStore'
import axios from 'axios'

export function aSyncData (url, postData, options) {
    return new Promise((resolve, reject) => {
        const auth = authStore()
        const config = configStore()

        const fD = new FormData()
        fD.append('sessionID', auth.sessionID)

        for (const key in postData) {
            typeof postData[key] === 'object' && !(postData[key] instanceof Blob) && postData[key]?.constructor === ({}).constructor ? fD.append(key, JSON.stringify(postData[key])) : fD.append(key, postData[key])
        }

        axios.post(config.projectURL + url, fD, options)
            .then(r => {
                resolve(r)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export function stringToDate(dateString){
    try {
        let arr = dateString.split(/[- :]/)
        if(arr.length > 3){
            return new Date(parseInt(arr[0]), parseInt(arr[1])-1, parseInt(arr[2]), parseInt(arr[3]), parseInt(arr[4]), parseInt(arr[5]))
        }else{
            return new Date(parseInt(arr[0]), parseInt(arr[1])-1, parseInt(arr[2]))
        }
    }catch (e) {
        console.log(e)
        return null
    }
}

export function dateToString(date){
    try {
        return String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ' ' + String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() +1).padStart(2, '0') + '.' + date.getFullYear()
    }catch (e) {
        console.log(e)
        return null
    }
}

export function calcDistance(lat1, lon1, lat2, lon2)
{
    let R = 6371;
    let dLat = toRad(lat2-lat1);
    let dLon = toRad(lon2-lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c;
    return d;
}

export function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

// Converts numeric degrees to radians
function toRad(Value){
    return Value * Math.PI / 180;
}

export function dateForInput(date){
    let returnDay = date.getDate() <= 9 ?  '0' + String(date.getDate()) : String(date.getDate())
    let returnMonth = (date.getMonth() +1) <= 9 ? '0' + String(date.getMonth() +1) : String(date.getMonth() +1)


    return String(date.getFullYear()) + '-' + returnMonth + '-' + returnDay
}