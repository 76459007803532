<template>
  <div class="time-intervals">
    <div class="time-interval" v-for="(interval, index) in intervals" :key="index">
      {{ interval }}
    </div>
  </div>
</template>

<script>
export default {
  name:'Playground',
  data() {
    return {
      intervals: [
        "12:00 AM",
        "1:00 AM",
        "2:00 AM",
        "3:00 AM",
        "4:00 AM",
        "5:00 AM",
        "6:00 AM",
        "7:00 AM",
        "8:00 AM",
        "9:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "1:00 PM",
        "2:00 PM",
        "3:00 PM",
        "4:00 PM",
        "5:00 PM",
        "6:00 PM",
        "7:00 PM",
      ],
      isOverflow: false,
    };
  },
  computed: {
    containerClass() {
      return this.isOverflow ? "time-intervals--stacked" : "";
    },
  },
  mounted() {
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkOverflow);
  },
  methods: {
    checkOverflow() {
      const container = this.$el;
      this.isOverflow = container.scrollWidth > container.clientWidth;
    },
  },
};
</script>

<style scoped>
.time-intervals {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.time-interval {
  box-sizing: border-box;
  width: calc(100% / 4);
  padding: 5px;
  text-align: center;
}

.time-intervals--stacked {
  flex-direction: column;
}

.time-intervals--stacked .time-interval {
  width: 100%;
}
</style>