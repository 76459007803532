<template>
  <div class="pad-8" />
  <div class="bgcolor-white br-10 pad-16" v-if="rerenderDay">
    <div style="display: flex;justify-content: space-between; align-items: center">
      <div>
        <h4 style="display: inline;">
          {{ weekDates.name }}<span class="label tiny blue mar-left-8" style="vertical-align: 4px;">{{ weekDates.date }}</span>
        </h4>
      </div>
      <div style="display: flex;gap:12px">
        <button class="button tiny pad-right-8" @click="templateSaveModal = true" v-if="slotItems">
          <svg style="opacity: 0.4 ;vertical-align: -4px;" width="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none" /><path d="M4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z" /></g></svg>
          Als Vorlage speichern
        </button>
        <button class="button tiny" @click="slotSettingsModal = true">
          <svg style="opacity: 0.4; vertical-align: -2px;" width="16px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" /></svg>
          Schichten anpassen
        </button>
      </div>
    </div>
<!--    TESTBEREICH-->
    <div>
      <div class="pad-16" />
      <div class="hourLines" style="height:fit-content;width: 100%;box-sizing: border-box;position: relative;border-left: 1px solid #aaaaaa33">
        <div id="slotGrid" style="z-index:4;display: grid;grid-auto-columns: calc((100% - 48px)/48);column-gap: 1px;grid-auto-flow: row dense;"  @drop="droppi($event)" @dragenter.prevent @dragover.prevent>
          <div v-for="singleSlot in slotItems"
               :key="singleSlot.id"
               onresize="this.ForceUpdateComponent()"
               draggable="true"
               ondrop="return false"
               @dragstart="startDrag($event, singleSlot)"
               :style="renderSlot2(singleSlot.slotStart, singleSlot.slotEnd)"
               style="z-index: 2;position: relative;user-select: none;"
               class="mobileSlot">
            <div
                v-if="singleSlot.userId == null" class="slot bgcolor-grey pad-8 br-4 color-white t-center" style=" cursor: pointer;box-sizing: border-box"
                @click="getSocialScore(singleSlot);slotId = singleSlot.id; slotType = null;"
            >
              <strong>{{ singleSlot.workgroupTitle }}</strong> - {{ singleSlot.workareasTitle }}
              <div>{{ singleSlot.slotStart.slice(11, 16) }} - {{ singleSlot.slotEnd.slice(11, 16) }} Uhr</div>
            </div>
            <div
                v-else-if="checkIfUserActive(singleSlot.userId)" class="slot pad-8 br-4 color-white t-center " :class="checkIfUserSick(singleSlot.userId)"  style=" cursor: pointer;box-sizing: border-box"
                @click=" getSocialScore(singleSlot);slotId = singleSlot.id;slotType = 'active';"
            >
              <div class="mar-bottom-8">
                <img :src="config.projectURL + '/assets/images/users/' + singleSlot.userimg" class="width-48 circular bgcolor-lightblue">
                <div style="display: inline-block;">
                  <span>{{ singleSlot.title }} {{ singleSlot.firstname }} {{ singleSlot.lastname }}</span>
                </div>
              </div>
              <strong>{{ singleSlot.workgroupTitle }}</strong> - {{ singleSlot.workareasTitle }}
              <div>{{ singleSlot.slotStart.slice(11, 16) }} - {{ singleSlot.slotEnd.slice(11, 16) }} Uhr</div>
            </div>
          </div>
          <div style="z-index:1;grid-column:  1 / 49;width:100%;height: 100%;position: absolute;">
            <div class="hourline2"
                 v-for="n in 24"
                 :key="n"
                 :style="renderHourline(n)"/>
          </div>
        </div>

      </div>
      <!-- Keine Schichten gefunden-->
      <div v-if="slotItems == null && freeDays == null">
        <div class="grid-12 planerMenu">
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-16">
            <div style="cursor: pointer;" class="bgcolor-lightblue color-blue br-10 pad-24 t-center" @click="createSlotModal = true;">
              <div>
                <svg width="80px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z" fill="#0173fe" class="fill-000000" /></svg>
              </div>
              Neue Schichten anlegen
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-16">
            <div style="cursor: pointer;" class="bgcolor-lightblue color-blue br-10 pad-24 t-center" @click="selectTemplateModal = true;">
              <div>
                <svg width="80px" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4ZM3 10a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-6ZM14 9a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-2Z" fill="#0173fe" class="fill-4a5568" /></svg>
              </div>
              Schichten-Vorlage auswählen
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-16">
            <div style="cursor: pointer;" class="bgcolor-lightblue color-blue br-10 pad-24 t-center" @click="createFreedayModal = true;">
              <div>
                <svg width="80px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6 295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z" fill="#0173fe" class="fill-000000" /></svg>
              </div>
              Als Freien Tag makieren
            </div>
          </div>
        </div>
      </div>
      <div v-if="freeDays != null" class="pad-16">
        <div class="t-center color-blue pad-16 br-10" style="background-color: #00000010">
          <svg width="80px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-v-21439d42=""><path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6 295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z" fill="#0173fe" class="fill-000000" data-v-21439d42=""></path></svg>
          <p>Als freier Tag markiert</p>
          <div class="pad-8"></div>
          <button @click="removeFreeDay(freeDays.id)" class="primary button">Tag wieder freigeben</button>
        </div>
      </div>
      <div class="pad-16" />
      <div class="grid-12 t-center" style="color: rgba(0,0,0,0.4); font-size: 0.4em;">
        <div class="col-sp-2 row-sp-12">
          2:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          6:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          10:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          14:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          18:00 Uhr
        </div>
        <div class="col-sp-2 row-sp-12">
          22:00 Uhr
        </div>
      </div>
    </div>
<!--    TESTBEREICH ENDE-->
  </div>
  <div class="vueModal" id="selectModal" v-if="selectModal" style="position:fixed;">
    <div class="vueModalOuter" style="height: 100%" @click="selectModal = false; socialscoredata = null;" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div class="grid-12">
        <div class="col-sp-1 row-sp-12">
          <a style="cursor: pointer" title="Löschen" @click="deleteSlot()">
            <img class="width-24" src="assets/images/icons/trash-icon.svg" style="opacity: 0.5">
          </a>
        </div>
        <div class="col-sp-1 row-sp-12">
          <a style="cursor: pointer" title="Bearbeiten" @click="editSlotModal = true">
            <img class="width-24" src="assets/images/icons/edit-icon.svg">
          </a>
        </div>
        <div class="col-sp-8 row-sp-12">
          <h4 class="t-center">
            Mitarbeiter*in auswählen
          </h4>
        </div>
        <div class="col-sp-2 row-sp-12 t-right">
          <div style="clear: both;">
            <div style="float: right; cursor: pointer;" @click="selectModal = false;">
              <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grid-12 pad-bottom-16 mar-top-16">
          <div class="col-sp-10 row-sp-12">
            <input type="search" class="search" placeholder="Suche...">
          </div>
          <div class="col-sp-2 row-sp-12">
            <button class="primary button">
              Suchen
            </button>
          </div>
        </div>
        <div v-if="slotType == 'active'">
          <div class="grid-12 user" @click="clearSlot(slotId)">
            <div class="col-sp-2 row-sp-12 t-center">
              <svg enable-background="new 0 0 512 512" height="64px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="64px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
            </div>
            <div class="col-sp-10 row-sp-12 pad-left-24">
              <h6 class="pad-top-20">
                Mitarbeiter*in von Schicht entfernen
              </h6>
            </div>
          </div>
        </div>
        <div style="overflow-y: scroll; max-height: 300px;">
          <div class="grid-12 user" v-for="singleUser in socialscoredata" :key="singleUser.id" @click="setEmployeeToSlot(singleUser.id)">
            <div class="col-sp-2 row-sp-12 t-left">
              <img :src="config.projectURL + 'assets/images/users/' + singleUser.userImg" class="width-80 circular bgcolor-primary">
            </div>
            <div class="col-sp-10 row-sp-12 pad-left-24">
              <h6>{{ singleUser.title }} {{ singleUser.firstname }} {{ singleUser.lastname }}</h6>
              <p>{{ singleUser.workgroupTitle }}</p>
              <p style="font-size: 13px">
                {{ singleUser.worktypeTitle }}
              </p>
              <span class="label blue tiny">{{ singleUser.socialScore }}</span>
            </div>
          </div>
          <div v-if="socialscoredata == null">
            <p class="t-center pad-16">
              Keine passenden Mitarbeiter*innen gefunden
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Neue Schicht Anlegen Template -->
  <create-freeday-modal
      v-if="createFreedayModal"
      @closingCreateSlot="(bool)=>{createFreedayModal= bool}"
      @activateFreeDay="this.freeDays = null,this.getFreeDays(),this.getSlots()"
      :week-dates="weekDates"
  />
  <!--Neue Schicht Anlegen Template -->
  <create-slot-modal
    v-if="createSlotModal"
    @closingCreateSlot="(bool)=>{createSlotModal= bool}"
    @activateGetSlots="getSlots(); selectModal=false"
    :week-dates="weekDates"
  />
  <!--Alte Schicht bearbeiten -->
  <edit-slot-modal
    v-if="editSlotModal"
    @closingEditSlot="(bool)=>{editSlotModal= bool}"
    @activateClearSlot="clearSlot()"
    @activateGetSlots="getSlots(); selectModal=false"
    :slot-items="slotItems"
    :slot-id="slotId"
    :week-dates="weekDates"
  />
  <!--  Slot Bearbeiten Template-->
  <div class="vueModal" id="selectNewSlotModal" v-if="slotSettingsModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="slotSettingsModal = false;" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="slotSettingsModal = false;">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <div id="slotSettingsMenu">
        <h4 class="t-center">
          {{ weekDates.name }}<span class="label tiny blue mar-left-8" style="vertical-align: 4px;">{{ weekDates.date }}</span>
        </h4>
        <div class="pad-8">
          <div style="cursor: pointer;" class="bgcolor-lightblue color-blue br-10 pad-24 t-center" @click="createSlotModal = true; slotSettingsModal = false;">
            <div>
              <svg width="80px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M8 12H4v28c0 2.21 1.79 4 4 4h28v-4H8V12zm32-8H16c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 18h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z" fill="#0173fe" class="fill-000000" /></svg>
            </div>
            Neue Schicht anlegen
          </div>
        </div>
        <div class="pad-8">
          <div style="cursor: pointer;" class="bgcolor-lightblue color-blue br-10 pad-24 t-center" @click="selectTemplateModal= true; slotSettingsModal = false;">
            <div>
              <svg width="80px" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4ZM3 10a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-6ZM14 9a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-2Z" fill="#0173fe" class="fill-4a5568" /></svg>
            </div>
            Schichten-Vorlage auswählen
          </div>
        </div>
        <div class="pad-8">
          <div style="cursor: pointer;" class="bgcolor-lightblue color-blue br-10 pad-24 t-center" @click="createFreedayModal = true; slotSettingsModal = false;">
            <div>
              <svg width="80px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6 295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z" fill="#0173fe" class="fill-000000" /></svg>
            </div>
            Als Freien Tag makieren
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Template erstellen-->
  <create-slot-template
    v-if="templateSaveModal"
    @closingCreateSlotTemplate="(bool)=>{templateSaveModal= bool}"
    :slot-items="slotItems"
    :slot-id="slotId"
  />
  <!--  Template auswählen-->
  <select-template-modal
    v-if="selectTemplateModal"
    @closingSelectSlotTemplate="(bool)=>{selectTemplateModal= bool}"
    @activateGetSlots="getSlots();"
    :slot-items="slotItems"
    :slot-id="slotId"
    :week-dates="weekDates"
  />
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import EditSlotModal from "@/components/praxisplaner/dayPlanModels/editSlotModal";
import CreateSlotModal from "@/components/praxisplaner/dayPlanModels/createSlotModal.vue";
import CreateFreedayModal from "@/components/praxisplaner/dayPlanModels/createFreedayModal.vue";

import CreateSlotTemplate from "@/components/praxisplaner/dayPlanModels/createSlotTemplate.vue";
import SelectTemplateModal from "@/components/praxisplaner/dayPlanModels/selectTemplateModal";


export default {
  name: "DayPlan",
  components: {
    SelectTemplateModal,
    CreateSlotTemplate,
    CreateSlotModal,
    EditSlotModal,
    CreateFreedayModal
  },
  props: ["weekDates","activeUsers"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      rerenderDay:true,
      slotItems: null,
      selectModal: false,
      slotId: null,
      slotType: null,
      socialscoredata: null,
      createSlotModal:false,
      editSlotModal : false,
      selctedworkgroup: '',
      selectedworkarea: [],
      selectedworkplaces: [],
      workgroups: [],
      workareas: [],
      slotSettingsModal:false,
      templateSaveModal: false,
      selectTemplateModal: false,
      createFreedayModal: false,
      sickReports:[],
      freeDays: null,
      xAchse:'',
      dragedStartingPoint:'',
      dragedEndingTime:'',
      dragedBoxEnd:'',
      halfHourWidth:''
    }
  },
  mounted() {
    this.config.loading = false
    this.getSlots()
    this.getWorkgroups()
    this.getSickReports()
    this.getFreeDays()
  },
  methods: {
    startDrag(evt, item) {
      const gridWidth = document.getElementById('slotGrid')
      this.dragedStartingPoint = evt.x
      this.halfHourWidth = gridWidth.offsetWidth / 48
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('elemID', item.id)
      evt.dataTransfer.setData('workareaId', item.workareasId)
      evt.dataTransfer.setData('workgroupId', item.workgroupId)
    },
    droppi(evt, list) {
      const halfHoursEdit = Math.round(((this.dragedStartingPoint - evt.x)* -1) / this.halfHourWidth)
      const elemID = evt.dataTransfer.getData('elemID')
      const workareaId = parseInt(evt.dataTransfer.getData('workareaId'))
      const workgroupId = parseInt(evt.dataTransfer.getData('workgroupId'))
      const findId = this.slotItems.find((item) => item.id === parseInt(elemID))
      const oldSlotStartDate = new Date(findId.slotStart)
      const oldSlotEndDate = new Date(findId.slotEnd)
      const newSlotStart = new Date(oldSlotStartDate.getTime() +((halfHoursEdit * 30)*60000))
      const newSlotEnd = new Date(new Date (findId.slotEnd).getTime() +((halfHoursEdit * 30)*60000))
      let newStartString =''
      let newEndString =''
      if (newSlotStart.getDate() !== oldSlotStartDate.getDate()){
        newStartString = oldSlotStartDate.getFullYear()+'-'+(oldSlotStartDate.getMonth()+1)+'-'+oldSlotStartDate.getDate()+' 00:00:00'
      }
      else {
        newStartString = oldSlotStartDate.getFullYear()+'-'+(oldSlotStartDate.getMonth()+1)+'-'+oldSlotStartDate.getDate()+' '+newSlotStart.getHours()+':'+newSlotStart.getMinutes()
      }
      if (newSlotEnd.getDate() !== oldSlotEndDate.getDate()){
        newEndString = oldSlotEndDate.getFullYear()+'-'+(oldSlotEndDate.getMonth()+1)+'-'+oldSlotEndDate.getDate()+' 23:59:59'
      }
      else {
        newEndString = oldSlotEndDate.getFullYear()+'-'+(oldSlotEndDate.getMonth()+1)+'-'+oldSlotEndDate.getDate()+' '+newSlotEnd.getHours()+':'+newSlotEnd.getMinutes()
      }

        const fD = new FormData()
        fD.append('sessionID', this.auth.sessionID)
        fD.append('id', parseInt(elemID))
        fD.append('newSlotStart', newStartString)
        fD.append('newSlotEnd', newEndString)
        fD.append('selctedworkgroup', workgroupId)
        fD.append('selectedworkarea', workareaId)
        this.axios.post('praxisplaner/editSlot', fD)
            .then(r => {
              if(r.status === 250) {
                this.getSlots()
              }
            })
            .catch(e => {
              console.log(e)
            })


    },
    removeFreeDay(id){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/removeFreeDay', fD)
          .then((response) => {
            if(response.status === 250){
              _this.freeDays = null
              _this.getFreeDays()
              _this.getSlots()
            }
          })
    },
    clearSlot(slotId){
      const _this = this
      const fD = new FormData()
      fD.append('slotId', slotId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/clearSlot', fD)
          .then((response) => {
            if(response.status === 250){
              _this.getSlots()
              this.selectModal = false;
            }
          })
    },
    setEmployeeToSlot(userId){
      const _this = this
      const fD = new FormData()
      fD.append('userId', userId)
      fD.append('slotId', this.slotId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/setEmployeeToSlot', fD)
          .then((response) => {
            if(response.status === 250){
              _this.getSlots()
              this.selectModal = false;
              _this.socialscoredata = null;
            }
          })
    },
    getSlots : function (){

      const _this = this
      const fD = new FormData()
      fD.append('date', this.weekDates.date)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/getSlots', fD)
          .then((response) => {
            if(response.status === 250){
              _this.slotItems = response.data
              _this.slotItems.forEach((slot)=>{
                this.renderSlot2(slot.slotStart, slot.slotEnd)
              })
            }
            else{
              this.slotItems = null
              this.rerenderDay = false;
              this.$nextTick(()=>{

                this.rerenderDay = true
              })
            }
          })
    },
    //Anpassung der Größe und Position der Box
    renderSlot2(startTime, endTime){
      const startTimeInUnix = Date.parse(startTime)/ 1000
      const endTimeInUnix = Date.parse(endTime) /1000

      const startingTime =Math.round((((new Date(startTimeInUnix * 1000).getHours())*60)+(new Date(startTimeInUnix * 1000).getMinutes()))/30)
      const endingTime =Math.round((((new Date(endTimeInUnix * 1000).getHours())*60)+(new Date(endTimeInUnix * 1000).getMinutes()))/30)

      return'grid-column-start:'+ (startingTime +1) + ';grid-column-end:'+ (endingTime +1)
    },
    ForceUpdateComponent(){
      this.$forceUpdate()
    },
    //Abfrage der Mitarbeiter für den gewählten Slot
    getSocialScore(slotItems){
      const _this = this
      const fD = new FormData()
      fD.append('workareaId', slotItems.workareasId)
      fD.append('workgroupId', slotItems.workgroupId)
      fD.append('slotstart', slotItems.slotStart)
      fD.append('slotend', slotItems.slotEnd)
      fD.append('slotId', slotItems.id)
      fD.append('sessionID', this.auth.sessionID)
      this.selectModal = true;
      this.axios.post('praxisplaner/getEmployeesForSlot', fD)
          .then((response) => {

            if(response.status === 250){
              _this.socialscoredata = response.data.sort(this.sorter)
              this.selectModal = false; this.$nextTick(()=>{this.selectModal=true;})
            }


          })
    },
    //Sortierfunktion
    sorter(a,b){
      if (typeof a.socialScore === "string"){
        return  1;
      }
      else if(typeof b.socialScore === "string"){
        return -1;
      }
      else{
        if (a.socialScore < b.socialScore){
          return 1;
        }
        if (a.socialScore > b.socialScore){
          return -1;
        }
        return 0;
      }
    },
    getWorkgroups(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkgroups', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workgroups = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkareas(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroupid', this.selctedworkgroup)
      this.axios.post('usermanagement/getWorkareas', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workareas = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getFreeDays(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('praxisId', this.config.organizerId)
      fD.append('date', this.renderDateToPhp(this.weekDates.date))
      this.axios.post('praxisplaner/getFreeDays', fD)
          .then(r => {
            if(r.status === 250) {
              _this.freeDays = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    deleteSlot(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('slotId', this.slotId)
      this.axios.post('praxisplaner/deleteSlot', fD)
          .then(r => {
            if(r.status === 250) {
              this.selectModal = false
              this.getSlots()

            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getSickReports(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('date', this.renderDateToPhp(this.weekDates.date))
      this.axios.post('sickreport/getSickReports', fD)
          .then(r => {
            if(r.status === 250) {
              r.data.map(sI=>{
                this.sickReports.push(sI.userId)
              })
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    checkIfUserSick(userId){
      if(this.sickReports.includes(userId)){
        this.$emit('sick', true)
        return 'sick'
      }
      return 'active'
    },
    checkIfUserActive(userId){
      if(this.activeUsers.includes(userId)){
        return true
      }
      return false
    },

    renderDateToPhp(date){
      const substrings = date.split('.')
      const finalstring = substrings[2]+'-'+substrings[1]+'-'+substrings[0]
      return finalstring
    },
    renderHourline(number){
      return 'left:' + ((100 / 24) * number) + '%'
    }


  },
}
</script>

<style scoped>
.planerMenu .bgcolor-lightblue, #slotSettingsMenu .bgcolor-lightblue{
  transition: 0.3s;
}
  .planerMenu .bgcolor-lightblue:hover , #slotSettingsMenu .bgcolor-lightblue:hover{
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    .mobileSlot{
      grid-column: 1 / 49 !important;

    }
  }
  .slot{
    margin-bottom: 4px;
    transition: 0.3s;
  }
  .slot:hover,
  .slot.active, slot:active{
    background-color: var(--theme-diagnostika-primary);
    color: var(--white);
  }
  .slot.sick{
    background-color: var(--red);
    color: var(--white);
  }
  .slot:not(.active), slot:not(.active):active{
    padding: 13px 0;
  }
  .slot img{
    width: 32px;
    vertical-align: -10px;
    margin-right: 8px;
  }
  .search{
    padding: 8px;
    border-radius: 8px;
    background-color: #E3EEFB75;
    width: 100%;
    border: none;
  }
  .user:hover{
    background-color: #E3EEFB75;
    transform: scale(1.02);
  }
  .user{
    border-radius: 8px;
    background-color: #E3EEFB50;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 8px;
    transition: 0.3s;
  }
  .user p{
    margin-bottom: 4px;
    color: rgba(0,0,0,0.6);
  }
.hourline{
  height: 100%;
  width: 1px;
  background-color: #aaaaaa33;
  position: absolute;
  z-index: 1;
}
  .hourline2{
    height: 100%;
    width: 1px;
    background-color: #aaaaaa33;
    z-index: 1;
    position: absolute;
  }
  .svgRed{
    filter: invert(31%) sepia(92%) saturate(6383%) hue-rotate(355deg) brightness(91%) contrast(131%);
  }
.opacityZero{
  opacity: 0;
}

</style>