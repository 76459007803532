<template>
  <div>
    <main id="createUser">
      <div class="content-1400">
        <div class="pad-16">
          <h2 style="font-size: 1.2em;" class="color-primary">
            Neuen Benutzer anlegen
          </h2>
          <h1>Benutzerverwaltung</h1>
          <div class="pad-4" />
          <form method="post" @submit.prevent="createUser()">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-4 row-sp-12">
                <div class="pad-4">
                  <div class="bgcolor-white pad-24 br-10 eventDataForm">
                    <div id="uploadBox">
                      <img :src="avatar" class="image width-150 circular bgcolor-lightblue">
                      <div class="pad-16">
                        <button type="button" class="button primary" @click="avatarModal = true">
                          Bild hochladen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-sd-8 row-sp-12">
                <div class="pad-4">
                  <div class="bgcolor-white pad-16 br-10 eventDataForm">
                    <h3 style="padding-left: 4px;">
                      Angaben zur Person
                    </h3>
                    <div class="pad-8" />
                    <div class="grid-12">
                      <div class="col-sp-12 col-sd-2 row-sp-12 pad-4">
                        <div class="field">
                          <label>Title</label>
                          <select v-model="title" name="title">
                            <option tabindex="-1" selected value="">
                              Titel auswählen
                            </option>
                            <option value="Dr.">
                              Dr.
                            </option>
                            <option value="Dr. med.">
                              Dr. med.
                            </option>
                            <option value="Prof.">
                              Prof.
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-5 row-sp-12 pad-4">
                        <div class="field">
                          <label>Vorname*</label>
                          <input type="text" placeholder="Vorname" name="firstname" v-model="firstname" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-5 row-sp-12 pad-4">
                        <div class="field">
                          <label>Nachname*</label>
                          <input type="text" placeholder="Nachname" name="lastname" v-model="lastname" required>
                        </div>
                      </div>
                      <div class="col-sp-10 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Geburtsdatum*</label>
                          <input type="date" name="birthday" v-model="birthday" required>
                        </div>
                      </div>
                      <div class="col-sp-2 col-sd-1 row-sp-12">
                        <div class="pad-16" />
                        <button class="button lightblue" type="button" style="padding: 12px;" title="Information" @click="ageInfoModal = true;">
                          <svg style="vertical-align: middle;" width="24px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" fill="#1495e6" class="fill-000000" /></svg>
                        </button>
                      </div>
                      <div class="col-sp-12 col-sd-5 row-sp-12 pad-4">
                        <div class="field">
                          <label>Geschlecht*</label>
                          <select v-model="gender" name="gender" required>
                            <option tabindex="-1" selected disabled>
                              Geschlecht auswählen
                            </option>
                            <option value="male">
                              Männlich
                            </option>
                            <option value="female">
                              Weiblich
                            </option>
                            <option value="divers">
                              Divers
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>E-Mail-Adresse*</label>
                          <input type="email" placeholder="E-Mail-Adresse" name="email" v-model="email" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Telefonnummer</label>
                          <input type="tel" placeholder="Telefonnummer" name="phone" v-model="phone">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-sd-12 row-sp-12">
                <div class="pad-8" />
                <div class="pad-4">
                  <div class="bgcolor-white pad-16 br-10 eventDataForm">
                    <h3 style="padding-left: 4px;">
                      Angaben zur Beschäftigung
                    </h3>
                    <div class="pad-8" />
                    <div v-if="selectedworktype == 4">
                      <div class="bgcolor-lightblue color-blue br-10 pad-16">
                        <svg style="vertical-align: -6px;" width="24px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" fill="#1495e6" class="fill-000000" /></svg>
                        Bitte beachten Sie, dass Werkstudent*innen maximal 20 Stunden pro Woche arbeiten dürfen.
                        In den Semesterferien liegt die Grenze bei 40 Stunden pro Woche.
                      </div>
                      <div class="pad-4" />
                    </div>
                    <div class="grid-12">
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Art der Beschäftigung*</label>
                          <select v-model="selectedworktype" name="selectedworktype" required>
                            <option tabindex="-1" selected disabled>
                              Bitte auswählen
                            </option>
                            <option v-for="worktype in worktypes" :key="worktype.id" :value="worktype.id">
                              {{ worktype.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Wöchentliche Arbeitszeit*</label>
                          <input type="number" v-model="weeklytime" placeholder="Wöchentliche Arbeitszeit" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Beschäftigungsbeginn*</label>
                          <input type="date" v-model="jobstart" required>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Beschäftigungsende</label>
                          <input type="date" v-model="jobend">
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Gehalt / Lohn pro Stunde (in €)</label>
                          <input type="number" v-model="pay" placeholder="14,00€">
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Urlaubstage (im Jahr)</label>
                          <input type="number" v-model="vacationdays" placeholder="Urlaubstage">
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Berufsgruppe*</label>
                          <select v-model="selctedworkgroup" name="selctedworkgroup" required @change="getWorkareas()">
                            <option tabindex="-1" selected disabled>
                              Bitte auswählen
                            </option>
                            <option v-for="workgroup in workgroups" :key="workgroup.id" :value="workgroup.id">
                              {{ workgroup.title }}
                            </option>
                          </select>
                          <div class="t-right mar-top-4">
                            <router-link to="/praxisverwaltung" class="color-blue">
                              Berufsgruppen bearbeiten
                            </router-link>
                          </div>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Tätigkeitsbereiche (Mehrfachauswahl möglich)*</label>
                          <div v-if="selctedworkgroup" style="border: solid 1px rgba(0,0,0,0.1);border-radius: 10px" class="pad-12">
                            <div v-for="workarea in workareas" :key="workarea.id" @click="selectWorkAreas( workarea.id,event)">
                              {{ workarea.title }}
                            </div>
                          </div>
                          <p v-else class="t-center pad-24">
                            <span class="label">Bitte wähle zuerst eine Berufsgruppe</span>
                          </p>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Rechtegruppe*</label>
                          <select v-model="role" name="role" required>
                            <option tabindex="-1" selected disabled>
                              Bitte auswählen
                            </option>
                            <option value="2">
                              Mitarbeiter*in
                            </option>
                            <option value="3">
                              Schichtplaner*in
                            </option>
                            <option value="6">
                              Personalleiter*in
                            </option>
                            <option value="1">
                              Administrator*in
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Tätig in folgenden Praxen (Mehrfachauswahl möglich)*</label>
                          <select v-model="selectedworkplaces" name="selectedworkplaces" multiple required>
                            <option v-for="workplace in workplaces" :key="workplace.id" :value="workplace.id">
                              {{ workplace.organizerName }}
                            </option>
                          </select>
                        </div>
                        <div class="t-right mar-top-4">
                          <router-link to="/praxisverwaltung" class="color-blue">
                            Praxen bearbeiten
                          </router-link>
                        </div>
                      </div>
                      <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                        <div class="field">
                          <label>Akutelle Anzahl an Plus/Minus-Arbeitsstunden (optional)</label>
                          <input type="number" v-model="currentworkhours" placeholder="Plus/Minus-Arbeitsstunden">
                        </div>
                      </div>
                    </div>
                    <div class="pad-16" />
                    <div class="pad-16" />
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>Arbeitstage*</label><br>
                        Mo. <input type="checkbox" v-model="workingDays.monday">
                        Di. <input type="checkbox" v-model="workingDays.tuesday">
                        Mi. <input type="checkbox" v-model="workingDays.wednesday">
                        Do. <input type="checkbox" v-model="workingDays.thursday">
                        Fr. <input type="checkbox" v-model="workingDays.friday">
                        Sa. <input type="checkbox" v-model="workingDays.saturday">
                        So. <input type="checkbox" v-model="workingDays.sunday">
                      </div>
                    </div>
                  </div>
                  <div class="pad-16" />
                  <!-- Präferenzen-->
                  <!--
                  <div class="bgcolor-white pad-16 br-10 eventDataForm">
                    <h3 style="padding-left: 4px;">
                      Angaben zu Präferenzen
                    </h3>
                    <div class="pad-8" />
                    <div class="">
                      <div id="weekdayPreferences" style="display: flex;justify-content: center;flex-wrap: wrap;gap: 12px">
                        <div>
                          <h4 style="text-align: center">
                            Montag
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('monday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('monday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('monday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                        <div>
                          <h4 style="text-align: center">
                            Dienstag
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('tuesday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('tuesday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('tuesday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                        <div>
                          <h4 style="text-align: center">
                            Mittwoch
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('wednesday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('wednesday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('wednesday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                        <div>
                          <h4 style="text-align: center">
                            Donnerstag
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('thursday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('thursday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('thursday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                        <div>
                          <h4 style="text-align: center">
                            Freitag
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('friday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('friday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('friday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                        <div>
                          <h4 style="text-align: center">
                            Samstag
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('saturday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('saturday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('saturday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                        <div>
                          <h4 style="text-align: center">
                            Sonntag
                          </h4>
                          <div class="slotPreference" @click="preferenceWorktime('sunday', 1)" style="border-top-left-radius: 16px;border-top-right-radius: 16px">
                            Frühschicht
                            06:00 - 13:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('sunday',2)">
                            Spätschicht
                            13:00 - 22:00
                          </div>
                          <div class="slotPreference" @click="preferenceWorktime('sunday',3)" style="border-bottom-left-radius: 16px;border-bottom-right-radius: 16px">
                            Nachtschicht
                            22:00 - 06:00
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pad-16" />
                  </div>
                  -->
                  <div class="t-right">
                    <div class="pad-16" />
                    <button type="submit" class="button large primary">
                      Benutzer erstellen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
    <transition name="modal" id="ageInfoModal">
      <div class="vueModal" v-if="ageInfoModal">
        <div class="vueModalOuter" @click="ageInfoModal = false;" />
        <div class="vueModalInner tiny bottom" style="background-color: #E2F1FF!important;">
          <div class="t-center pad-8">
            <svg style="vertical-align: middle;" width="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" fill="#1495e6" class="fill-000000" /></svg>
            <div class="pad-16" />
            <p class="color-blue">
              Das Geburtsdatum wird benötigt, um ggf. Bestimmungen des Arbeitsschutzgesetzes bei der Arbeitszeitplanung berücksichtigen zu können.
            </p>
            <div class="pad-16" />
            <button @click="ageInfoModal = false;" class="button primary fluid">
              Okay
            </button>
          </div>
        </div>
      </div>
    </transition>
    <VueModal :show="avatarModal" @close="avatarModal = false" approve="Weiter" @approve="avatarModal = false">
      <cropper-upload :init="true" :ratio="1" @cropped="c => avatarBlob = c" />
    </VueModal>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";

export default {
  name: "UserCreate",
  title: 'Benutzer erstellen',
  components: {
    CropperUpload
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ageInfoModal: false,
      gender: '',
      title: '',
      firstname: '',
      lastname: '',
      birthday: '',
      selectedworktype: '',
      selctedworkgroup: '',
      workgroups: [],
      email: '',
      phone: null,
      weeklytime: null,
      jobstart: null,
      jobend: null,
      pay: null,
      role: null,
      vacationdays: null,
      workareas: [],
      worktypes: [],
      selectedworkareas: [],
      selectedworkplaces: [],
      workplaces: [],
      currentworkhours: 0,
      avatarModal: false,
      avatarBlob: false,
      loadingAvatar: false,
      workingDays: {},

      monday:[],
      tuesday:[],
      wednesday:[],
      thursday:[],
      friday:[],
      saturday:[],
      sunday:[],
    }
  },
  computed:{
    avatar(){
      return this.avatarBlob ?  URL.createObjectURL(this.avatarBlob) : '/assets/images/users/default.svg'
    }
  },
  mounted() {
    this.config.loading = false
    this.getWorkplaces()
    this.getWorkgroups()
    this.getWorktypes()
  },
  methods: {
    getWorkplaces(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkplaces', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workplaces = r.data
              console.log(r.data)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkgroups(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkgroups', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workgroups = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorktypes(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorktypes', fD)
          .then(r => {
            if(r.status === 250) {
              _this.worktypes = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkareas(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroupid', this.selctedworkgroup)
      this.axios.post('usermanagement/getWorkareas', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workareas = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    createUser(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('gender', this.gender)
      fD.append('title', this.title)
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('birthday', this.birthday)
      fD.append('selectedworktype', this.selectedworktype)
      fD.append('selctedworkgroup', this.selctedworkgroup)
      fD.append('email', this.email)
      fD.append('phone', this.phone)
      fD.append('weeklytime', this.weeklytime)
      fD.append('jobstart', this.jobstart)
      fD.append('jobend', this.jobend)
      fD.append('pay', this.pay)
      fD.append('vacationdays', this.vacationdays)
      fD.append('selectedworkareas', this.selectedworkareas)
      fD.append('selectedworkplaces', this.selectedworkplaces)
      fD.append('avatar', this.avatarBlob)
      fD.append('currentworkhours', this.currentworkhours)
      fD.append('role', this.role)
      fD.append('workingDays', JSON.stringify(this.workingDays))

      this.axios.post('usermanagement/createUser', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.$router.push({name: 'UserEdit', params: { id: response.data }});
            }
          })

    },
    selectWorkAreas(id){
      event.target.classList.toggle("selected")
        if (this.selectedworkareas.includes(id)){
          const indexOfId = this.selectedworkareas.indexOf(id)
          this.selectedworkareas.splice(indexOfId,1)
        }
        else {
          this.selectedworkareas.push(id)
        }
    },
    preferenceWorktime(day, slot){
      switch (day){
        case 'monday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.monday.includes(slot)){
            const indexOfId = this.monday.indexOf(slot)
            this.monday.splice(indexOfId,1)
            return
          }
          else {
            this.monday.push(slot)
            return;
          }
          break
        }
        case 'tuesday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.tuesday.includes(slot)){
            const indexOfId = this.tuesday.indexOf(slot)
            this.tuesday.splice(indexOfId,1)
            return
          }
          else {
            this.tuesday.push(slot)
            return;
          }
          break
        }
        case 'wednesday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.wednesday.includes(slot)){
            const indexOfId = this.wednesday.indexOf(slot)
            this.wednesday.splice(indexOfId,1)
            return
          }
          else {
            this.wednesday.push(slot)
            return;
          }
          break
        }
        case 'thursday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.thursday.includes(slot)){
            const indexOfId = this.thursday.indexOf(slot)
            this.thursday.splice(indexOfId,1)
            return
          }
          else {
            this.thursday.push(slot)
            return;
          }
          break
        }
        case 'friday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.friday.includes(slot)){
            const indexOfId = this.friday.indexOf(slot)
            this.friday.splice(indexOfId,1)
            return
          }
          else {
            this.friday.push(slot)
            return;
          }
          break
        }
        case 'saturday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.saturday.includes(slot)){
            const indexOfId = this.saturday.indexOf(slot)
            this.saturday.splice(indexOfId,1)
            return
          }
          else {
            this.saturday.push(slot)
            return;
          }
          break
        }
        case 'sunday':{
          event.preventDefault()
          event.target.classList.toggle("selectedPreference")
          if (this.sunday.includes(slot)){
            const indexOfId = this.sunday.indexOf(slot)
            this.sunday.splice(indexOfId,1)
            return
          }
          else {
            this.sunday.push(slot)
            return;
          }
          break
        }
      }
    }
  },
}
</script>

<style scoped>
#createUser h1{
  font-size: 2em;
}
#createUser h3{
  font-size: 1.6em;
}

#uploadBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 32px;
}
.eventDataForm .field input,
.eventDataForm .field textarea,
.eventDataForm .field select{
  display: block;
  padding: 12px;
  width: 100%;
  max-width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  box-sizing: border-box;
}
.eventDataForm .field input[type=checkbox]{
  width: auto;
  display: inline;
  margin-right: 2px;
}
.eventDataForm .field label{
  margin-top: 8px;
  display: inline-block;
  color: rgba(0,0,0,0.6);
}
.selected{
  background-color: #015ecc;
  color: white;
}
.slotPreference{
  background-color: #eeeeee;
  padding: 12px;
  text-align: center;
  cursor: pointer;
}
.selectedPreference{
  background-color: #015ecc;
  color: white;
}
</style>