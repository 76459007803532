<template>
  <h6 class="t-center pad-16">- Schichten -</h6>
  <div v-for="element in templateElements" :key="element.id" @change="pushToTemplateArray">
    <div class="br-10 pad-8 mar-bottom-8 bgcolor-lightblue" style="border: 2px solid var(--theme-diagnostika-primary-color);">
      <div class="grid-12">
        <div class="col-sp-6 row-sp-12">
          <div class="field">
            <label>Schichtbeginn</label>
            <input type="time" v-model="element.slotStart" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
          </div>
        </div>
        <div class="col-sp-6 row-sp-12">
          <div class="field">
            <label>Schichtende</label>
            <input type="time" v-model="element.slotEnd" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
          </div>
        </div>
        <div class="col-sp-6 row-sp-12">
          <div class="field">
            <label>Berufsgruppe</label>
            <select style="border-top-right-radius: 0; border-bottom-right-radius: 0;" v-model="element.workgroupId" @change="element.workareaId = '';showAreas=false;$nextTick(()=>{showAreas=true})">
              <option v-for="workgroup in workgroups" :key="workgroup.id" :value="workgroup.id">{{workgroup.title}}</option>
            </select>
          </div>
        </div>
        <div class="col-sp-6 row-sp-12">
          <div class="field">
            <label>Arbeitsbereich</label>
            <WorkareaTemplateEdit  style="border-top-left-radius: 0; border-bottom-left-radius: 0;" v-if="showAreas" :workgroupId="element.workgroupId" :editWork="element.workareaId" @newWorkarea="(value)=>{element.workareaId = value; pushToTemplateArray}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pad-16"></div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

import WorkareaTemplateEdit from "@/components/praxisplaner/dayPlanModels/workareaTemplateEdit";

export default {
  name: "editTemplateElementSlot",
  components:{
    WorkareaTemplateEdit
  },
  props:['templateId'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      templateElements:'',
      showAreas:true,
      workgroups:[],
    }
  },
  methods:{
    getTemplateElements(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('parentId', this.templateId)
      this.axios.post('praxisplaner/getTemplateElements', fD)
          .then((response)=>{
            if (response.status === 250){
              this.templateElements= response.data
            }
          })
    },
    getWorkgroups(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkgroups', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workgroups = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkareas(workgroupId){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroupid', workgroupId)
      this.axios.post('usermanagement/getWorkareas', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workareas = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    consi(value){
      console.log(value)
    },
    pushToTemplateArray(){
      this.$emit('editedTemplateElements', this.templateElements )
    }
  },
  mounted() {
    this.getTemplateElements()
    this.getWorkgroups()
  },
}
</script>

<style scoped>
.field{
  margin-bottom: 8px;
}
.field input,
.field select,
.field textarea{
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  width: 100%;
}
.field input:focus{
  outline: none;
}
</style>