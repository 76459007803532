<template>
  <div style="height: 100vh;width: 100vw; display: flex;justify-content: center;align-items: center;flex-direction: column">
    <main>
      <div class="content-1200">
        <div class="grid-12">
          <div class="col-sp-12 col-sd-4 row-sp-12" id="contactInformations">
            <div class="contactInformationBox gardient-diagnostika">
              <div class="grid-12" style=" column-gap: 10px;">
                <div class="col-sp-2 col-sd-3 row-sp-12">
                  <h6>✍️</h6>
                </div>
                <div class="col-sp-10 col-sd-9 row-sp-12">
                  <h3>Schreib uns</h3>
                  <p>Wir beantworten Ihnen gerne alle Fragen</p>
                  <a href="mailto:diagnostika@20north.de">diagnostika@20north.de</a>
                </div>
              </div>
            </div>
            <div class="pad-12"></div>
            <div class="contactInformationBox gardient-diagnostika">
              <div class="grid-12" style=" column-gap: 10px;">
                <div class="col-sp-2 col-sd-3 row-sp-12">
                  <h6>📞</h6>
                </div>
                <div class="col-sp-10 col-sd-9 row-sp-12">
                  <h3>Telefon</h3>
                  <p>Mo - Do von 10 - 16 Uhr</p>
                  <span>+49 (0) 4131 9921080</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-12" id="contactForm">
            <form @submit.prevent="sendEmail()" ref="form">
              <h2>Nächster Schritt:<br>
                Digitalisierung</h2>
              <div class="contactFunnelFirst" v-if="step == 1">
                                <span class="contactStep">
                                    Frage 1 von 3
                                </span>
                <h5>Für welchen Bereich möchten Sie Diagnostika einsetzen?</h5>
                <div class="grid-12" style="align-self: stretch; column-gap: 12px;">
                  <div class="col-sp-12 col-sd-6 row-sp-12">
                    <div class="contactFunnelSelectBox" :class="selectArztpraxen ? 'active' : ''" @click="selectArztpraxen = !selectArztpraxen">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>🩺️</h4>
                      <h5>Arztpraxen</h5>
                      <p>Effiziente Schichtplanung und KI-basierte Mitarbeiteranalyse für eine reibungslose Patientenversorgung.</p>
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-6 row-sp-12" >
                    <div class="contactFunnelSelectBox" :class="selectApotheke ? 'active' : ''" @click="selectApotheke = !selectApotheke">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>⚕️</h4>
                      <h5>Apotheke</h5>
                      <p>Automatisierte Arbeitszeit-Erfassung und Schichtplanung für eine verbesserte Mitarbeiterkommunikation.</p>
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-6 row-sp-12" >
                    <div class="contactFunnelSelectBox" :class="selectPflegedienst ? 'active' : ''" @click="selectPflegedienst = !selectPflegedienst">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>🩹️</h4>
                      <h5>Pflegedienst</h5>
                      <p>Intelligente Tourenplanung und mobile App für eine effektivere Pflegeorganisation.</p>
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-6 row-sp-12" >
                    <div class="contactFunnelSelectBox" :class="selectGastronomie ? 'active' : ''" @click="selectGastronomie = !selectGastronomie">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>🍴</h4>
                      <h5>Gastronomie</h5>
                      <p>Einfache Planung von Mitarbeiter-Schichten und Verbesserung der Arbeitsabläufe im Restaurantbetrieb.</p>
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-6 row-sp-12" >
                    <div class="contactFunnelSelectBox" :class="selectEinzelhandel ? 'active' : ''" @click="selectEinzelhandel = !selectEinzelhandel">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>️🛒</h4>
                      <h5>Einzelhandel</h5>
                      <p>Optimierte Personalplanung und bessere Teamkommunikation für ein erfolgreiches Geschäft.</p>
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-6 row-sp-12">
                    <div class="contactFunnelSelectBox" :class="selectSozialeEinrichtungen ? 'active' : ''" @click="selectSozialeEinrichtungen = !selectSozialeEinrichtungen">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>🙌🏼</h4>
                      <h5>Soziale Einrichtungen</h5>
                      <p>Zeit- und Ressourcenersparnis durch eine smarte Schichtplanung und Mitarbeiteranalyse.</p>
                    </div>
                  </div>
                  <div class="col-sp-12 col-sd-12 row-sp-12">
                    <div class="contactFunnelSelectBox conactOther" :class="selectSonstiges ? 'active' : ''" @click="selectSonstiges = !selectSonstiges">
                      <div class="contactFunnelCheckdisable">
                        <svg width="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#0173fe" class="fill-232323"></path></svg>
                      </div>
                      <h4>👀</h4>
                      <h5>Sonstiges</h5>
                    </div>
                  </div>
                </div>
                <button @click="step = 2" class="funnelNext" v-if="selectArztpraxen ||selectApotheke || selectPflegedienst || selectGastronomie || selectEinzelhandel || selectSozialeEinrichtungen || selectSonstiges">Nächster</button>
              </div>
              <div class="contactFunnelSecond" v-if="step == 2">
                                <span class="contactStep">
                                    Frage 2 von 3
                                </span>
                <h5>Gibt es weitere Informationen, die Sie uns mitteilen möchten?</h5>
                <div class="grid-12">
                  <div class="col-sp-12 row-sp-12">
                    <textarea id="message" name="message" placeholder="Diagnostika ist für unser Unternehmen..." v-model="message"></textarea>
                  </div>
                </div>
                <button class="funnelNext" @click="step = 3">Nächster</button>
              </div>
              <div class="contactFunnelfinal" v-if="step == 3">
                                <span class="contactStep">
                                    Frage 3 von 3
                                </span>
                <h5>Wie können wir Sie am besten erreichen?</h5>
                <div class="grid-12">
                  <div class="col-sp-12 row-sp-12">
                    <div class="flex FunnelContactData">
                      <label for="name">Vor- und Nachname</label>
                      <input type="text" id="name" name="name" required v-model="name">
                    </div>

                    <div class="flex FunnelContactData">
                      <label for="company">Name der Gesellschaft</label>
                      <input type="text" id="company" name="company" v-model="company">
                    </div>

                    <div class="flex FunnelContactData">
                      <label for="phone">Telefonnummer</label>
                      <input type="tel" id="phone" name="phone" v-model="phone">
                    </div>

                    <div class="flex FunnelContactData">
                      <label for="email">E-Mail-Adresse</label>
                      <input type="email" id="email" name="email" v-model="email" required>
                    </div>
                    <input style="display: none;" id="message" name="message" v-model="message">
                    <input style="display: none;" id="selectArztpraxen" name="selectArztpraxen" v-model="selectArztpraxen">
                    <input style="display: none;" id="selectApotheke" name="selectApotheke" v-model="selectApotheke">
                    <input style="display: none;" id="selectPflegedienst" name="selectPflegedienst" v-model="selectPflegedienst">
                    <input style="display: none;" id="selectGastronomie" name="selectGastronomie" v-model="selectGastronomie">
                    <input style="display: none;" id="selectEinzelhandel" name="selectEinzelhandel" v-model="selectEinzelhandel">
                    <input style="display: none;" id="selectSozialeEinrichtungen" name="selectSozialeEinrichtungen" v-model="selectSozialeEinrichtungen">
                    <input style="display: none;" id="selectSonstiges" name="selectSonstiges" v-model="selectSonstiges">
                  </div>
                  <div class="col-sp-12 row-sp-12 privacyBox">
                    <input type="checkbox" id="privacyCheck" name="privacyCheck" required>
                    <label class="mar-left-4" for="privacyCheck">Hier mit bestätige ich die <a href="/privacy">Datenschutzerklärung</a> gelesen und bin mit dieser einverstanden.</label>
                  </div>
                </div>
                <button class="funnelSubmit">Abschicken</button>
              </div>
              <div v-if="step == 4">
                <div class="t-center mar-top-32 pad-24 bgcolor-white br-10" style="border: 2px solid var(--theme-diagnostika-primary);">
                  <picture>
                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f389/512.webp" type="image/webp">
                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f389/512.gif" alt="🎉" width="80" height="80">
                  </picture>
                  <h4>Wir haben deine Nachricht erhalten</h4>
                  <h5 class="color-primary f-normal">und werden uns in kürze bei die melden</h5>
                  <div class="mar-top-32">
                    <a href="index.html" class="button primary">Zurück zur Startseite</a>
                  </div>
                </div>
              </div>
              <div class="pad-80"></div>
            </form>
          </div>

        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "register",
  data(){
    return{
      step : 1,
      selectArztpraxen: false,
      selectApotheke: false,
      selectPflegedienst: false,
      selectGastronomie: false,
      selectEinzelhandel: false,
      selectSozialeEinrichtungen: false,
      selectSonstiges: false,
      message: '',
      name: '',
      company: '',
      phone: '',
      email: ''
    }
  },
  methods:{

  }
}
</script>

<style scoped>
.gardient-diagnostika {
  background: rgb(219,235,254);
  background: linear-gradient(164deg, rgba(219,235,254,1) 0%, rgba(229,238,210,1) 100%);
}
#contactInformations{
  padding: 0 16px;
  margin-top: 40px;
}

#contactInformations .contactInformationBox{
  border-radius: 12px;
  padding: 16px;
}

#contactInformations .contactInformationBox h6{
  font-size: 40px;
  text-align: center;
}

#contactInformations .contactInformationBox h3{
  font-size: 1.2em;
}

#contactInformations .contactInformationBox p{
  font-size: 1em;
  padding: 4px 0;
  color: #00000090;
}

#contactInformations .contactInformationBox a{
  color: black;
  text-decoration: none;
  font-weight: 600;
}

#contactForm{
  display: flex;
  justify-content: center;
  padding: 0 16px;
  margin-top: 40px;
}

#contactForm h2{
  text-align: center;
}

#contactForm  .contactStep{
  text-align: center;
  font-size: 0.9em;
  display: block;
  padding-top: 8px;
}

#contactForm .contactFunnelFirst h5, #contactForm .contactFunnelSecond h5, #contactForm .contactFunnelfinal h5{
  text-align: center;
  font-size: 1.2em;
  padding-top: 18px;
  padding-bottom: 20px;
}

#contactForm .contactFunnelSelectBox{
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  margin: 8px 0;
  padding: 16px;
  cursor: pointer;
  position: relative;
}

#contactForm .contactFunnelSelectBox.active{ /*aktiviert sich, wenn die Box ausgewÃ¤hlt wurde */
  outline: 2px solid var(--theme-diagnostika-primary) !important;
  border: none !important;
}
#contactForm .contactFunnelSelectBox.active .contactFunnelCheckdisable{
  display: inline;
}
#contactForm .contactFunnelSelectBox .contactFunnelCheckdisable{
  position: absolute;
  right: 12px;
  top: 12px;
  display: none;
}

#contactForm .contactFunnelSelectBox h4{
  font-size: 40px;
}

#contactForm .contactFunnelSelectBox h5{
  text-align: left;
  padding-top: 6px;
}

#contactForm .funnelNext, #contactForm .funnelSubmit{
  width: 100%;
  background-color: var(--theme-diagnostika-primary);
  border: none;
  font-size: 1em;
  border-radius: 8px;
  margin: 20px 0 20px 0;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  display: inline-block;
  padding: 10px 16px;
}

#contactForm .contactFunnelSecond textarea{
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  width: 100%;
  margin-top: 12px;
  height: 200px;
  padding: 16px;
  background-color: #F5F8FD;
}

#contactForm .FunnelContactData{
  display: flex;
  align-items: center;
  column-gap: 100%;
  flex-wrap: wrap;
  row-gap: 6px;
  margin: 12px;
}

#contactForm .FunnelContactData label{
  margin-bottom: 4px;
}
#contactForm .FunnelContactData input{
  padding: 12px;
  background-color: #F5F8FD;
  border: none;
  border-radius: 16px;
  width: 100%;
}

#contactForm .privacyBox{
  margin: 12px;
}
</style>