<template>
  <div class="vueModal" id="selectNewSlotModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="handleClosing" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div id="slotSettingsMenu">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="handleClosing">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div class="t-center">
          <h4>Template Speichern</h4>
          <h6 class="f-normal">
            Schichten: {{ slotItems.length }}
          </h6>
        </div>
        <div class="field">
          <label for="templateNameInput">Template Name:</label>
          <input v-model="templateSaveName" placeholder="Template Name" type="text" id="templateNameInput" class="inputField" style="width: 100%">
        </div>
        <div class="field">
          <textarea class="inputField" v-model="templateSaveDescription" placeholder="Zusatz Infos" rows="4" />
        </div>
        <button class="primary fluid button" @click="saveAsTemplate">
          Speichern
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "CreateSlotTemplate",
  props:["slotItems","slotId"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      templateSaveDescription:'',
      templateSaveName: '',
    }
  },
  methods:{
    handleClosing(){
      this.$emit("closingCreateSlotTemplate", false)
    },
    saveAsTemplate(){
      console.log("TemplateName: ", this.templateSaveName)
      console.log("TemplateBeschreibung: ", this.templateSaveDescription)
      console.log("Items:", this.slotItems.length)

      const fD = new FormData
      fD.append('sessionID', this.auth.sessionID)
      fD.append('praxisId', this.config.organizerId)
      fD.append('title', this.templateSaveName)
      fD.append('description', this.templateSaveDescription)
      this.axios.post('praxisplaner/setNewTemplate', fD)
          .then(r=>{
            if (r.status === 250){
              const templateId = r.data
              console.log(r.data)
              this.slotItems.map(item =>{
                fD.append('sessionID', this.auth.sessionID)
                fD.append('parentId', templateId)
                fD.append("workareaId", item.workareasId)
                fD.append("workgroupId", item.workgroupId)
                fD.append("slotStart", item.slotStart)
                fD.append("slotEnd", item.slotEnd)
                this.axios.post('praxisplaner/setNewTemplateElements', fD)
                    .then(r=>{
                      if (r.status === 250){
                        this.handleClosing()
                      }
                    })
                    .catch(e=>{
                      console.log(e)
                    })
              })
            }})
          .catch(e=>{
            console.log(e)
          })

    },

  }

}
</script>

<style scoped>
textarea{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.field{
  margin-bottom: 8px;
}
.field input,
.field textarea{
  max-width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
}

</style>