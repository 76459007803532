<template>
  <div class="grid-12 pad-16">
    <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
      <h3 style="font-size: 24px">Berufsgruppen</h3>
      <div v-for="group in workgroups" :key="group.id" style="background-color: #00000005" class="br-10 pad-16 mar-bottom-8">
        <h6 class="pad-8"
            style="cursor: pointer;"
            :class="activeWorkgroup === group.id? 'color-primary':''"
            @click="activeWorkgroup = group.id;activeGroupTitle = group.title;getWorkAreas()"
        >
          {{group.title}}
        </h6>
        <div v-if="activeWorkgroup === group.id">
          <button class="button tiny mar-right-12" title="Bearbeiten" @click="editWorkGroupModal=true; editTitle=group.title">Bearbeiten</button>
          <button class="button tiny" title="Löschen" @click="deleteWorkGroupModal=true;deleteId=group.id;deleteTitle=group.title">Löschen</button>
        </div>
      </div>
      <div v-if="workgroups.length == 0">
        <p class="color-grey t-center pad-16">
          Es wurden noch keine Berufsgruppen angelegt.
        </p>
      </div>
      <div class="col-sp-12 row-sp-12 mar-top-8">
        <button class="button fluid primary" @click="newWorkGroupModal=true">Berufsgruppe erstellen</button>
      </div>
    </div>
<!--    ArbeitsBereiche-->
    <div class="col-sp-12 col-sd-6 row-sp-12 pad-4" v-if="activeWorkgroup != 0">
      <h3 style="font-size: 20px">{{activeGroupTitle}}: Arbeitsbereiche</h3>
      <div v-for="area in workareas" :key="area.id" style="background-color: #00000005" class="br-10 pad-16 mar-bottom-8">
        <p class="pad-8">{{area.title}}</p>
        <div>
          <button class="button tiny mar-right-12" title="Bearbeiten" @click="editWorkAreaModal=true;editareaId=area.id; editareaTitle=area.title">Bearbeiten</button>
          <button class="button tiny" title="Löschen" @click="deleteWorkAreaModal=true;deleteareaId=area.id;deleteareaTitle=area.title">Löschen</button>
        </div>
      </div>
      <div v-if="workareas.length == 0">
        <p class="color-grey t-center pad-16">
          Es wurden noch keine Arbeitsbereiche für die Berufsgruppe {{activeGroupTitle}} angelegt.
        </p>
      </div>
      <div class="col-sp-12 row-sp-12 t-center mar-top-8">
        <button class="button primary fluid" @click="newWorkAreaModal=true">Arbeitsbereich anlegen</button>
      </div>
    </div>
<!--    Modale-->
    <div class="vueModal" id="newWorkgroupModal" v-if="newWorkGroupModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="newWorkGroupModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="newWorkGroupModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h5 class="t-center">
            Berufsgruppe erstellen
          </h5>
          <div class="grid-12 mar-top-16 pad-bot-16">
            <div class="col-sp-12 row-sp-12">
              <div class="field">
                <label for="title">Titel</label>
                <input type="text" id="title" v-model="title" placeholder="Titel">
              </div>
            </div>
            <div class="col-sp-12 row-sp-12">
              <button class="button fluid primary" v-if="title.length > 0" @click="addNewWorkgroup()">
                Erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vueModal" id="editWorkgroupModal" v-if="editWorkGroupModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="editWorkGroupModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="editWorkGroupModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h5 class="t-center">
            Berufsgruppe Bearbeiten
          </h5>
          <div class="grid-12 mar-top-16 pad-bot-16">
            <div class="col-sp-12 row-sp-12">
              <div class="field">
                <label for="title">Titel</label>
                <input type="text" id="title" v-model="editTitle" placeholder="Titel">
              </div>
            </div>
            <div class="col-sp-12 row-sp-12">
              <button class="button fluid primary" v-if="editTitle.length > 0" @click="saveWorkgroup()">
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vueModal" id="editWorkgroupModal" v-if="deleteWorkGroupModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="deleteWorkGroupModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="deleteWorkGroupModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h5 class="t-center">
            Möchtest du die Gruppe {{deleteTitle}} löschen?
          </h5>
          <div class="mar-top-16 pad-bot-16">
            <div style="display: flex;justify-content: space-around">
                <button class="button primary" @click="deleteWorkgroup">Löschen</button>
                <button class="button" @click="deleteWorkGroupModal=false">Abbrechen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    Workarea Modale-->
    <div class="vueModal" id="newWorkgroupModal" v-if="newWorkAreaModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="newWorkAreaModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="newWorkAreaModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h5 class="t-center">
            Arbeitsbereich erstellen
          </h5>
          <div class="grid-12 mar-top-16 pad-bot-16">
            <div class="col-sp-12 row-sp-12">
              <div class="field">
                <label for="title">Titel</label>
                <input type="text" id="title" v-model="areaTitle" placeholder="Titel">
              </div>
            </div>
            <div class="col-sp-12 row-sp-12">
              <button class="button fluid primary" v-if="areaTitle.length > 0" @click="addNewWorkarea()">
                Erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vueModal" id="editWorkgroupModal" v-if="editWorkAreaModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="editWorkAreaModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="editWorkAreaModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h5 class="t-center">
            Arbeitsbereich bearbeiten
          </h5>
          <div class="grid-12 mar-top-16 pad-bot-16">
            <div class="col-sp-12 row-sp-12">
              <div class="field">
                <label for="title">Titel</label>
                <input type="text" id="title" v-model="editareaTitle" placeholder="Titel">
              </div>
            </div>
            <div class="col-sp-12 row-sp-12">
              <button class="button fluid primary" v-if="editareaTitle.length > 0" @click="saveWorkarea()">
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vueModal" id="editWorkgroupModal" v-if="deleteWorkAreaModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="deleteWorkAreaModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div style="clear: both;">
          <div style="float: right; cursor: pointer;" @click="deleteWorkAreaModal = false">
            <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
          </div>
        </div>
        <div>
          <h5 class="t-center">
            Möchtest du den Arbeitsbereich {{deleteareaTitle}} löschen?
          </h5>
          <div class="mar-top-16 pad-bot-16">
            <div style="display: flex;justify-content: space-around">
              <button class="button primary" @click="deleteWorkarea">Löschen</button>
              <button class="button" @click="deleteWorkAreaModal=false">Abbrechen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "workSettings",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      workgroups:[],
      workareas:'',
      activeWorkgroup:0,
      activeGroupTitle: '',

      newWorkGroupModal:false,
      title:'',
      editWorkGroupModal:false,
      editTitle:'',
      deleteWorkGroupModal:false,
      deleteId:'',
      deleteTitle:'',

      newWorkAreaModal:false,
      areaTitle:'',
      editWorkAreaModal:false,
      editareaTitle:'',
      editareaId:'',
      deleteWorkAreaModal:false,
      deleteareaId:'',
      deleteareaTitle:'',
    }
  },
  mounted() {
    this.getWorkGroups()
  },
  methods:{
    getWorkGroups(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getWorkgroups', fD)
          .then((response) => {
            if(response.status === 250){
              this.workgroups = response.data
              this.activeWorkgroup= response.data[0].id
              this.activeGroupTitle = response.data[0].title
              this.getWorkAreas()
            }
          })
    },
    getWorkAreas(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroup', this.activeWorkgroup)
      this.axios.post('praxisverwaltung/getWorkareas', fD)
          .then((response) => {
            if(response.status === 250){
              this.workareas = response.data
            }
            else{
              this.workareas =''
            }
          })
    },
    addNewWorkgroup(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('title', this.title)
      this.axios.post('praxisverwaltung/addWorkgroup', fD)
          .then((response) => {
            if(response.status === 250){
              this.title=''
              this.newWorkGroupModal=false
              this.getWorkGroups()
            }
          })
    },
    saveWorkgroup(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('title', this.editTitle)
      this.axios.post('praxisverwaltung/saveWorkgroup', fD)
          .then((response) => {
            if(response.status === 250){
              this.editTitle=''
              this.editWorkGroupModal=false
              this.getWorkGroups()
            }
          })
    },
    deleteWorkgroup(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.deleteId)
      this.axios.post('praxisverwaltung/deleteWorkgroup', fD)
          .then((response) => {
            if(response.status === 250){
              this.deleteTitle=''
              this.deleteWorkGroupModal=false
              this.getWorkGroups()
            }
          })
    },
    addNewWorkarea(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('title', this.areaTitle)
      fD.append('workgroup', this.activeWorkgroup)
      console.log(this.areaTitle)
      this.axios.post('praxisverwaltung/addWorkarea', fD)
          .then((response) => {
            if(response.status === 250){
              this.areaTitle=''
              this.newWorkAreaModal=false
              this.getWorkAreas()
            }
          })
    },

    saveWorkarea(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('title', this.editareaTitle)
      fD.append('id', this.editar)
      this.axios.post('praxisverwaltung/saveWorkarea', fD)
          .then((response) => {
            if(response.status === 250){
              this.editareaTitle=''
              this.editWorkAreaModal=false
              this.getWorkAreas()
            }
          })
    },
    deleteWorkarea(){
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.deleteareaId)
      this.axios.post('praxisverwaltung/deleteWorkarea', fD)
          .then((response) => {
            console.log(response,this.deleteareaId)
            if(response.status === 250){
              this.deleteareaTitle=''
              this.deleteWorkAreaModal=false
              this.getWorkAreas()
            }
          })
    },

  }
}
</script>

<style scoped>

</style>