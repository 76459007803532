<template>
  <div>
    <div class="vueModal" id="praxisModal" v-if="praxisModal" style="position: fixed">
      <div class="vueModalOuter" style="height: 100%" @click="praxisModal = false" />
      <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
        <div>
          <div style="clear: both;">
            <div style="float: right; cursor: pointer;" @click="praxisModal = false;">
              <svg enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width: 24px;"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
            </div>
          </div>
          <h5 class="t-center">
            Praxis auswählen
          </h5>
          <div style="max-height: 500px; overflow-y: scroll;" class="mar-top-16">
            <praxisItems v-for="citems in praxisItems" :praxis-items="citems" :key="citems.id" @update="getNow();getMembers();getPraxisDetails()" />
          </div>
        </div>
      </div>
    </div>
    <main id="praxisplaner">
      <div class="content-1400">
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-3 pad-8">
            <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-primary">
              Übersicht
            </h2>
            <h1 style="padding-left: 4px;">
              Schichtplaner
            </h1>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
            <article class="praxis">
              <div class="grid-12">
                <div class="col-sp-3 row-sp-12 pad-8">
                  <img :src="config.projectURL + 'assets/images/eventhoster/'+praxis.logo">
                </div>
                <div class="col-sp-9 row-sp-12">
                  <h4 class="f-bold mar-bottom-4">
                    {{ praxis.organizerName }}
                  </h4>
                  <svg style="vertical-align: 16px; margin-right: 4px; opacity: 0.5;" width="16px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" /><path d="M0 0h48v48h-48z" fill="none" /></svg>
                  <p class="color-grey" style="display: inline-block;">
                    {{ praxis.street }} {{ praxis.hnr }}<br>
                    {{ praxis.plz }} {{ praxis.city }}
                  </p>
                  <div class="pad-4" />
                  <svg style="vertical-align: -2px; margin-right: 4px; opacity: 0.5;" width="16px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title /><g data-name="1" id="_1"><path d="M348.73,450.06a198.63,198.63,0,0,1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152C46.65,146.75,56.15,99.61,86.69,69.07l8.72-8.72a42.2,42.2,0,0,1,59.62,0l50.11,50.1a42.18,42.18,0,0,1,0,59.62l-29.6,29.59c14.19,24.9,33.49,49.82,56.3,72.63s47.75,42.12,72.64,56.31L334.07,299a42.15,42.15,0,0,1,59.62,0l50.1,50.1a42.16,42.16,0,0,1,0,59.61l-8.73,8.72C413.53,439,383.73,450.06,348.73,450.06ZM125.22,78a12,12,0,0,0-8.59,3.56l-8.73,8.72c-22.87,22.87-29.55,60-18.81,104.49,11.37,47.13,40.64,96.1,82.41,137.86s90.73,71,137.87,82.41c44.5,10.74,81.61,4.06,104.48-18.81l8.72-8.72a12.16,12.16,0,0,0,0-17.19l-50.09-50.1a12.16,12.16,0,0,0-17.19,0l-37.51,37.51a15,15,0,0,1-17.5,2.72c-30.75-15.9-61.75-39.05-89.65-66.95s-51-58.88-66.94-89.63a15,15,0,0,1,2.71-17.5l37.52-37.51a12.16,12.16,0,0,0,0-17.19l-50.1-50.11A12.07,12.07,0,0,0,125.22,78Z" /><path d="M364.75,269.73a15,15,0,0,1-15-15,99.37,99.37,0,0,0-99.25-99.26,15,15,0,0,1,0-30c71.27,0,129.25,58,129.25,129.26A15,15,0,0,1,364.75,269.73Z" /><path d="M428.15,269.73a15,15,0,0,1-15-15c0-89.69-73-162.66-162.65-162.66a15,15,0,0,1,0-30c106.23,0,192.65,86.43,192.65,192.66A15,15,0,0,1,428.15,269.73Z" /></g></svg>
                  <p class="color-grey" style="display: inline-block;">
                    {{ praxis.phone }}
                  </p>
                </div>
              </div>
            </article>
            <div class="t-right mar-top-8">
              <a @click="praxisModal = true;" style="cursor: pointer;" class="color-primary">Andere Praxis auswählen</a>
            </div>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-9 pad-8">
            <section>
              <div style="display: flex;flex-flow: wrap;gap: 4px; padding: 0 8px;">
                <div v-for="workG in workgroups" :key="workG">
                  <button @click="()=>toggleActiveGroups(workG)" class="button tiny activeGroup">{{workG}}</button>
                </div>
              </div>
              <div class="pad-8" style="display: flex;flex-flow: wrap;gap: 4px;">
                <div v-for="member in members"
                     :key="member.id" class="singleMember" @click="()=>{toggleActiveMembers(member.id)}">
                  <a style="cursor: pointer;" :title="member.title+' '+member.firstname+' '+member.lastname">
                    <img :src="config.projectURL + 'assets/images/users/'+member.userImg"
                         class="image width-40 circular bgcolor-lightblue"
                         :class="{active : activeMembers.includes(member.id)}"
                    >
                  </a>
                </div>
              </div>
            </section>
          </div>
          <div class="col-sp-12 row-sp-12" v-if="sickMessage">
            <div class="label red t-center pad-16" style="display: block;">
              <p>
                <b>Achtung!</b> Mindestens eine Schicht muss aufgrund eines Krankheitsfalles neu besetzt werden.
              </p>
            </div>
          </div>
        </div>
        <div class="pad-8" />
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="grid-12">
              <div class="col-sp-3 row-sp-12">
                <button class="button tiny white" @click="renderWeek('left')">
                  <svg height="24px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " /></svg>
                </button>
              </div>
              <div class="col-sp-6 row-sp-12 t-center" id="PlanerMenu">
                <div class="field">
                  <select v-model="year" @change="getAllWeeks(); ForceUpdateComponent()">
                    <option tabindex="-1" selected disabled>
                      Bitte auswählen
                    </option>
                    <option value="2023">
                      2023
                    </option>
                    <option value="2024">
                      2024
                    </option>
                    <option value="2025">
                      2025
                    </option>
                    <option value="2026">
                      2026
                    </option>
                    <option value="2027">
                      2027
                    </option>
                    <option value="2028">
                      2028
                    </option>
                    <option value="2029">
                      2029
                    </option>
                    <option value="2030">
                      2030
                    </option>
                  </select>
                </div>
                <div class="field">
                  <select v-model="currentKW" @change="loadPlan()">
                    <option tabindex="-1" selected disabled>
                      Bitte auswählen
                    </option>
                    <option v-for="kw in allWeeksdata" :key="kw.KW" :value="kw.KW">
                      KW {{ kw.KW }} | {{ kw.Monday }} - {{ kw.Sunday }}
                    </option>
                  </select>
                </div>
                <button class="primary button mar-left-8" @click="autoSet()">
                  <svg width="24px" style="vertical-align: -5px;" enable-background="new 0 0 32 32" id="Stock_cut" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><desc /><g><path d="M17,31h-2   c-1.105,0-2-0.895-2-2v-2h6v2C19,30.105,18.105,31,17,31z" fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" /><path d="M23,16L23,16   c0,2.518-1.186,4.889-3.2,6.4L19,23v4h-6v-4l-0.8-0.6C10.186,20.889,9,18.518,9,16v0c0-3.866,3.134-7,7-7h0   C19.866,9,23,12.134,23,16z" fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" /><line fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="16" x2="16" y1="6" y2="2" /><line fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="8.93" x2="6.101" y1="8.929" y2="6.1" /><line fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="23.07" x2="25.899" y1="8.929" y2="6.1" /><line fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="6" x2="2" y1="16" y2="16" /><line fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="26" x2="30" y1="16" y2="16" /></g></svg>
                  <span class="mar-left-8">Automatische Planung</span>
                </button>
                <!--
                <button class=" button mar-left-8" @click="setWeekTemplate">
                  <svg style="opacity: 0.4 ;vertical-align: -4px;" width="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none" /><path d="M4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z" /></g></svg>
                  <span class="mar-left-8">Als Wochenvorlage speichern</span>
                </button>
                -->
              </div>
              <div class="col-sp-3 row-sp-12 t-right">
                <button class="button tiny white" @click="renderWeek('right')">
                  <svg height="24px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " /></svg>
                </button>
              </div>
            </div>
            <div v-if="renderComponent">
              <div v-if="sunday">
                <dayPlan v-for="weekDates in weekDates" :week-dates="weekDates" :activeUsers="activeMembers" :key="weekDates.id" @sick="sickMessage = true"/>
              </div>
              <div v-else>
                <dayPlan v-for="weekDates in weekDates.slice(0, -1)" :week-dates="weekDates" :activeUsers="activeMembers" :key="weekDates.id" @sick="sickMessage = true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pad-40" />
    </main>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import dayPlan from "@/components/praxisplaner/dayPlan";
import praxisItems from "@/components/profil/praxis";

export default {
  name: "Praxisplaner",
  title: "Praxisplaner",
  components: {
    dayPlan: dayPlan,
    praxisItems: praxisItems
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      allWeeksdata: [],
      currentKW: '10',
      year: 2023,
      weekDates: [],
      renderComponent: true,
      sunday:true,
      saturday:true,
      members: '',
      activeMembers:[],
      praxis: '',
      praxisModal: false,
      praxisItems: '',
      sickMessage: false,
      workgroups:[]
    }
  },
  mounted() {
    this.config.loading = false
    this.getNow()
    this.getMembers()
    this.getPraxisDetails()
    this.getPraxisItems()
  },
  methods: {
    getPraxisItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getMyClubs', fD)
          .then((response) => {
            if(response.status === 250){
              _this.praxisItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    autoSet(){
      const _this = this
      const fD = new FormData()
      fD.append('monday', this.allWeeksdata[this.currentKW - 1]['Monday'])
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/autoSet', fD)
          .then((response) => {
            console.log(response.data)
            if(response.status === 250){
              _this.loadPlan()
            }
          })
    },
    getMembers(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getMembers', fD)
          .then((response) => {
            if(response.status === 250){
              _this.members = response.data
              console.log(response.data)
              response.data.map(singleMember=>{
                this.activeMembers.push(singleMember.id)
                if (!this.workgroups.includes(singleMember.workgroupTitle)){
                 this.workgroups.push(singleMember.workgroupTitle)
                }
              })
            }
          })
    },
    toggleActiveMembers(id){
      const found = this.activeMembers.indexOf(id)
      if(found >= 0){
        this.activeMembers.splice(found,1)
      }
      else {
        this.activeMembers.push(id)
      }
      this.forceRerender()
    },
    toggleActiveGroups(name){
      if (event.target.classList.contains('activeGroup')){
        event.target.classList.remove('activeGroup')
        this.members.map(sMember =>{
          if (sMember.workgroupTitle === name){
            const found = this.activeMembers.indexOf(sMember.id)
            if(found >= 0){
              this.activeMembers.splice(found,1)
            }
          }
        })
      }
      else {
        event.target.classList.add('activeGroup')
        this.members.map(sMember =>{
          if (sMember.workgroupTitle === name) {
            this.activeMembers.push(sMember.id)
          }
        })
      }
      this.forceRerender()
    },
    getNow(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/getNow', fD)
          .then((response) => {
            _this.currentKW = response.data.KW
            _this.year = response.data.Year
            _this.getAllWeeks()
          })
    },
    getAllWeeks(){
      const _this = this
      const fD = new FormData()
      fD.append('year', this.year)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/getAllWeeks', fD)
          .then((response) => {
            _this.allWeeksdata = response.data
            _this.loadPlan()
          })
    },
    loadPlan(){
      const thisweek = this.allWeeksdata[this.currentKW - 1]
      this.weekDates = [
        {id:1, name: 'Montag', date:thisweek['Monday']},
        {id:2, name: 'Dienstag', date:thisweek['Tuesday']},
        {id:3, name: 'Mittwoch', date:thisweek['Wednesday']},
        {id:4, name: 'Donnerstag', date:thisweek['Thursday']},
        {id:5, name: 'Freitag', date:thisweek['Friday']},
        {id:6, name: 'Samstag', date:thisweek['Saturday']},
        {id:7, name: 'Sonntag', date:thisweek['Sunday']}
      ]
      this.forceRerender()
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    setWeekTemplate(){
      const start = this.weekDates[0].date + '00:00:00'
      const end = this.weekDates[6].date + '23:59:59'
      const fD = new FormData()
      fD.append('start', start)
      fD.append('end', end)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('mylayers/getMyLayersFromWeek', fD)
          .then((response) => {
            console.log(response)
          })
    },
    renderWeek(direction){
      if (direction === 'left'){
        const toInt = parseInt(this.currentKW) -1
        if (toInt <= 0){
          this.currentKW = '52'
          const yearToInt = parseInt(this.year) -1
          this.year = String(yearToInt)
          this.loadPlan()
          return;
        }
        else {
          const toInt = parseInt(this.currentKW) -1
          if (toInt <= 9 && toInt > 0){
            const toPhp= "0"+ String(toInt)
            this.currentKW = toPhp
            this.loadPlan()
            return
          }
          else{
            this.currentKW = String(toInt)
            this.loadPlan()
          }
        }
      }
      else if(direction === 'right'){
        const toInt = parseInt(this.currentKW) +1
        if (toInt <= 0){
          this.currentKW = '52'
          const yearToInt = parseInt(this.year) -1
          this.year = String(yearToInt)
          this.loadPlan()
          return;
        }
        if (toInt <= 9 && toInt > 0){
          const toPhp= "0"+ String(toInt)
          this.currentKW = toPhp
          this.loadPlan()
          return
        }
        if (toInt >= 53){
          this.currentKW = '01'
          const yearToInt = parseInt(this.year) +1
          this.year = String(yearToInt)
          this.loadPlan()
          return;
        }
        else {
          const toPhp= String(toInt)
          this.currentKW = toPhp
          this.loadPlan()
          return
        }
      }
    },
    getPraxisDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('organizerId', this.config.getCurrentOrganizer.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisverwaltung/getPraxisById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.praxis = response.data
            }
          })
    },

  }
}
</script>

<style scoped>
  #praxisplaner h1{
    font-size: 2em;
  }
  #praxisplaner h2{
    font-size: 1.2em;
  }
  #user-list{
    padding: 0;
    margin: 0;
  }
  #user-list li{
    display: inline;
    margin-right: 4px;
  }
  #user-list li img{
    border-radius: 90%;
    max-width: 48px;
    border: 3px solid #ffffff;
    cursor: pointer;
  }
  #user-list li img:hover{
    border: 3px solid var(--theme-diagnostika-primary);
    transition: 0.3s;
    transform: scale(1.1);
  }
  #PlanerMenu .field{
    display: inline-block;
  }
  #PlanerMenu .field select{
    border: 1px solid rgba(0,0,0,0.06);
    font-weight: bold;
    padding: 8px;
    margin-right: 8px;
    border-radius: 10px;
  }
  .singleMember img{
    border: 3px solid var(--lightblue);
    transition: 0.3s;
  }
  .singleMember img:hover,
  .singleMember img.active{
    border: 3px solid var(--theme-diagnostika-primary);
    transition: 0.3s;
  }
  .praxis{
    margin-top: 8px;
    color: var(--theme-diagnostika-primary)!important;
    border-radius: 10px;
    padding: 8px 4px;
    transition: 0.3s;
    background-color: #ffffff;
  }
  .praxis img{
    max-width: 100%;
    width: 100%;
    padding-right: 8px;
  }
  .praxis h4{
    font-size: 1.1em;
    font-family: 'Helvetica';
    margin-top: 8px;
    margin-left: 4px;
  }
  .button.activeGroup{
    background-color: var(--theme-diagnostika-primary);
    color: var(--white);
  }
</style>