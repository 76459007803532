<template>
  <div class="vueModal" id="selectNewSlotModal" style="position: fixed">
    <div class="vueModalOuter" style="height: 100%" @click="handleClosing" />
    <div class="vueModalInner" style="width: calc(100% - 64px);max-width: 500px">
      <div style="clear: both;">
        <div style="float: right; cursor: pointer;" @click="handleClosing">
          <svg style="width: 24px;" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M256,7C118.467,7,7,118.468,7,256.002C7,393.533,118.467,505,256,505s249-111.467,249-248.998  C505,118.468,393.533,7,256,7z M256,485.08c-126.31,0-229.08-102.771-229.08-229.078C26.92,129.692,129.69,26.92,256,26.92  c126.309,0,229.08,102.771,229.08,229.082C485.08,382.309,382.309,485.08,256,485.08z" fill="#425661" /><polygon fill="#425661" points="368.545,157.073 354.461,142.988 255.863,241.587 157.733,143.456 143.648,157.54 241.78,255.672   143.648,353.809 157.733,367.893 255.863,269.75 354.461,368.361 368.545,354.275 269.947,255.672 " /></svg>
        </div>
      </div>
      <div>
        <h4 class="t-center">
          {{ weekDates.name }}<span class="label tiny blue mar-left-8" style="vertical-align: 4px;">{{ weekDates.date }}</span>
        </h4>
        <div class="pad-16">
          <div class="grid-12">
            <div class="col-sp-12 row-sp-12">
              <label for="editSlotStartTime">Schichtbeginn</label>
            </div>
            <div class="col-sp-8 row-sp-12">
              <div class="field">
                <input type="date" id="editSlotStartDate" v-model="editSlotStartDate" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
              </div>
            </div>
            <div class="col-sp-4 row-sp-12">
              <div class="field">
                <input type="time" id="editSlotStartTime" v-model="editSlotStart" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
              </div>
            </div>
          </div>
          <div class="grid-12">
            <div class="col-sp-12 row-sp-12">
              <label for="editSlotEndTime">Schichtende</label>
            </div>
            <div class="col-sp-8 row-sp-12">
              <div class="field">
                <input type="date" id="editSlotEndDate" v-model="editSlotEndDate" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
              </div>
            </div>
            <div class="col-sp-4 row-sp-12">
              <div class="field">
                <input type="time" id="editSlotEndTime" v-model="editSlotEnd" @change="calculateWorkTime()" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
              </div>
            </div>
          </div>
          <div class="t-right mar-bottom-8">
            <span class="label tiny" v-if="editSlotWorkTime">Schichtdauer : {{ editSlotWorkTime }}</span>
          </div>
          <div class="grid-12">
            <div class="col-sp-12 col-tb-6 row-sp-12">
              <div class="field">
                <label>Berufsgruppe</label>
                <select v-model="editWorkgroup" name="editworkgroup" required @change="getWorkareas(); editWorkarea = '';handleClearSlot(slotId)" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                  <option tabindex="-1" selected disabled>
                    Bitte auswählen
                  </option>
                  <option v-for="workgroup in workgroups" :key="workgroup.id" :value="workgroup.id">
                    {{ workgroup.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sp-12 col-tb-6 row-sp-12">
              <div class="field">
                <label>Arbeitsbereich</label>
                <select v-model="editWorkarea" class="pad-12" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                  <option v-for="workarea in workareas" :key="workarea.id" :value="workarea.id">
                    {{ workarea.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="pad-8" />
          <button class="primary fluid button" @click="editSlot(slotItems, slotId)">
            Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "EditSlotModal",
  props:["weekDates","slotItems","slotId"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      editSlotStartDate: this.renderDateForJS(this.weekDates.date),
      editSlotEndDate: this.renderDateForJS(this.weekDates.date),
      editSlotStart:'',
      editSlotEnd:'',
      editSlotWorkTime:'',
      editWorkarea:'',
      editWorkgroup:'',
      workgroups: [],
      workareas: [],
    }
  },
  mounted() {
    this.renderEditSlot(this.slotItems, this.slotId)
    this.getWorkgroups()
    this.calculateWorkTime()
  },
  methods:{
    handleClosing(){
      this.$emit("closingEditSlot", false)
    },
    handleClearSlot(slotId){
      const fD = new FormData()
      fD.append('slotId', slotId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('praxisplaner/clearSlot', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('activateClearSlot', true)
              this.$emit('activateGetSlots', true)
            }
          })
    },
    //Rendering Date
    renderDateForJS(date){
      const jsDate = date.split('.')
      return jsDate[2] + '-' + jsDate[1] + '-' + jsDate[0]
    },
    calculateWorkTime(){
      const startTime = document.querySelector('#editSlotStartTime')
      const endTime = document.querySelector('#editSlotEndTime')
      const startTimeSplit = startTime.value.split(':')
      const endTimeSplit = endTime.value.split(':')
      const startTimeInMin = (startTimeSplit[0] * 60) + parseInt(startTimeSplit[1])
      const endTimeInMin = (endTimeSplit[0] * 60) + parseInt(endTimeSplit[1])

      this.editSlotWorkTime = Math.floor((endTimeInMin - startTimeInMin) / 60)+ ' Stunden : ' +(endTimeInMin - startTimeInMin) % 60 + ' Minuten'
    },
    getWorkareas(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('workgroupid', this.editWorkgroup)
      this.axios.post('usermanagement/getWorkareas', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workareas = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getWorkgroups(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getWorkgroups', fD)
          .then(r => {
            if(r.status === 250) {
              _this.workgroups = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    renderEditSlot(slotItems, slotId){
      const EditObject = slotItems.filter(obj=>{return obj.id === slotId})
      this.editWorkgroup = EditObject[0].workgroupId
      const startTime = EditObject[0].slotStart.split(" ")
      const endTime = EditObject[0].slotEnd.split(" ")
      this.editSlotStart = startTime[1]
      this.editSlotEnd = endTime[1]
      this.getWorkareas()
      this.editWorkarea = EditObject[0].workareasId
    },
    editSlot(slotItems, slotId){
      const fD = new FormData()
      const startDate = document.querySelector('#editSlotStartDate')
      const startTime = document.querySelector('#editSlotStartTime')
      this.editSlotStart = startDate.value+ ' '+ startTime.value

      const endDate = document.querySelector('#editSlotEndDate')
      const endTime = document.querySelector('#editSlotEndTime')
      this.editSlotEnd = endDate.value+ ' '+ endTime.value

      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', slotId)
      fD.append('newSlotStart', this.editSlotStart)
      fD.append('newSlotEnd', this.editSlotEnd)
      fD.append('selctedworkgroup', this.editWorkgroup)
      fD.append('selectedworkarea', this.editWorkarea)
      console.log(slotId, this.editSlotStart, this.editSlotEnd, this.editWorkgroup, this.editWorkarea)
      this.axios.post('praxisplaner/editSlot', fD)
          .then(r => {
            if(r.status === 250) {
              this.handleClosing()
              this.$emit('activateGetSlots', true)
            }
          })
          .catch(e => {
            console.log(e)
          })

    },
  }
}
</script>

<style scoped>
.field{
  margin-bottom: 8px;
}
.field input,
.field select{
  max-width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  width: 100%;
}
.field input:focus{
  outline: none;
}
</style>